import z from 'zod';
import StocktakeCountStatus from '../enums/StocktakeCountStatus';

export const CountSheetSummarySchema = z.object({
    countStatus: z.nativeEnum(StocktakeCountStatus),
    id: z.string(),
    key: z.string(),
    locationId: z.string(),
    locationName: z.string(),
    stocktakeId: z.string(),
});

export type CountSheetSummary = z.infer<typeof CountSheetSummarySchema>;
