import DataTable, { ColumnBuilder } from 'components/DataTable/DataTable';

import Icons from 'Icons';
import MyMenuKebabButton from 'components/MyMenuKebabButton/MyMenuKebabButton';
import { WorkflowBreakdownNonMappedKeysDisplay } from 'features/workOrders/enums/WorkflowBreakdownNonMappedKeys';
import { WorkflowItemSplitConfig } from 'features/workOrders/models/WorkflowItemSplitConfig';
import React, { useMemo } from 'react';
import './WorkflowItemSplitTable.scss';

export default function WorkflowItemSplitTable({
    data,
    highlightedRowId,
    onRowClick,
    onClone,
    onDelete,
}: {
    data?: WorkflowItemSplitConfig[];
    highlightedRowId?: string;
    onRowClick: (config: WorkflowItemSplitConfig) => void;
    onClone: (config: WorkflowItemSplitConfig) => void;
    onDelete: (config: WorkflowItemSplitConfig) => void;
}) {
    const columns = useMemo(
        () =>
            ColumnBuilder<WorkflowItemSplitConfig>()
                .column({
                    label: 'Type',
                    key: 'type',
                    render: item => item.type,
                })
                .column({
                    label: 'Non-mapped keys',
                    key: 'settings.nonMappedKeys',
                    render: item =>
                        WorkflowBreakdownNonMappedKeysDisplay.display(item.settings.nonMappedKeys),
                })
                .column({
                    label: 'Conditions',
                    key: 'conditions.length',
                    align: 'center',
                    render: item => item.conditions.length,
                })
                .column({
                    label: 'Mappings',
                    key: 'mapping.length',
                    align: 'center',
                    render: item => item.mapping.length,
                })
                .column({
                    label: '',
                    key: 'menu',
                    width: '1px',
                    render: item => (
                        <MyMenuKebabButton
                            className="WorkflowItemSplitTable__RowKebabButton"
                            size="small"
                            buttonType="Nude"
                            menuItems={[
                                {
                                    label: 'Clone',
                                    IconLeft: Icons.Copy,
                                    onClick: () => onClone(item),
                                },
                                {
                                    label: 'Delete',
                                    IconLeft: Icons.Archive,
                                    onClick: () => onDelete(item),
                                },
                            ]}
                        />
                    ),
                })
                .build(),
        [onClone, onDelete],
    );

    return (
        <div className="WorkflowItemSplitTable">
            <DataTable
                className="WorkflowItemSplitTable__DataTable"
                zebra={true}
                data={data}
                onRowClick={onRowClick}
                rowIsHighlighted={item => item.id === highlightedRowId}
                useStickyHeader={true}
                columns={columns}
                emptyState={'No split configs have been set up for this workflow'}
            />
        </div>
    );
}
