import Icons from 'Icons';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import './MyCheckboxTree.scss';

export type MyCheckboxTreeNode = {
    id: string;
    label: string;
    nodes?: MyCheckboxTreeNode[];
};

export default function MyCheckboxTree({
    className,
    nodes = [],
    selectedIds = [],
    initialExpandAll = false,
    readonly = false,
    onChange,
}: {
    className?: string;
    nodes?: MyCheckboxTreeNode[];
    selectedIds?: string[];
    initialExpandAll?: boolean;
    readonly?: boolean;
    onChange?: (selectedIds: string[]) => void;
}) {
    const [expandedIds, setExpandedIds] = useState<string[]>([]);

    useEffect(() => {
        if (initialExpandAll) {
            setExpandedIds(nodes.map(n => n.id));
        }
    }, [nodes, initialExpandAll]);

    return (
        <div className={coalesceClassNames('MyCheckboxTree', className)}>
            {nodes.map(n => (
                <MyCheckboxTreeItem
                    key={n.id}
                    node={n}
                    selectedIds={selectedIds}
                    expandedIds={expandedIds}
                    readonly={readonly}
                    onSelectChange={onChange}
                    onExpandChange={setExpandedIds}
                />
            ))}
        </div>
    );
}

type NodeSelectionState = 'selected' | 'unselected' | 'partial';

function MyCheckboxTreeItem({
    node,
    selectedIds,
    expandedIds,
    readonly = false,
    onSelectChange,
    onExpandChange,
}: {
    node: MyCheckboxTreeNode;
    /** Contains ids of any selected leaf node
     * Plus any parent node where all leafs are selected
     * Partially selected parent nodes are NOT included */
    selectedIds: string[];
    expandedIds: string[];
    readonly?: boolean;
    onSelectChange?: (selectedIds: string[]) => void;
    onExpandChange?: (expanded: string[]) => void;
}) {
    const childNodes = useMemo(() => node.nodes || [], [node]);
    const isLeafNode = childNodes.length === 0;

    const selectionState: NodeSelectionState = useMemo(() => {
        if (selectedIds.includes(node.id)) {
            return 'selected';
        }

        if (isLeafNode) {
            // this is a leaf node and it is not selected,
            // just return 'unselected'
            return 'unselected';
        }

        // this is a parent node, it can be unselected or partial, check leaf nodes to decide
        const leafIds = getLeafIds(node);
        return leafIds.some(id => selectedIds.includes(id)) ? 'partial' : 'unselected';
    }, [isLeafNode, node, selectedIds]);

    const toggleSelected = useCallback(() => {
        if (selectionState === 'unselected') {
            // select this node and all child nodes
            // use a Set to avoid duplicates
            const mySet = new Set([...selectedIds, node.id, ...getLeafIds(node)]);
            const ids = Array.from(mySet);
            onSelectChange?.(ids);
        } else {
            // unselect this node and all parents
            const idsToUnselect = [...getLeafIds(node), node.id];
            const ids = selectedIds.filter(id => !idsToUnselect.includes(id));
            onSelectChange?.(ids);
        }
    }, [node, onSelectChange, selectedIds, selectionState]);

    /** Selection changed on a child node
     * Update parent node selection as well
     */
    const handleChildSelectChanged = useCallback(
        (ids: string[]) => {
            let finalIds = ids;
            if (childNodes.some(n => !ids.includes(n.id))) {
                // some children are not selected, so this node should not be selected either
                finalIds = ids.filter(id => id !== node.id);
            } else if (!finalIds.includes(node.id)) {
                // all children are selected, so this should also be selected
                finalIds.push(node.id);
            }
            onSelectChange?.(finalIds);
        },
        [childNodes, node.id, onSelectChange],
    );

    const isExpanded = useMemo(() => expandedIds.includes(node.id), [node.id, expandedIds]);

    const toggleExpanded = useCallback(() => {
        if (!isExpanded) {
            // expand now - use a Set to avoid duplicates
            const ids = new Set([...expandedIds, node.id]);
            onExpandChange?.(Array.from(ids));
        } else {
            // collapse now
            const ids = expandedIds.filter(id => id !== node.id);
            onExpandChange?.(ids);
        }
    }, [expandedIds, isExpanded, node.id, onExpandChange]);

    return (
        <div className="MyCheckboxTreeItem">
            <div className="MyCheckboxTreeItem__Main">
                {isLeafNode ? (
                    <div className="MyCheckboxTreeItem__Main__LeafMarker" />
                ) : (
                    <button
                        className={coalesceClassNames(
                            'MyCheckboxTreeItem__Main__Expander',
                            isExpanded ? 'expanded' : 'collapsed',
                        )}
                        onClick={toggleExpanded}
                    >
                        {isExpanded ? <Icons.ChevronDown /> : <Icons.ChevronRight />}
                    </button>
                )}
                <button
                    className="MyCheckboxTreeItem__Main__Button"
                    onClick={toggleSelected}
                    disabled={readonly}
                >
                    <div
                        className={coalesceClassNames(
                            'MyCheckboxTreeItem__Main__Button__Checkbox',
                            selectionState,
                        )}
                    >
                        {selectionState === 'selected' ? (
                            <Icons.Check />
                        ) : selectionState === 'partial' ? (
                            <Icons.CheckPartial />
                        ) : null}
                    </div>
                    <div className="MyCheckboxTreeItem__Main__Button__Label">{node.label}</div>
                </button>
            </div>

            {childNodes.length > 0 && isExpanded && (
                <div className="MyCheckboxTreeItem__Children">
                    {childNodes.map(n => (
                        <MyCheckboxTreeItem
                            key={n.id}
                            node={n}
                            selectedIds={selectedIds}
                            expandedIds={expandedIds}
                            readonly={readonly}
                            onSelectChange={handleChildSelectChanged}
                            onExpandChange={onExpandChange}
                        />
                    ))}
                </div>
            )}
        </div>
    );
}

function getLeafIds(n: MyCheckboxTreeNode): string[] {
    const childNodes = n.nodes || [];
    if (childNodes.length === 0) {
        // this is a leaf node
        return [n.id];
    }

    const ids = childNodes.map(ch => getLeafIds(ch)).flat();
    return ids;
}
