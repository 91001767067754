import MySelectInput from 'components/MySelectInput/MySelectInput';
import { DateTime } from 'luxon';
import {
    dateFilterOptionsAny,
    dateFilterOptionsFuture,
    dateFilterOptionsPast,
} from 'models/DateFilterOption';
import React from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import { getDeepValue } from 'utils/helpers';
import {
    DataTableFilterBase,
    DataTableFilterPlugin,
    FilterCompProps,
} from '../DataTableFilterTypes';

export type DateFilterConfig = DataTableFilterBase & {
    type: 'date';
    field: string;
    range?: 'past' | 'future' | 'any';
};

function DateFilter({ filter, onChange }: FilterCompProps<DateFilterConfig>) {
    const handleInput = (val: string) => {
        filter.value = val;
        onChange?.(filter);
    };

    return (
        <div
            className={coalesceClassNames(
                'DataTableFilters__Field',
                'DataTableFilters__Field--DateFilter',
            )}
        >
            <MySelectInput
                label={filter.config.label}
                value={filter.value}
                options={
                    filter.config.range === 'future'
                        ? dateFilterOptionsFuture
                        : filter.config.range === 'past'
                        ? dateFilterOptionsPast
                        : dateFilterOptionsAny
                }
                handleInput={handleInput}
                allowBlank={true}
            />
        </div>
    );
}

const plugin: DataTableFilterPlugin<DateFilterConfig> = {
    Component: DateFilter,
    applyFilter: (filter, value, item) => {
        const minDate = dateFilterOptionsAny.find(d => d.value === value)?.minDate;
        const maxDate = dateFilterOptionsAny.find(d => d.value === value)?.maxDate;

        if (minDate && maxDate) {
            const val = getDeepValue(item, filter.field);
            const date = DateTime.fromISO(val).valueOf();
            return (
                date >= (minDate as DateTime).valueOf() && date <= (maxDate as DateTime).valueOf()
            );
        }
        return true;
    },
};
export default plugin;
