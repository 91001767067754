import WorkItemFieldUnits from 'features/workOrders/enums/WorkItemFieldUnits';
import { WorkstationWorkItemField } from 'features/workOrders/models/WorkstationWorkItem';
import React, { useMemo } from 'react';
import { isEmpty } from 'utils/helpers';

const fractionLookup: Record<number, string> = {
    0.0625: '1/16',
    0.125: '1/8',
    0.1875: '3/16',
    0.25: '1/4',
    0.3125: '5/16',
    0.375: '3/8',
    0.4375: '7/16',
    0.5: '1/2',
    0.5625: '9/16',
    0.625: '5/8',
    0.6875: '11/16',
    0.75: '3/4',
    0.8125: '13/16',
    0.875: '7/8',
    0.9375: '15/16',
};

export function formatInches(floatValue?: number) {
    if (floatValue === 0) {
        return <>0"</>;
    }

    if (floatValue) {
        const inches = Math.floor(floatValue);
        const decimal = floatValue % 1;
        const fraction = fractionLookup[decimal];

        if (fraction) {
            const [num, den] = fraction.split('/');
            return (
                // Format using unicode fraction slash
                <>
                    {inches} {num}&#8260;{den}"
                </>
            );
        }
        return <>{inches}"</>;
    }
    return floatValue;
}

/** Display WorkstationWorkItemField value using unitSystem formatting */
export default function WorkItemFieldDisplay({ field }: { field: WorkstationWorkItemField }) {
    const valueWithUnits = useMemo(() => {
        if (field.unitSystem === WorkItemFieldUnits.Millimetres) {
            return isEmpty(field.value) ? '' : `${field.value} mm`;
        }
        if (field.unitSystem === WorkItemFieldUnits.Inches) {
            const floatVal =
                typeof field.value === 'number'
                    ? field.value
                    : typeof field.value === 'string'
                    ? parseFloat(field.value)
                    : Number.NaN;

            if (!Number.isNaN(floatVal)) {
                return formatInches(floatVal);
            }
        }
        return field.value;
    }, [field.unitSystem, field.value]);

    if (isEmpty(valueWithUnits) && field.hideIfEmpty) {
        return null;
    }

    return <>{valueWithUnits}</>;
}
