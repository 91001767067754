import InventoryGroupDetailModal from 'features/inventory/components/InventoryGroupDetailModal/InventoryGroupDetailModal';
import inventoryApi from 'features/inventory/inventory.api';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { usePageTitle } from 'utils/locationHelpers';

export default function InventoryGroupDetailPage() {
    const { groupId = '' } = useParams();
    const navigate = useNavigate();

    const query = inventoryApi.useInventoryGroupDetailQuery(groupId);
    const schemaQuery = inventoryApi.useInventoryGroupSchemaQuery();
    const attributesQuery = inventoryApi.useAttributeListQuery();
    const [saveQuery, saveQueryState] = inventoryApi.useInventoryGroupUpdateMutation();
    usePageTitle(query.data?.name);

    return groupId ? (
        <>
            <InventoryGroupDetailModal
                model={query.data}
                udfs={schemaQuery.data?.schemaExtensions.udfs}
                attributes={attributesQuery.data?.data}
                isLoading={query.isLoading || schemaQuery.isLoading}
                isError={query.isError || schemaQuery.isError}
                onSave={saveQuery}
                isSaving={saveQueryState.isLoading}
                close={() => navigate('/inventorygroups')}
            />
        </>
    ) : null;
}
