import { useEffect, useMemo } from 'react';
import { debounce } from 'throttle-debounce';

export default function useDebouncedCallback(
    delay: number,
    callback: (data?: any) => void,
    dependencies: any[],
) {
    const debouncedHandler = useMemo(
        () => debounce(delay, callback),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        dependencies,
    );

    useEffect(
        () => {
            // cancel any callback if the component unmounts
            return () => debouncedHandler.cancel();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        dependencies,
    );

    return debouncedHandler;
}
