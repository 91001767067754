import Icons from 'Icons';
import ErrorContent from 'components/ErrorContent/ErrorContent';
import MyLinearProgress from 'components/MyLinearProgress/MyLinearProgress';
import inventoryApi from 'features/inventory/inventory.api';
import { Inventory } from 'features/inventory/models/Inventory';
import { InventoryLocationPath } from 'features/inventory/models/InventoryLocationsResult';
import React from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import './InventoryDetailsStockLevels.scss';

export default function InventoryDetailsStockLevels({ model }: { model?: Inventory }) {
    const query = inventoryApi.useInventoryLocationsQuery(model?.id || '', { skip: !model?.id });
    const [searchParams] = useSearchParams();

    return (
        <div className="InventoryDetailsStockLevels">
            {query.isLoading ? (
                // Loading state
                <div className="InventoryDetailsModal__TabLoadingPanel">
                    <MyLinearProgress />
                </div>
            ) : query.isError ? (
                // Error state
                <ErrorContent />
            ) : (query.data?.warehouses.length ?? 0) === 0 ? (
                // Empty state
                <div className="InventoryDetailsModal__TabEmptyPanel">
                    <h2>Nothing to display</h2>
                    <p>No stock records found in any locations</p>
                </div>
            ) : (
                <>
                    <h2 className="InventoryDetailsStockLevels__Total">
                        <div className="label">Total stock on hand</div>
                        <div className="total">{query.data?.total}</div>
                    </h2>
                    {query.data?.warehouses.map(warehouse => (
                        <div
                            key={warehouse.id}
                            className="InventoryDetailsStockLevels__Warehouse"
                        >
                            {/* Warehouse header: name, total count */}
                            <h3 className="InventoryDetailsStockLevels__Warehouse__Header">
                                <div className="name">{warehouse.name}</div>
                                <div className="total">{warehouse.total}</div>
                            </h3>

                            {/* List locations within warehouse */}
                            {warehouse.locations.map((loc, index) => (
                                <Link
                                    key={index}
                                    className="InventoryDetailsStockLevels__Location"
                                    to={`location/${
                                        loc.path[loc.path.length - 1].id
                                    }?${searchParams}`}
                                >
                                    <div className="InventoryDetailsStockLevels__Location__Path">
                                        {loc.path.map((p, idx) => (
                                            <PathItem
                                                key={idx}
                                                path={p}
                                                isLast={idx === loc.path.length - 1}
                                            />
                                        ))}
                                    </div>
                                    <div className="InventoryDetailsStockLevels__Location__Count">
                                        {loc.count}
                                    </div>
                                </Link>
                            ))}
                        </div>
                    ))}
                </>
            )}
        </div>
    );
}

function PathItem({ path, isLast }: { path: InventoryLocationPath; isLast: boolean }) {
    return (
        <>
            <span className="path">{path.name}</span>
            {!isLast && <Icons.ChevronRight className="spacer" />}
        </>
    );
}
