import PageHeader from 'components/PageHeader/PageHeader';
import CustomersTable from 'features/customers/components/CustomersTable/CustomersTable';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { usePageTitle } from 'utils/locationHelpers';
import './CustomersPage.scss';

export default function CustomersPage() {
    usePageTitle('Customers');
    return (
        <>
            <PageHeader title="Customers" />

            <CustomersTable />

            <Outlet />
        </>
    );
}
