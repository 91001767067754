import PackageDetailModal from 'features/dispatch/components/PackageDetailModal/PackageDetailModal';
import dispatchApi from 'features/dispatch/dispatch.api';
import React from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { usePageTitle } from 'utils/locationHelpers';

export default function PackageDetailPage() {
    const { packageId = '' } = useParams();
    const query = dispatchApi.usePackageDetailQuery(packageId, {
        skip: !packageId,
    });
    usePageTitle(query.data?.tuid);

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    return (
        <>
            <PackageDetailModal
                model={query.data}
                isLoading={query.isLoading}
                isError={query.isError}
                close={() => navigate(`/packages?${searchParams}`)}
            />
        </>
    );
}
