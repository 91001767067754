import InventoryLocationJoinDetailModal from 'features/inventory/components/InventoryLocationJoinDetailModal/InventoryLocationJoinDetailModal';
import inventoryApi from 'features/inventory/inventory.api';
import React from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { usePageTitle } from 'utils/locationHelpers';

export default function InventoryLocationJoinDetailPage() {
    usePageTitle('Stock');
    const navigate = useNavigate();
    const { inventoryId = '', locationId = '' } = useParams();

    const [searchParams] = useSearchParams();
    const query = inventoryApi.useInventoryDetailQuery(inventoryId);
    return (
        <InventoryLocationJoinDetailModal
            inventory={query.data?.data}
            locationId={locationId}
            isLoading={query.isLoading}
            isError={query.isError}
            close={() => navigate(`..?${searchParams}`)}
        />
    );
}
