import { WorkOrderItemDetailSchema } from 'features/workOrders/models/WorkOrderItemDetail';
import z from 'zod';
import { PackageSchema } from './Package';
import { ShipmentEntitySchema } from './ShipmentEntity';

export const ShipmentSchema = ShipmentEntitySchema.extend({
    context: z.object({
        /* Work Items assigned to this shipment before manufacturing */
        assignedWorkOrderItems: z.array(WorkOrderItemDetailSchema),

        /** Packages in this shipment - includes work items post-manufacturing and any other non-work item packages */
        packages: z.array(PackageSchema).default([]),
    }),
});

export type Shipment = z.infer<typeof ShipmentSchema>;
