import { mergeDeep } from 'utils/helpers';
import z from 'zod';

export const PackageEntitySchema = z.object({
    id: z.string(),
    shipmentId: z.string().nullable(),
    locationId: z.string(),
    tuid: z.string(),
    description: z.string(),
    notes: z.string().default(''),
    weight: z.string(),
    dimensions: z.string(),
});

export type PackageEntity = z.infer<typeof PackageEntitySchema>;

export class PackageEntityFactory {
    static create(data?: Partial<PackageEntity>) {
        const defaults: PackageEntity = {
            id: '',
            shipmentId: '',
            locationId: '',
            tuid: '',
            description: '',
            notes: '',
            weight: '',
            dimensions: '',
        };

        const mergedData = mergeDeep(defaults, data);

        return PackageEntitySchema.parse(mergedData);
    }
}
