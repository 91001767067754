import { dateTimeInstant } from 'utils/dateSchemas';
import z from 'zod';
import AccountingConnectionStatus from '../enums/AccountingConnectionStatus';
import AccountingConnectionType from '../enums/AccountingConnectionType';

export const AccountingConnectionDetailSchema = z.object({
    label: z.string(),
    value: z.union([z.string(), z.number()]).nullable(),
    type: z.enum(['TEXT', 'NUMBER', 'DATE', 'DATETIME', 'CURRENCY', 'LINK', 'PHONE', 'EMAIL']),
});

export const AccountingConnectionSchema = z.object({
    id: z.string(),
    createdAt: dateTimeInstant(),
    details: z.array(AccountingConnectionDetailSchema).nullable(),
    externalId: z.string().nullable(),
    raw: z.record(z.string(), z.unknown()).nullable(),
    schema: z.string().nullable(),
    status: z.nativeEnum(AccountingConnectionStatus),
    summary: z.string(),
    type: z.nativeEnum(AccountingConnectionType),
    updatedAt: dateTimeInstant(),
});

export type AccountingConnectionDetail = z.infer<typeof AccountingConnectionDetailSchema>;
export type AccountingConnection = z.infer<typeof AccountingConnectionSchema>;
