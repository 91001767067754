import Icons from 'Icons';
import Dialog from 'components/Dialogs/Dialog';
import MyButton from 'components/MyButton/MyButton';
import PageHeader from 'components/PageHeader/PageHeader';
import { SalesOrderWorksheet } from 'features/sales/models/SalesOrderWorksheet';
import React from 'react';
import './SalesOrderWorksheetsDialog.scss';

export default function SalesOrderWorksheetsDialog({
    data,
    titleContext,
    close,
}: {
    data: SalesOrderWorksheet[];
    titleContext?: string;
    close?: () => void;
}) {
    return (
        <Dialog
            className="SalesOrderWorksheetsDialog"
            allowSubtrateClose={false}
            close={close}
        >
            <PageHeader
                title="Worksheets"
                titleContext={titleContext}
                subtitle="Available worksheets are listed below"
            >
                <MyButton
                    className="SalesOrderWorksheetsDialog__CloseButton"
                    IconRight={Icons.Close}
                    buttonType="None"
                    onClick={close}
                />
            </PageHeader>

            <div className="SalesOrderWorksheetsDialog__Items">
                {data.map((sheet, index) => (
                    <SheetItem
                        key={index}
                        sheet={sheet}
                    />
                ))}
            </div>

            <div className="SalesOrderWorksheetsDialog__Buttons">
                <MyButton
                    className="SalesOrderWorksheetsDialog__Buttons__Close"
                    label="Done"
                    onClick={close}
                />
            </div>
        </Dialog>
    );
}

function SheetItem({ sheet }: { sheet: SalesOrderWorksheet }) {
    if (sheet.errorMessage !== undefined) {
        return (
            <div className="SalesOrderWorksheetsDialog__SheetItem SalesOrderWorksheetsDialog__SheetItem--Error">
                <div>
                    <strong>{sheet.name}</strong>
                    <br />
                    An error ocurred generating this worksheet
                </div>
                <Icons.Error className="icon" />
            </div>
        );
    }
    return (
        <a
            href={sheet.path || sheet.url}
            className="SalesOrderWorksheetsDialog__SheetItem SalesOrderWorksheetsDialog__SheetItem--Link"
            target="_blank"
            rel="noreferrer"
        >
            {sheet.name}
            <Icons.Download className="icon" />
        </a>
    );
}
