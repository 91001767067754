import React from 'react';
import './MessagePanel.scss';
import coalesceClassNames from 'utils/coalesceClassNames';

export default function MessagePanel({
    className,
    messageType = 'info',
    children,
    shake = false,
}: {
    className?: string;
    messageType?: 'info' | 'warning' | 'error';
    children?: React.ReactFragment;
    shake?: boolean;
}) {
    return (
        <div
            className={coalesceClassNames(
                'MessagePanel',
                `MessagePanel--${messageType}`,
                shake && `MessagePanel--shake`,
                className,
            )}
        >
            <div className="MessagePanel__Inner">{children}</div>
        </div>
    );
}
