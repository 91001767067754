import StocktakeStatus, { StocktakeStatusDisplay } from 'features/stocktake/enums/StocktakeStatus';
import React from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import './StocktakeStatusBadge.scss';

export default function StocktakeStatusBadge({
    status,
    isArchived,
    className,
}: {
    status: StocktakeStatus;
    isArchived?: boolean;
    className?: string;
}) {
    return (
        <>
            <div className={coalesceClassNames('StocktakeStatusBadge', className, status)}>
                {StocktakeStatusDisplay.display(status)}
            </div>
            {isArchived && (
                <>
                    {' '}
                    <div
                        className={coalesceClassNames(
                            'StocktakeStatusBadge',
                            `StocktakeStatusBadge--Archived`,
                            className,
                        )}
                    >
                        Archived
                    </div>
                </>
            )}
        </>
    );
}
