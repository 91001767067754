import PageHeader from 'components/PageHeader/PageHeader';
import SalesOrdersTable from 'features/sales/components/SalesOrdersTable/SalesOrdersTable';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { usePageTitle } from 'utils/locationHelpers';
import './SalesPage.scss';

export default function SalesPage() {
    usePageTitle('Sales');
    return (
        <>
            <PageHeader title="Sales" />

            <SalesOrdersTable />

            <Outlet />
        </>
    );
}
