import createDisplayEnum, { EnumDisplayLabels } from 'utils/createDisplayEnum';

enum WorkflowBreakdownConditionType {
    Match = 'MATCH',
}
export default WorkflowBreakdownConditionType;

const labels: EnumDisplayLabels<typeof WorkflowBreakdownConditionType> = {
    Match: 'Match',
};

export const WorkflowBreakdownConditionTypeDisplay = createDisplayEnum(
    WorkflowBreakdownConditionType,
    labels,
);
