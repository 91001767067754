import { Checkbox, CheckboxProps, FormControl } from '@mui/material';
import React, { ChangeEvent } from 'react';
import coalesceClassNames, { extendClassName } from 'utils/coalesceClassNames';
import FieldValidator from '../FieldValidator/FieldValidator';
import './MyCheckboxInput.scss';

export type MyCheckboxInputProps = {
    checked?: boolean;
    handleInput?: (val: boolean) => void;
    validationKey?: string;
    validationRequired?: string | boolean;
    validationCustom?: string | false;
} & CheckboxProps;

export default function MyCheckboxInput({
    checked = false,
    handleInput,
    validationKey,
    validationRequired,
    validationCustom,
    ...props
}: MyCheckboxInputProps) {
    const onChange = (e: ChangeEvent<HTMLInputElement>, check: boolean) => {
        props.onChange?.(e, check);
        handleInput?.(check);
    };

    return (
        <FieldValidator
            validationKey={validationKey}
            validationRequired={validationRequired}
            validationCustom={validationCustom}
            value={checked}
        >
            {({ revealError, error }) => (
                <FormControl
                    className={coalesceClassNames(
                        'MyCheckboxInput',
                        props.className,
                        error && 'MyCheckboxInput--error',
                    )}
                >
                    <Checkbox
                        {...props}
                        checked={checked}
                        className={coalesceClassNames(
                            'MyCheckboxInput__Input',
                            props.className && extendClassName(props.className, '__Input'),
                        )}
                        onChange={onChange}
                        onBlur={revealError}
                    />
                </FormControl>
            )}
        </FieldValidator>
    );
}
