import SalesItemDrawer from 'features/sales/components/SalesItemDrawer/SalesItemDrawer';
import React from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { usePageTitle } from 'utils/locationHelpers';
import salesApi from '../../features/sales/sales.api';

export default function SalesItemDetailPage() {
    const { orderId = '', itemId = '' } = useParams();
    // retain url query params when navigating

    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const query = salesApi.useSalesOrderDetailQuery(orderId);

    const order = query.data;
    const model = order?.context.orderLines.find(l => String(l.id) === itemId);
    usePageTitle(model?.lineNumber.toString());

    return model ? (
        <>
            <SalesItemDrawer
                model={model}
                order={order}
                // Use query.isFetching here instead of isLoading
                // so that it also shows spinner when refreshing after an update
                isLoading={query.isLoading}
                isError={query.isError || !model}
                close={() => navigate(`/sales/${order?.id}?${searchParams}`)}
            />
        </>
    ) : null;
}
