import createDisplayEnum, { EnumDisplayLabels } from 'utils/createDisplayEnum';

enum DocumentFriendlyType {
    Excel = 'excel',
    Image = 'image',
    Pdf = 'pdf',
    Text = 'text',
    Word = 'word',
    Unknown = 'unknown',
}

export default DocumentFriendlyType;

const labels: EnumDisplayLabels<typeof DocumentFriendlyType> = {
    Excel: 'Excel spreadsheet',
    Image: 'Image',
    Pdf: 'PDF document',
    Text: 'Text',
    Word: 'Word document',
    Unknown: 'Unknown',
};

export const DocumentFriendlyTypeDisplay = createDisplayEnum(DocumentFriendlyType, labels);

/** use file extension to determine type -
 * this is copied from old manufacturing BE logic */
export function getDocumentFriendlyType(filename: string) {
    // use file extension to determine type
    // this is copied from old manufacturing BE logic
    const tokens = filename.toLowerCase().split('.');
    const extension = tokens.length > 1 ? tokens[tokens.length - 1] : '';
    const friendlyType = {
        jpeg: DocumentFriendlyType.Image,
        jpg: DocumentFriendlyType.Image,
        png: DocumentFriendlyType.Image,
        gif: DocumentFriendlyType.Image,
        svg: DocumentFriendlyType.Image,

        pdf: DocumentFriendlyType.Pdf,

        doc: DocumentFriendlyType.Word,
        docx: DocumentFriendlyType.Word,
        odt: DocumentFriendlyType.Word,

        csv: DocumentFriendlyType.Excel,
        xls: DocumentFriendlyType.Excel,
        xlsx: DocumentFriendlyType.Excel,
        ods: DocumentFriendlyType.Excel,

        txt: DocumentFriendlyType.Text,
        msg: DocumentFriendlyType.Text,
    }[extension];

    return friendlyType ?? DocumentFriendlyType.Unknown;
}
