import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import MyCalendarDay from './MyCalendarDay';
import './MyCalendar.scss';

/** wrapper for mui calendar picker with a select-style input to activate it
 * see docs at https://mui.com/x/api/date-pickers/calendar-picker/
 */
export default function MyCalendar({
    value,
    onChange,
    onMonthChange,
    minDate,
    maxDate,
    className,
    selectionMode = 'day', // day | week
    shouldDisableDate,
}: {
    value?: DateTime;
    onChange?: (value: DateTime) => void;
    onMonthChange?: (value: DateTime) => void;
    minDate?: DateTime;
    maxDate?: DateTime;
    className?: string;
    selectionMode?: 'day' | 'week';
    shouldDisableDate?: (day: DateTime) => boolean;
}) {
    const [selectedMonth, setSelectedMonth] = useState<DateTime>();

    const handleDateSelected = (date: DateTime | null) => {
        // dont allow setting to null
        if (date) {
            onChange?.(date);
        }
    };

    const handleMonthSelected = (month: DateTime | null) => {
        // dont allow setting to null
        if (month) {
            setSelectedMonth(month);
            onMonthChange?.(month);
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterLuxon}>
            <DateCalendar
                className={coalesceClassNames(
                    'MyCalendar',
                    selectionMode === 'week' && 'MyCalendar--week',
                    className,
                )}
                value={value}
                onChange={handleDateSelected}
                onMonthChange={handleMonthSelected}
                minDate={minDate}
                maxDate={maxDate}
                showDaysOutsideCurrentMonth={selectionMode === 'week'}
                slots={{ day: MyCalendarDay }}
                shouldDisableDate={shouldDisableDate}
                slotProps={{
                    day: {
                        selectedDay: value,
                        selectedMonth,
                        selectionMode,
                    } as any,
                }}
            />
        </LocalizationProvider>
    );
}
