import { MySelectInputOption } from 'components/MySelectInput/MySelectInput';
import { useMemo } from 'react';
import salesApi from '../sales.api';

/** Get a list of sales item status options for use in select component
 * Optionally pass a `currentId` to include a disabled option if it is currently selected
 */
export function useSalesItemStatusOptions({ currentId }: { currentId?: number } = {}):
    | MySelectInputOption[]
    | undefined {
    const query = salesApi.useSalesItemStatusesQuery();
    const options = useMemo(() => {
        const opts = query.data
            ?.filter(o => o.isActive || o.id === currentId)
            .map(o => ({
                label: o.name,
                value: `${o.id}`,
                disabled: !o.isActive,
            }));
        return opts;
    }, [currentId, query.data]);

    return options;
}
