import Icons from 'Icons';
import MyButton from 'components/MyButton/MyButton';
import MyTextInput from 'components/MyTextInput/MyTextInput';
import PropertyDisplay from 'components/PropertyDisplay/PropertyDisplay';
import PropertyEdit from 'components/PropertyEdit/PropertyEdit';
import React, { useRef } from 'react';
import { generateShortUuid } from 'utils/helpers';
import './PropertyEditColor.scss';

export type PropertyEditColorProps = {
    value?: string;
    label: string;
    hint?: string;
    onChange?: (val?: string) => void;
    readonly?: boolean;
    disabled?: boolean;
    validationRequired?: string | boolean;
    validationCustom?: string | false;
};

export default function PropertyEditColor({
    value,
    label,
    hint,
    onChange,
    readonly = false,
    disabled = false,
    validationRequired,
    validationCustom,
}: PropertyEditColorProps) {
    const inputId = useRef(generateShortUuid()).current;

    const validationRequiredWithLabel =
        validationRequired === true ? `${label} is required` : validationRequired;

    if (readonly) {
        return (
            <PropertyDisplay
                label={label}
                hint={hint}
                value={
                    value && (
                        <div className="PropertyEditColor__Display">
                            <div
                                className="PropertyEditColor__Display__Swatch"
                                style={{ backgroundColor: value }}
                            />
                            {value}
                        </div>
                    )
                }
            />
        );
    }
    return (
        <PropertyEdit
            className="PropertyEditColor"
            label={label}
            hint={hint}
            inputId={inputId}
        >
            <MyTextInput
                className="PropertyEditColor__Input"
                id={inputId}
                value={value}
                type="color"
                disabled={disabled}
                handleInput={onChange}
                validationRequired={validationRequiredWithLabel}
                validationCustom={validationCustom}
            />
            {value && (
                <MyButton
                    className="PropertyEditColor__ClearButton"
                    buttonType="Secondary"
                    size="medium"
                    IconLeft={Icons.Close}
                    onClick={() => onChange?.('')}
                />
            )}
        </PropertyEdit>
    );
}
