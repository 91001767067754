import z from 'zod';
import { UserDefinedFieldSchema } from 'models/UserDefinedField';

export const InventoryGroupSchemaResultSchema = z.object({
    data: z.array(z.any()),
    schemaExtensions: z.object({
        udfs: z.array(UserDefinedFieldSchema),
    }),
});

export type InventoryGroupSchemaResult = z.infer<typeof InventoryGroupSchemaResultSchema>;
