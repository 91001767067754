import MyAutocompleteInput from 'components/MyAutocompleteInput/MyAutocompleteInput';
import React from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import {
    CriteriaCompProps,
    DataTableCriteriaFieldBase,
    DataTableCriteriaPlugin,
    DataTableCriteriaSelectOption,
} from '../DataTableCriteriaTypes';

export type AutocompleteCriteriaFieldConfig = DataTableCriteriaFieldBase & {
    type: 'autocomplete';
    field: string;
    options?: DataTableCriteriaSelectOption[];
};

function AutocompleteCriteriaField({
    field,
    onChange,
}: CriteriaCompProps<AutocompleteCriteriaFieldConfig>) {
    const handleChange = (val: string) => {
        field.value = val;
        onChange?.(field);
    };

    return (
        <div
            className={coalesceClassNames(
                'DataTableCriteria__Field',
                'DataTableCriteria__Field--Autocomplete',
                field.config.field && `DataTableCriteria__Field--${field.config.field}`,
            )}
        >
            <MyAutocompleteInput
                label={field.config.label}
                value={field.value}
                options={field.config.options}
                onChange={handleChange}
            />
        </div>
    );
}

const plugin: DataTableCriteriaPlugin<AutocompleteCriteriaFieldConfig> = {
    Component: AutocompleteCriteriaField,
};
export default plugin;
