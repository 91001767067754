import Icons from 'Icons';
import { MyMenuButtonItem } from 'components/MyMenuButton/MyMenuButton';
import MyMenuKebabButton from 'components/MyMenuKebabButton/MyMenuKebabButton';
import Draggable from 'components/ReactSmoothDnd/Draggable';
import { Schedule } from 'features/schedule/models/Schedule';
import { ScheduledWorkOrder } from 'features/schedule/models/ScheduledWorkOrder';
import scheduleApi from 'features/schedule/schedule.api';
import { selectLastMoveToSchedule } from 'features/schedule/schedule.slice';
import WorkOrderDetailModal from 'features/workOrders/components/WorkOrderDetailModal/WorkOrderDetailModal';
import useUrlQueryState from 'hooks/useUrlQueryState';
import { DateTime } from 'luxon';
import { useDialogManager } from 'providers/DialogManager';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import coalesceClassNames from 'utils/coalesceClassNames';
import { dateIsPast, formatDateRelative } from 'utils/dateHelpers';
import { isEmpty } from 'utils/helpers';
import ScheduleMoveToDateDialog from '../ScheduleMoveToDateDialog/ScheduleMoveToDateDialog';
import './ScheduleWorkOrderRow.scss';

export default function ScheduleWorkOrderRow({
    workOrder,
    schedule,
    isLoading = false,
    onAssign,
}: {
    workOrder: ScheduledWorkOrder;
    schedule: Schedule;
    isLoading?: boolean;
    onAssign: (schedule: Schedule, workOrders: ScheduledWorkOrder[], sortOrder?: number) => void;
}) {
    const dialogManager = useDialogManager();
    const handleMoveDate = useCallback(async () => {
        const newSchedule: Schedule | undefined = await dialogManager.custom(
            ScheduleMoveToDateDialog,
            {
                workOrder,
                currentSchedule: schedule,
            },
        );

        if (newSchedule) {
            onAssign(newSchedule, [workOrder]);
        }
    }, [dialogManager, onAssign, schedule, workOrder]);

    /** The schedule that an item as last moved to (this is not latestScheduleDate) */
    const lastMoveToSchedule = useAppSelector(selectLastMoveToSchedule);

    /** The latest date that this item can be scheduled and still meet its reqeusted date */
    const dtLatestScheduleDate = useMemo(
        () => DateTime.fromISO(workOrder.latestScheduleDate),
        [workOrder.latestScheduleDate],
    );

    /** True if this item can be moved to its latest scheduled date
     * - it is not already scheduled on that date
     * - and date is not in the past */
    const canMoveToLatest = useMemo(() => {
        const dtSched = DateTime.fromISO(schedule.date);
        const isPast = dateIsPast(dtLatestScheduleDate);
        const isSetAlready = dtLatestScheduleDate.hasSame(dtSched, 'day');
        return !isPast && !isSetAlready;
    }, [dtLatestScheduleDate, schedule.date]);

    const [scheduleListQuery] = scheduleApi.useLazyScheduleListQuery();

    const handleMoveToLatest = useCallback(async () => {
        // load the schedule for the selected date from the api
        const queryResult = await scheduleListQuery({
            dateFrom: dtLatestScheduleDate.startOf('day').toISODate() as string,
            dateTo: dtLatestScheduleDate.endOf('day').toISODate() as string,
        }).unwrap();

        // move the item
        const latestSchedule = queryResult[0];
        if (latestSchedule) {
            onAssign(latestSchedule, [workOrder]);
        }
    }, [dtLatestScheduleDate, onAssign, scheduleListQuery, workOrder]);

    // Ability to highlight and scroll to an order from url param
    const [highlightId, setHighlightId] = useUrlQueryState('highlight');
    const isHighlighted = highlightId === workOrder.id;

    const showWorkOrderDetail = useCallback(async () => {
        await dialogManager.custom(WorkOrderDetailModal, {
            workOrderId: workOrder.id,
            tuid: workOrder.tuid,
        });
    }, [dialogManager, workOrder.id, workOrder.tuid]);

    const kebabMenuItems = useMemo(
        () =>
            [
                {
                    label: 'View work order',
                    onClick: showWorkOrderDetail,
                },
                {
                    label: (
                        <>
                            Move to <em>select date...</em>
                        </>
                    ),
                    onClick: handleMoveDate,
                },

                lastMoveToSchedule &&
                    lastMoveToSchedule?.date !== schedule.date && {
                        label: (
                            <>
                                Move to <strong>recent</strong>{' '}
                                <em>
                                    {formatDateRelative(lastMoveToSchedule.date, {
                                        alwaysDate: true,
                                    })}
                                </em>
                            </>
                        ),
                        onClick: () => onAssign(lastMoveToSchedule, [workOrder]),
                    },
                {
                    label: (
                        <>
                            Move to <strong>latest schedule date</strong>{' '}
                            <em>
                                {formatDateRelative(workOrder.latestScheduleDate, {
                                    alwaysDate: true,
                                })}
                            </em>
                        </>
                    ),
                    disabled: !canMoveToLatest,
                    onClick: handleMoveToLatest,
                },
                isHighlighted && {
                    label: 'Clear highlight',
                    onClick: () => setHighlightId(''),
                },
            ].filter(Boolean) as MyMenuButtonItem[],
        [
            canMoveToLatest,
            handleMoveDate,
            handleMoveToLatest,
            isHighlighted,
            lastMoveToSchedule,
            onAssign,
            schedule.date,
            setHighlightId,
            showWorkOrderDetail,
            workOrder,
        ],
    );

    const [hasScrolled, setHasScrolled] = useState(false);
    const rowElem = React.createRef<HTMLDivElement>();
    useEffect(() => {
        if (isHighlighted && !hasScrolled && rowElem.current) {
            rowElem.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
            setHasScrolled(true);
        }
    }, [hasScrolled, isHighlighted, rowElem]);

    return (
        <Draggable>
            <div
                className={coalesceClassNames(
                    'ScheduleWorkOrderRow',
                    isHighlighted && 'ScheduleWorkOrderRow--highlight',
                )}
                data-id={workOrder.id}
                ref={rowElem}
            >
                <Cell
                    className="ScheduleWorkOrderRow__HandleCell"
                    value={
                        <>
                            {
                                <div className="ScheduleWorkOrderRow__DragHandle">
                                    <Icons.DragHandle className="icon " />
                                </div>
                            }
                        </>
                    }
                />
                <a
                    className="ScheduleWorkOrderRow__ClickableWrapper"
                    onClick={showWorkOrderDetail}
                >
                    <Cell
                        label={workOrder.tuid}
                        value={workOrder.identifier}
                    />
                    <Cell
                        label="Items"
                        value={workOrder.itemCount}
                    />
                    <Cell
                        label="Due"
                        value={formatDateRelative(workOrder.requestedDate, {
                            alwaysDate: true,
                            hideDayName: true,
                        })}
                    />
                    <Cell
                        label="Est. days"
                        value={workOrder.daysRequired}
                    />
                    <Cell
                        label="Est. completion"
                        value={formatDateRelative(workOrder.estimatedCompletionDate, {
                            alwaysDate: true,
                            hideDayName: true,
                        })}
                    />
                    <Cell
                        label="Slack days"
                        value={workOrder.slackDays}
                        className={coalesceClassNames(
                            'ScheduleWorkOrderRow__SlackDays',
                            workOrder.slackDays < 0 && 'negative',
                        )}
                    />
                    <Cell
                        label="Group"
                        value={workOrder.workstationGroupAssignment?.name}
                    />
                </a>
                <Cell
                    className="ScheduleWorkOrderRow__Menu"
                    value={
                        <MyMenuKebabButton
                            className="ScheduleWorkOrderRow__Menu__Button"
                            disabled={isLoading}
                            menuItems={kebabMenuItems}
                            buttonType="None"
                        />
                    }
                />
            </div>
        </Draggable>
    );
}

function Cell({
    label,
    value,
    className,
}: {
    label?: React.ReactNode;
    value?: React.ReactNode;
    className?: string;
}) {
    return (
        <div className={coalesceClassNames('ScheduleWorkOrderRow__Cell', className)}>
            {label && <div className="ScheduleWorkOrderRow__Cell__Label">{label}</div>}
            <div className="ScheduleWorkOrderRow__Cell__Value">{isEmpty(value) ? '-' : value}</div>
        </div>
    );
}
