import createDisplayEnum, { EnumDisplayLabels } from 'utils/createDisplayEnum';

enum SalesOrderCoreStatus {
    Unstarted = 'UNSTARTED',
    InProgress = 'IN_PROGRESS',
    OnHold = 'ON_HOLD',
    Cancelled = 'CANCELLED',
    Completed = 'COMPLETED',
}
export default SalesOrderCoreStatus;

const labels: EnumDisplayLabels<typeof SalesOrderCoreStatus> = {
    Unstarted: 'Unstarted',
    InProgress: 'In progress',
    OnHold: 'On hold',
    Cancelled: 'Cancelled',
    Completed: 'Completed',
};

export const SalesOrderCoreStatusDisplay = createDisplayEnum(SalesOrderCoreStatus, labels);
