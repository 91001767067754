import React from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import MyButton, { ButtonType } from '../MyButton/MyButton';
import Dialog from './Dialog';

export type ConfirmProps = {
    title?: string | React.ReactFragment;
    message?: string | React.ReactFragment;
    acceptLabel?: string;
    cancelLabel?: string;
    acceptButtonType?: ButtonType;
    cancelButtonType?: ButtonType;
    cancelButtonClassName?: string;
    acceptButtonClassName?: string;
    className?: string;
    close?: (confirm: boolean) => void;
};

export default function Confirm({
    title,
    message,
    acceptLabel = 'OK',
    cancelLabel = 'Cancel',
    acceptButtonType = 'Primary',
    cancelButtonType = 'Hollow',
    cancelButtonClassName,
    acceptButtonClassName,
    className,
    close,
}: ConfirmProps) {
    return (
        <Dialog
            className={coalesceClassNames('DialogManager__dialog--confirm', className)}
            close={() => close?.(false)}
            title={title}
            message={message}
            buttons={
                <>
                    {/* accept */}
                    <MyButton
                        buttonType={acceptButtonType}
                        className={coalesceClassNames(
                            'DialogManager__dialog__accept-button',
                            acceptButtonClassName,
                        )}
                        onClick={() => close?.(true)}
                    >
                        {acceptLabel}
                    </MyButton>

                    {/* cancel */}
                    <MyButton
                        buttonType={cancelButtonType}
                        className={coalesceClassNames(
                            'DialogManager__dialog__cancel-button',
                            cancelButtonClassName,
                        )}
                        onClick={() => close?.(false)}
                    >
                        {cancelLabel}
                    </MyButton>
                </>
            }
        />
    );
}
