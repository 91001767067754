import { dateTimeOldSystem } from 'utils/dateSchemas';
import z from 'zod';

export const SalesOrderWorksheetOldSchema = z.union([
    z.object({
        id: z.number(),
        filename: z.string(),
        extension: z.string(), // 'xlsx',
        mime_type: z.string(), // 'application/vnd.ms-excel',
        type: z.string(), // 'excel',
        name: z.string(),
        direct_upload: z.number(),
        created_by: z.number(),
        updated_at: dateTimeOldSystem({ allowBlank: true, isUTC: true }).nullable(), // '2023-10-25 00:12:53',
        created_at: dateTimeOldSystem({ isUTC: true }), // '2023-10-25 00:12:53',
        url: z.string(),
        error_message: z.literal(undefined),
    }),
    z.object({
        error_message: z.string(),
    }),
]);

export type SalesOrderWorksheetOld = z.infer<typeof SalesOrderWorksheetOldSchema>;
