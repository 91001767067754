import Icons from 'Icons';
import MyButton, { MyButtonLink, MyButtonLinkNewTab } from 'components/MyButton/MyButton';
import PageHeader from 'components/PageHeader/PageHeader';
import Env from 'config/Env';
import InventoryTable from 'features/inventory/components/InventoryTable/InventoryTable';
import inventoryApi from 'features/inventory/inventory.api';
import useFileUploader from 'hooks/useFileUploader';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { usePageTitle } from 'utils/locationHelpers';
import './InventoryPage.scss';

export default function InventoryPage() {
    usePageTitle('Inventory');
    const exportUrl = `${Env.API_BASE_URL}/inventory/export`;
    const [importQuery, importQueryState] = inventoryApi.useInventoryImportMutation();

    const fileUploader = useFileUploader(file => importQuery(file).unwrap());

    return (
        <>
            <PageHeader title="Inventory">
                <div className="InventoryPage__Actions">
                    <MyButton
                        buttonType="Accent"
                        size="medium"
                        label="Add Inventory"
                        IconLeft={Icons.Plus}
                        href="add"
                        LinkComponent={MyButtonLink}
                        disabled={importQueryState.isLoading}
                    />
                    <MyButton
                        buttonType="Secondary"
                        size="medium"
                        label="Import"
                        IconLeft={Icons.Upload}
                        onClick={fileUploader.onClick}
                        isLoading={importQueryState.isLoading}
                    />
                    <MyButton
                        buttonType="Secondary"
                        size="medium"
                        label="Export"
                        IconLeft={Icons.Export}
                        href={exportUrl}
                        LinkComponent={MyButtonLinkNewTab}
                        disabled={importQueryState.isLoading}
                    />
                </div>
            </PageHeader>

            <InventoryTable />

            <fileUploader.HiddenFileInput />

            <Outlet />
        </>
    );
}
