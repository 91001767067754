import z from 'zod';
import WorkflowStepType from '../enums/WorkflowStepType';

export const WorkflowStepEntitySchema = z.object({
    name: z.string(),
    id: z.string(),
    stepType: z.nativeEnum(WorkflowStepType).default(WorkflowStepType.Default),
});

export type WorkflowStepEntity = z.infer<typeof WorkflowStepEntitySchema>;
