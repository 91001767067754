import React from 'react';
import coalesceClassNames, { extendClassName } from 'utils/coalesceClassNames';
import './PropertyEdit.scss';

/** Wrapper component for all other PropertyEdit types */
export default function PropertyEdit({
    className,
    label,
    hint,
    inlineHint,
    inputId,
    children,
    verticalAlign = 'middle',
}: {
    className?: string;
    label: string;
    hint?: React.ReactNode;
    inlineHint?: React.ReactNode;
    inputId?: string;
    children?: React.ReactFragment;
    /** Only applies to table layout */
    verticalAlign?: 'top' | 'middle';
}) {
    return (
        <div
            className={coalesceClassNames(
                'PropertyEdit',
                verticalAlign === 'top' && `PropertyEdit--verticalAlign-top`,
                className,
            )}
        >
            <label
                htmlFor={inputId}
                className="PropertyEdit__Label"
            >
                <div className="PropertyEdit__Label__Text">
                    {label}
                    {inlineHint && (
                        <>
                            {' '}
                            <span className="PropertyEdit__Label__Text__InlineHint">
                                {inlineHint}
                            </span>
                        </>
                    )}
                </div>
                {hint && <div className="PropertyEdit__Label__Hint">{hint}</div>}
            </label>
            <div
                className={coalesceClassNames(
                    'PropertyEdit__InputWrapper',
                    className && extendClassName(className, '__InputWrapper'),
                )}
            >
                {children}
            </div>
        </div>
    );
}
