import DocumentUploadModal from 'features/documents/components/DocumentUploadModal/DocumentUploadModal';
import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { usePageTitle } from 'utils/locationHelpers';

export default function DocumentUploadPage() {
    usePageTitle('Upload');
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    return (
        <>
            <DocumentUploadModal
                directUpload={false}
                showBrand={true}
                close={() => navigate(`..?${searchParams}`)}
            />
        </>
    );
}
