import ShipmentCreateModal from 'features/dispatch/components/ShipmentCreateModal/ShipmentCreateModal';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { usePageTitle } from 'utils/locationHelpers';

export default function ShipmentCreatePage() {
    usePageTitle('Create');
    const navigate = useNavigate();

    return <ShipmentCreateModal close={() => navigate(`/shipments`)} />;
}
