import { AttributeFieldSchema } from 'models/AttributeField';
import z from 'zod';

export const InventoryGroupListWithAttributesResultSchema = z.object({
    data: z.array(
        z.object({
            id: z.string(),
            name: z.string(),
            context: z.object({
                attributes: z.array(AttributeFieldSchema),
            }),
        }),
    ),
    total: z.number(),
});

export type InventoryGroupListWithAttributesResult = z.infer<
    typeof InventoryGroupListWithAttributesResultSchema
>;
