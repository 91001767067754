import z from 'zod';
import { SalesOrderWorksheetOldSchema } from './SalesOrderWorksheetOld';

export const SalesOrderGenerateWorksheetsResultOldSchema = z.object({
    data: z.object({
        xls: z.record(z.string(), SalesOrderWorksheetOldSchema),
    }),
});

export type SalesOrderGenerateWorksheetsResultOld = z.infer<
    typeof SalesOrderGenerateWorksheetsResultOldSchema
>;
