import z from 'zod';
import { CountSheetInventorySchema } from './CountSheetInventory';

export const CountSheetSchema = z.object({
    id: z.string(),
    key: z.string(),
    stocktakeId: z.string(),
    inventory: z.array(CountSheetInventorySchema),
});

export type CountSheet = z.infer<typeof CountSheetSchema>;
