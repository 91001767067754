import z from 'zod';
import WorkItemStatus from '../enums/WorkItemStatus';
import { WorkOrderItemWithOptionsSchema } from './WorkOrderItemWithOptions';

export const WorkOrderItemWithStateSchema = WorkOrderItemWithOptionsSchema.extend({
    context: z.object({
        workOrderItemState: z.nativeEnum(WorkItemStatus),
    }),
});

export type WorkOrderItemWithState = z.infer<typeof WorkOrderItemWithStateSchema>;
