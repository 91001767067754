import z from 'zod';
import { InventorySchema } from './Inventory';
import { UserDefinedFieldSchema } from 'models/UserDefinedField';

export const InventorySearchResultSchema = z.object({
    data: z.array(InventorySchema),
    total: z.number(),
    schemaExtensions: z.object({
        udfs: z.array(UserDefinedFieldSchema),
    }),
});

export type InventorySearchResult = z.infer<typeof InventorySearchResultSchema>;
