import z from 'zod';
import { User } from './User';

export const UserEditSchema = z.object({
    id: z.number(),
    first_name: z.string(),
    last_name: z.string(),
    email: z.string(),
});

export type UserEdit = z.infer<typeof UserEditSchema>;

export class UserEditFactory {
    static create(user: User) {
        const model: UserEdit = {
            id: user.id,
            first_name: user.firstName,
            last_name: user.lastName,
            email: user.email,
        };

        return model;
    }
}
