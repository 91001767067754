import MyAutocompleteInput from 'components/MyAutocompleteInput/MyAutocompleteInput';
import React from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import { getDeepValue, isEmpty } from 'utils/helpers';
import {
    DataTableFilterBase,
    DataTableFilterPlugin,
    DataTableFilterSelectOption,
    FilterCompProps,
} from '../DataTableFilterTypes';

export type AutocompleteFilterConfig = DataTableFilterBase & {
    type: 'autocomplete';
    field: string;
    options?: DataTableFilterSelectOption[];
};

function AutocompleteFilter({ filter, onChange }: FilterCompProps<AutocompleteFilterConfig>) {
    const handleChange = (val: string) => {
        filter.value = val;
        onChange?.(filter);
    };

    return (
        <div
            className={coalesceClassNames(
                'DataTableFilters__Field',
                'DataTableFilters__Field--AutocompleteFilter',
                filter.config.field &&
                    `DataTableFilters__Field--AutocompleteFilter--${filter.config.field}`,
            )}
        >
            <MyAutocompleteInput
                label={filter.config.label}
                value={filter.value}
                options={filter.config.options}
                onChange={handleChange}
            />
        </div>
    );
}

const plugin: DataTableFilterPlugin<AutocompleteFilterConfig> = {
    Component: AutocompleteFilter,
    applyFilter: (filter, value, item) => {
        let val = getDeepValue(item, filter.field);
        if (isEmpty(val)) {
            val = '';
        }
        return String(val) === value;
    },
};
export default plugin;
