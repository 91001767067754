import createDisplayEnum, { EnumDisplayLabels } from 'utils/createDisplayEnum';

enum CustomerPaymentMethod {
    Default = 'default',
    Account = 'account',
    COD = 'cod',
    ProForma = 'proforma',
}
export default CustomerPaymentMethod;

const labels: EnumDisplayLabels<typeof CustomerPaymentMethod> = {
    Default: 'Default',
    Account: 'Account',
    COD: 'COD',
    ProForma: 'Pro forma',
};

export const CustomerPaymentMethodDisplay = createDisplayEnum(CustomerPaymentMethod, labels);
