import createDisplayEnum, { EnumDisplayLabels } from 'utils/createDisplayEnum';

enum InventoryType {
    NonStock = 'NONSTOCK',
    Stocked = 'STOCKED',
    BuiltBOM = 'BUILTBOM',
}
export default InventoryType;

const labels: EnumDisplayLabels<typeof InventoryType> = {
    NonStock: 'Non Stock',
    Stocked: 'Stocked',
    BuiltBOM: 'Built BOM',
};

export const InventoryTypeDisplay = createDisplayEnum(InventoryType, labels);
