import createDisplayEnum, { EnumDisplayLabels } from 'utils/createDisplayEnum';

enum CustomerAccessType {
    Full = 'full',
    DealerPortal = 'manufacturer_dealer_portal',
}
export default CustomerAccessType;

const labels: EnumDisplayLabels<typeof CustomerAccessType> = {
    Full: 'Full',
    DealerPortal: 'Dealer portal',
};

export const CustomerAccessTypeDisplay = createDisplayEnum(CustomerAccessType, labels);
