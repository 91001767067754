import Icons from 'Icons';
import MyButton, { MyButtonLink } from 'components/MyButton/MyButton';
import PageHeader from 'components/PageHeader/PageHeader';
import InventoryGroupsTable from 'features/inventory/components/InventoryGroupsTable/InventoryGroupsTable';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { usePageTitle } from 'utils/locationHelpers';
import './InventoryGroupsPage.scss';

export default function InventoryGroupsPage() {
    usePageTitle('Inventory Groups');
    return (
        <>
            <PageHeader title="Inventory Groups">
                <div className="InventoryGroupsPage__Actions">
                    <MyButton
                        buttonType="Accent"
                        size="medium"
                        label="Add Group"
                        IconLeft={Icons.Plus}
                        href="add"
                        LinkComponent={MyButtonLink}
                    />
                </div>
            </PageHeader>

            <InventoryGroupsTable />

            <Outlet />
        </>
    );
}
