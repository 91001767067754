import z from 'zod';

export const AddressSchema = z.object({
    addressLine1: z.string(),
    addressLine2: z.string().optional(),
    city: z.string(),
    country: z.string().optional(),
    postcode: z.string(),
    state: z.string(),
});

export type Address = z.infer<typeof AddressSchema>;
