import WorkItemStepStatus from 'features/workOrders/enums/WorkItemStepStatus';
import z from 'zod';
import WorkItemStepTransitionReason from '../enums/WorkItemStepTransitionReason';

export const WorkOrderItemStepEntitySchema = z.object({
    id: z.string(),
    workflowStepId: z.string(),
    workOrderItemId: z.string(),
    status: z.nativeEnum(WorkItemStepStatus),
    lastTransitionTimestamp: z.string().optional(),
    lastTransitionWorkstationId: z.string().optional(),
    lastTransitionPrincipalId: z.string().optional(),
    lastTransitionReason: z.nativeEnum(WorkItemStepTransitionReason).nullable().optional(),
    sortOrder: z.number(),
});

export type WorkOrderItemStepEntity = z.infer<typeof WorkOrderItemStepEntitySchema>;
