import createDisplayEnum, { EnumDisplayLabels } from 'utils/createDisplayEnum';

enum WorkflowBreakdownFieldSource {
    FixedLabel = 'FIXED_LABEL',
    FixedOption = 'FIXED_OPTION',
    Label = 'LABEL',
    Option = 'OPTION',
}
export default WorkflowBreakdownFieldSource;

const labels: EnumDisplayLabels<typeof WorkflowBreakdownFieldSource> = {
    FixedLabel: 'Fixed label',
    FixedOption: 'Fixed option',
    Label: 'Label',
    Option: 'Option',
};

export const WorkflowBreakdownFieldSourceDisplay = createDisplayEnum(
    WorkflowBreakdownFieldSource,
    labels,
);
