import { mergeDeep } from 'utils/helpers';
import z from 'zod';
import StocktakeTargetType from '../enums/StocktakeTargetType';
import StocktakeType from '../enums/StocktakeType';

export const StocktakeCreateSchema = z.object({
    id: z.string(), // uuid
    notes: z.string(),
    parentLocationId: z.string(),
    stocktakeType: z.nativeEnum(StocktakeType),
    targets: z.array(
        z.object({
            targetId: z.string(),
            targetType: z.nativeEnum(StocktakeTargetType),
        }),
    ),
});

export type StocktakeCreate = z.infer<typeof StocktakeCreateSchema>;

export class StocktakeCreateFactory {
    static create(data?: Partial<StocktakeCreate>) {
        const defaults: StocktakeCreate = {
            id: '',
            notes: '',
            parentLocationId: '',
            stocktakeType: StocktakeType.Complete,
            targets: [],
        };

        const mergedData = mergeDeep(defaults, data);

        return StocktakeCreateSchema.parse(mergedData);
    }
}
