import InventoryGroupDetailModal from 'features/inventory/components/InventoryGroupDetailModal/InventoryGroupDetailModal';
import inventoryApi from 'features/inventory/inventory.api';
import {
    InventoryGroupDetail,
    InventoryGroupDetailFactory,
} from 'features/inventory/models/InventoryGroupDetail';
import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePageTitle } from 'utils/locationHelpers';

export default function InventoryAddPage() {
    usePageTitle('Add');
    const navigate = useNavigate();

    const model = useRef(InventoryGroupDetailFactory.create({})).current;

    // Run search query to get UDFs (should already be cached)
    const schemaQuery = inventoryApi.useInventoryGroupSchemaQuery();

    const [createQuery, createQueryState] = inventoryApi.useInventoryGroupCreateMutation();

    const handleSave = async (data: InventoryGroupDetail) => {
        const result = (await createQuery(data)) as any;
        if (result.data) {
            navigate(`/inventorygroups/${result.data}`);
        } else {
            throw new Error('Create Inventory result did not contain an id');
        }
    };

    return (
        <InventoryGroupDetailModal
            model={model}
            isNew={true}
            // udfs={schemaQuery.data?.schemaExtensions.udfs}
            isLoading={schemaQuery.isLoading}
            isError={schemaQuery.isError}
            onSave={handleSave}
            isSaving={createQueryState.isLoading}
            close={() => navigate('/inventorygroups')}
        />
    );
}
