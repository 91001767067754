import Icons from 'Icons';
import MyButton, { MyButtonLink } from 'components/MyButton/MyButton';
import PageHeader from 'components/PageHeader/PageHeader';
import StocktakeTable from 'features/stocktake/components/StocktakeTable/StocktakeTable';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { usePageTitle } from 'utils/locationHelpers';
import './StocktakePage.scss';

export default function StocktakePage() {
    usePageTitle('Stocktake');

    return (
        <>
            <PageHeader title="Stocktake">
                <div className="StocktakePage__Actions">
                    <MyButton
                        buttonType="Accent"
                        size="medium"
                        label="New stocktake"
                        IconLeft={Icons.Plus}
                        href="create"
                        LinkComponent={MyButtonLink}
                    />
                </div>
            </PageHeader>

            <StocktakeTable />

            <Outlet />
        </>
    );
}
