import { MyCheckboxTreeNode } from 'components/MyCheckboxTree/MyCheckboxTree';
import inventoryApi from 'features/inventory/inventory.api';
import { InventoryGroupSummary } from 'features/inventory/models/InventoryGroupSummary';
import { useMemo } from 'react';
import typescriptNaturalSort from 'typescript-natural-sort';
import StocktakeTargetType from '../enums/StocktakeTargetType';
import { StocktakeTarget } from '../models/StocktakeTarget';

/** The checkbox tree nodes for selecting Inventory Groups
 * this is only a flat list with no nesting
 */
export default function useGroupNodes({
    targets,
    appendMissingIds,
}: {
    targets?: StocktakeTarget[];
    appendMissingIds?: boolean;
}) {
    const groupsQuery = inventoryApi.useInventoryGroupListQuery();
    return useGroupNodesPure({
        targets,
        appendMissingIds,
        allGroupData: groupsQuery.data?.data ?? [],
    });
}

export function useGroupNodesPure({
    targets,
    appendMissingIds,
    allGroupData,
}: {
    targets?: StocktakeTarget[];
    appendMissingIds?: boolean;
    allGroupData: InventoryGroupSummary[];
}) {
    const selectedInventoryGroupIds = useMemo(
        () =>
            targets
                ?.filter(t => t.targetType === StocktakeTargetType.InventoryGroup)
                .map(t => t.targetId),
        [targets],
    );

    const groupNodes = useMemo(() => {
        const nodes = allGroupData.map<MyCheckboxTreeNode>(l => ({
            id: l.id,
            label: l.name,
            nodes: [],
        }));

        nodes?.sort((a, b) => typescriptNaturalSort(a.label, b.label));

        // append any selected ids that don't appear in the tree
        // i.e locations that have been deleted
        if (appendMissingIds) {
            selectedInventoryGroupIds
                ?.filter(id => !allGroupData.find(l => l.id === id))
                .forEach(id =>
                    nodes.push({
                        id,
                        label: id,
                        nodes: [] as MyCheckboxTreeNode[] | undefined,
                    }),
                );
        }
        return nodes;
    }, [allGroupData, appendMissingIds, selectedInventoryGroupIds]);

    return [groupNodes, selectedInventoryGroupIds] as const;
}
