import UserDefinedFieldValueType from 'enums/UserDefinedFieldValueType';
import z from 'zod';

export const AttributeFieldSchema = z.object({
    id: z.string(),
    canBeOverridden: z.boolean().optional(),
    name: z.string(),
    uniqueKey: z.string(),
    validationData: z.object({
        defaultValue: z.any().optional(),
        maxLength: z.number().optional(),
        maxSelectedOptions: z.number().optional(),
        maxValue: z.number().optional(),
        minSelectedOptions: z.number().optional(),
        minValue: z.number().optional(),
        options: z.array(z.string()).optional(),
        regex: z.string().optional(),
        required: z.boolean().optional().default(false),
    }),
    valueType: z.nativeEnum(UserDefinedFieldValueType),
});

export type AttributeField = z.infer<typeof AttributeFieldSchema>;
