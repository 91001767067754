import { ScheduleEntitySchema } from 'features/schedule/models/ScheduleEntity';
import { ScheduleMetaEntitySchema } from 'features/schedule/models/ScheduleMetaEntity';
import { WorkOrderEntitySchema } from 'features/workOrders/models/WorkOrderEntity';
import { WorkOrderItemEntitySchema } from 'features/workOrders/models/WorkOrderItemEntity';
import { WorkOrderItemStepEntitySchema } from 'features/workOrders/models/WorkOrderItemStepEntity';
import { WorkflowStepEntitySchema } from 'features/workOrders/models/WorkflowStepEntity';
import { WorkflowStepInstanceEntitySchema } from 'features/workOrders/models/WorkflowStepInstanceEntity';
import { WorkstationWorkItemSchema } from 'features/workOrders/models/WorkstationWorkItem';
import z from 'zod';
import StepCanTransition from '../../workOrders/enums/StepCanTransition';
import { WorkstationSchema } from './Workstation';

export const WorkstationStepDetailSchema = WorkOrderItemStepEntitySchema.extend({
    context: z.object({
        canTransition: z.nativeEnum(StepCanTransition),
        salesOrder: z.object({
            id: z.string(),
            tuid: z.string(),
        }),
        schedule: ScheduleEntitySchema,
        scheduleMeta: ScheduleMetaEntitySchema,
        workflowStep: WorkflowStepEntitySchema,
        workflowStepInstance: WorkflowStepInstanceEntitySchema,
        workOrder: WorkOrderEntitySchema,
        workOrderItem: WorkstationWorkItemSchema,
        /** this is the station which last updated the status or null if Unstarted */
        workstationLatest: WorkstationSchema.optional(),
        /** if UnstartedBlocked, this will tell you the work order item step that may be blocking */
        workOrderItemStepsBlocking: z.array(
            z.object({
                workOrderItemStep: WorkOrderItemStepEntitySchema,
                workflowStep: WorkflowStepEntitySchema,
            }),
        ),
        /** if UnstartedBlocked, this will tell you the work order item that may be blocking */
        workOrderItemBlocking: z.array(WorkOrderItemEntitySchema).optional(),
    }),
});

export type WorkstationStepDetail = z.infer<typeof WorkstationStepDetailSchema>;
