import DataTable, { ColumnBuilder } from 'components/DataTable/DataTable';
import DataTableFilters, {
    DataTableFilterConfig,
} from 'components/DataTableFilters/DataTableFilters';
import inventoryApi from 'features/inventory/inventory.api';
import { InventoryGroupSummary } from 'features/inventory/models/InventoryGroupSummary';
import React, { useState } from 'react';
import './InventoryGroupsTable.scss';

const COLUMN_DEFS = ColumnBuilder<InventoryGroupSummary>()
    .column({
        label: 'Name',
        key: 'name',
        getValue: item => item.name,
        isSortable: true,
    })
    .build();

const FILTER_DEFS: DataTableFilterConfig[] = [
    {
        label: 'Search',
        type: 'search',
        defaultValue: '',
        fields: ['name'],
    },
];

export default function InventoryGroupsTable() {
    const query = inventoryApi.useInventoryGroupListQuery();
    const [filteredData, setFilteredData] = useState<InventoryGroupSummary[]>();

    return (
        <div className="InventoryGroupsTable">
            <div className="InventoryGroupsTable__FilterBar">
                <DataTableFilters
                    data={query.data?.data}
                    filters={FILTER_DEFS}
                    onChange={setFilteredData}
                />
            </div>

            <DataTable
                className="InventoryGroupsTable__DataTable"
                isLoading={query.isLoading}
                isError={query.isError}
                data={filteredData}
                onRefresh={query.refetch}
                rowLinkTo={item => item.id}
                zebra={true}
                useStickyHeader={true}
                useFrontEndSorting={true}
                isRefreshing={query.isFetching}
                columns={COLUMN_DEFS}
            />
        </div>
    );
}
