import Icons from 'Icons';
import MyButton from 'components/MyButton/MyButton';
import PageHeader from 'components/PageHeader/PageHeader';
import PropertyContainer from 'components/PropertyContainer/PropertyContainer';
import PropertyDisplay from 'components/PropertyDisplay/PropertyDisplay';
import { selectCurrentUser } from 'features/auth/auth.slice';
import UserEditModal from 'features/auth/components/UserEditModal/UserEditModal';
import { getUserFullName, getUserInitials } from 'features/auth/models/User';
import { UserEditFactory } from 'features/auth/models/UserEdit';
import { useDialogManager } from 'providers/DialogManager';
import React, { useCallback, useMemo } from 'react';
import { useAppSelector } from 'store/hooks';
import { usePageTitle } from 'utils/locationHelpers';
import './ProfilePage.scss';

export default function ProfilePage() {
    usePageTitle('Profile');
    const user = useAppSelector(selectCurrentUser);
    const model = useMemo(() => (user ? UserEditFactory.create(user) : undefined), [user]);

    const dialogManager = useDialogManager();
    const edit = useCallback(() => {
        dialogManager.custom(UserEditModal, {
            model,
        });
    }, [dialogManager, model]);

    return (
        <>
            <PageHeader
                title="Profile"
                titleContext={getUserFullName(user)}
            />
            {model && (
                <div className="ProfilePage__Container">
                    <div className="ProfilePage__Actions">
                        <MyButton
                            label="Edit"
                            IconLeft={Icons.Edit}
                            buttonType="Accent"
                            onClick={edit}
                        />
                    </div>
                    <div className="ProfilePage__Main">
                        <div className="ProfilePage__Main__Badge">{getUserInitials(user)}</div>
                        <PropertyContainer className="ProfilePage__Main__Fields">
                            <PropertyDisplay
                                label="First name"
                                value={model?.first_name}
                            />
                            <PropertyDisplay
                                label="Last name"
                                value={model?.last_name}
                            />
                            <PropertyDisplay
                                label="Email"
                                value={model?.email}
                            />
                        </PropertyContainer>
                    </div>
                </div>
            )}
        </>
    );
}
