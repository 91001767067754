import PageHeader from 'components/PageHeader/PageHeader';
import WorkstationSelector from 'features/workstations/components/WorkstationSelector/WorkstationSelector';
import { selectCurrentWorkstation } from 'features/workstations/workstations.slice';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import { usePageTitle } from 'utils/locationHelpers';

// show the list of workstations
export default function WorkstationsIndexPage() {
    usePageTitle('Workstations');
    const currentWorkstation = useAppSelector(selectCurrentWorkstation);
    const navigate = useNavigate();

    useEffect(() => {
        if (currentWorkstation) {
            navigate(currentWorkstation.id);
        }
    }, [currentWorkstation, navigate]);

    return (
        <>
            <PageHeader
                title="Workstations"
                subtitle="Choose a workstation to get started"
            />
            {!currentWorkstation && <WorkstationSelector />}
        </>
    );
}
