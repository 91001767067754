import CustomerDetailModal from 'features/customers/components/CustomerDetailModal/CustomerDetailModal';
import customersApi from 'features/customers/customers.api';
import React, { useMemo } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { usePageTitle } from 'utils/locationHelpers';

export default function CustomerDetailPage() {
    const { customerId = '' } = useParams();

    // retain url query params when navigating
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const query = customersApi.useCustomerListQuery();
    const model = useMemo(
        () => query.data?.find(o => String(o.id) === customerId),
        [customerId, query.data],
    );
    usePageTitle(model?.name);

    return customerId ? (
        <>
            <CustomerDetailModal
                model={model}
                // Use query.isFetching here instead of isLoading
                // so that it also shows spinner when refreshing after an update
                isLoading={query.isFetching}
                isError={query.data && !model}
                close={() => navigate(`/customers?${searchParams}`)}
            />
        </>
    ) : null;
}
