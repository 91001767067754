import z from 'zod';
import InventoryMovementBatchType from '../enums/InventoryMovementBatchType';

export const InventoryItemMovementSchema = z.object({
    id: z.string(),
    batchId: z.string(),
    batchNumber: z.string(),
    locationId: z.string(),
    locationName: z.string(),
    warehouseName: z.string(),
    warehouseId: z.string(),
    date: z.string(),
    quantity: z.number(),
    movementType: z.nativeEnum(InventoryMovementBatchType),
    notes: z.string(),
});

export type InventoryItemMovement = z.infer<typeof InventoryItemMovementSchema>;
