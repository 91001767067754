import { MySelectInputOption } from 'components/MySelectInput/MySelectInput';
import { useMemo } from 'react';
import typescriptNaturalSort from 'typescript-natural-sort';
import LocationType from '../enums/LocationType';
import inventoryApi from '../inventory.api';

export function useWarehouseOptions(): MySelectInputOption[] | undefined {
    const query = inventoryApi.useLocationListQuery();
    const options = useMemo(() => {
        const opts = query.data?.data
            .filter(loc => loc.locationType === LocationType.Warehouse && !loc.parentLocationId)
            .map(loc => ({
                label: loc.name,
                value: loc.id,
            }))
            .sort((a, b) => typescriptNaturalSort(a.label, b.label));
        return opts ?? [];
    }, [query.data]);

    return options;
}
