import createDisplayEnum, { EnumDisplayLabels } from 'utils/createDisplayEnum';

enum StocktakeType {
    Complete = 'COMPLETE',
    Rolling = 'ROLLING',
}
export default StocktakeType;

const labels: EnumDisplayLabels<typeof StocktakeType> = {
    Complete: 'Complete',
    Rolling: 'Rolling',
};

export const StocktakeTypeDisplay = createDisplayEnum(StocktakeType, labels);
