import { MySelectInputOption } from 'components/MySelectInput/MySelectInput';
import { useMemo } from 'react';
import customersApi from '../customers.api';

export function useBrandOptions(): MySelectInputOption[] | undefined {
    const query = customersApi.useBrandListQuery();
    const options = useMemo(() => {
        const opts = query.data?.map(s => ({
            label: s.name,
            value: `${s.id}`,
        }));
        return opts ?? [];
    }, [query.data]);

    return options;
}
