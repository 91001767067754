import { ApiTagType, api } from 'services/api';
import z from 'zod';
import StocktakeImportErrorType from './enums/StocktakeImportErrorCode';
import { CountSheet, CountSheetSchema } from './models/CountSheet';
import {
    CountSheetStatusesResult,
    CountSheetStatusesResultSchema,
} from './models/CountSheetStatusesResult';
import { Stocktake, StocktakeSchema } from './models/Stocktake';
import { StocktakeCreate } from './models/StocktakeCreate';
import { StocktakeDetailResult, StocktakeDetailResultSchema } from './models/StocktakeDetailResult';
import { StocktakeDiscrepancy, StocktakeDiscrepancySchema } from './models/StocktakeDiscrepancy';
import { StocktakeImportError, StocktakeImportErrorSchema } from './models/StocktakeImportError';
import { StocktakeListResult, StocktakeListResultSchema } from './models/StocktakeListResult';

const stocktakeApi = api.injectEndpoints({
    endpoints: build => ({
        stocktakeList: build.query<StocktakeListResult, { includeArchived: boolean }>({
            query: params => ({
                url: `/stocktake`,
                method: 'POST',
                data: {
                    data: null,
                    meta: {
                        includeArchived: params.includeArchived,
                        limit: 9999999,
                    },
                },
            }),
            transformResponse: (result: unknown) => StocktakeListResultSchema.parse(result),
            providesTags: () => [ApiTagType.StocktakeList],
        }),
        stocktakeCreate: build.mutation<Stocktake, StocktakeCreate>({
            query: model => ({
                url: `/stocktake/create`,
                method: 'POST',
                data: model,
            }),
            transformResponse: (result: unknown) => StocktakeSchema.parse(result),
            invalidatesTags: () => [ApiTagType.StocktakeList],
        }),
        stocktakeArchive: build.mutation<void, string>({
            query: id => ({
                url: `/stocktake/${id}/archive`,
                method: 'POST',
            }),
            transformResponse: () => undefined,
            invalidatesTags: (res, req, id) => [
                ApiTagType.StocktakeList,
                { type: ApiTagType.StocktakeDetail, id },
            ],
        }),
        stocktakeUnarchive: build.mutation<void, string>({
            query: id => ({
                url: `/stocktake/${id}/unarchive`,
                method: 'POST',
            }),
            transformResponse: () => undefined,
            invalidatesTags: (res, req, id) => [
                ApiTagType.StocktakeList,
                { type: ApiTagType.StocktakeDetail, id },
            ],
        }),
        stocktakeDetail: build.query<StocktakeDetailResult, string>({
            query: id => ({
                url: `/stocktake/${id}/detail`,
                method: 'GET',
            }),
            transformResponse: (result: unknown) => StocktakeDetailResultSchema.parse(result),
            providesTags: (res, err, id) => [{ type: ApiTagType.StocktakeDetail, id }],
        }),
        stocktakeUpdate: build.mutation<void, Stocktake>({
            query: model => ({
                url: `/stocktake/${model.id}/update`,
                method: 'POST',
                data: {
                    notes: model.notes,
                },
            }),
            transformResponse: () => undefined,
            invalidatesTags: (res, err, model) => [
                ApiTagType.StocktakeList,
                { type: ApiTagType.StocktakeDetail, id: model.id },
            ],
        }),
        countSheetStatuses: build.query<CountSheetStatusesResult, string>({
            query: id => ({
                url: `/stocktake/${id}/countsheetstatuses`,
                method: 'GET',
            }),
            transformResponse: (result: unknown) => CountSheetStatusesResultSchema.parse(result),
            providesTags: (res, err, id) => [{ type: ApiTagType.StocktakeCountSheetStatuses, id }],
        }),
        countSheet: build.query<
            CountSheet,
            {
                stocktakeId: string;
                countSheetKey: string;
            }
        >({
            query: props => ({
                url: `/stocktake/${props.stocktakeId}/countsheet/${props.countSheetKey}`,
                method: 'GET',
            }),
            transformResponse: (result: unknown) => CountSheetSchema.parse(result),
            providesTags: res =>
                res?.id
                    ? [
                          {
                              type: ApiTagType.StocktakeCountSheetDetail,
                              id: res.id,
                          },
                      ]
                    : [],
        }),
        countSheetUpdate: build.mutation<
            void,
            {
                id: string; // count sheet id
                key: string;
                stocktakeId: string;
                tenantInventoryId: string;
                countedQuantity?: number;
            }
        >({
            query: args => ({
                url: `/stocktake/${args.stocktakeId}/countsheet/${args.key}`,
                method: 'POST',
                data: {
                    id: args.id,
                    key: args.key,
                    stocktakeId: args.stocktakeId,
                    inventory: [
                        {
                            tenantInventoryId: args.tenantInventoryId,
                            countedQuantity: args.countedQuantity ?? null,
                        },
                    ],
                },
            }),
            // TODO optimise invaldations to reduce overheads
            // Some of these only need to happen if stocktake status changes?
            invalidatesTags: (res, req, args) => {
                return [
                    ApiTagType.StocktakeList,
                    { type: ApiTagType.StocktakeDetail, id: args.stocktakeId },
                    { type: ApiTagType.StocktakeDiscrepancies, id: args.stocktakeId },
                    { type: ApiTagType.StocktakeCountSheetStatuses, id: args.stocktakeId },
                    { type: ApiTagType.StocktakeCountSheetDetail, id: args.id },
                ];
            },
        }),
        stocktakeDiscrepancies: build.query<StocktakeDiscrepancy[], string>({
            query: id => ({
                url: `/stocktake/${id}/discrepancies`,
                method: 'GET',
            }),
            transformResponse: (result: unknown) =>
                z.array(StocktakeDiscrepancySchema).parse(result),
            providesTags: (res, err, id) => [{ type: ApiTagType.StocktakeDiscrepancies, id }],
        }),
        refreshStock: build.mutation<void, string>({
            query: id => ({
                url: `/stocktake/${id}/refreshstock`,
                method: 'POST',
            }),
            invalidatesTags: (res, err, id) => [
                { type: ApiTagType.StocktakeDiscrepancies, id },
                { type: ApiTagType.StocktakeCountSheetStatuses, id },
            ],
        }),
        finaliseStocktake: build.mutation<void, string>({
            query: id => ({
                url: `/stocktake/${id}/finalise`,
                method: 'POST',
            }),
            invalidatesTags: (res, err, id) => [
                ApiTagType.StocktakeList,
                { type: ApiTagType.StocktakeDetail, id },
            ],
        }),
        stocktakeImport: build.mutation<
            { error?: StocktakeImportError },
            { file: File; id: string }
        >({
            async queryFn({ file, id }, _queryApi, _extraOptions, fetchWithBQ) {
                const formData = new FormData();
                formData.append('file', file, file.name);
                const response = await fetchWithBQ({
                    url: `/stocktake/${id}/import`,
                    method: 'POST',
                    data: formData,
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                if (response.error) {
                    if (response.error.response.data) {
                        const errData = StocktakeImportErrorSchema.parse(
                            response.error.response.data,
                        );

                        if (errData.code === StocktakeImportErrorType.StocktakeExcelError) {
                            // act as if this succeeded, but return a result that means show a dialog
                            // rather than letting apiError middleware for handling it
                            return {
                                data: {
                                    error: errData,
                                },
                            };
                        }
                    }

                    return {
                        error: response.error,
                    };
                }
                return {
                    data: {
                        error: undefined,
                    },
                };
            },
            invalidatesTags: (res, err, args) => [
                ApiTagType.StocktakeList,
                ApiTagType.StocktakeCountSheetDetail,
                { type: ApiTagType.StocktakeDetail, id: args.id },
                { type: ApiTagType.StocktakeDiscrepancies, id: args.id },
                { type: ApiTagType.StocktakeCountSheetStatuses, id: args.id },
            ],
        }),
    }),
});

export default stocktakeApi;
