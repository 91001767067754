import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Schedule } from './models/Schedule';
import { RootState } from 'store/store';

const initialState = {
    lastMoveToSchedule: undefined,
} as {
    lastMoveToSchedule?: Schedule;
};

const slice = createSlice({
    name: 'schedule',
    initialState,
    reducers: {
        setLastMoveToSchedule(state, action: PayloadAction<Schedule | undefined>) {
            state.lastMoveToSchedule = action.payload;
        },
    },
    // extraReducers: builder => {

    // },
});

export const { setLastMoveToSchedule } = slice.actions;

export default slice.reducer;

export const selectLastMoveToSchedule = (state: RootState) => state.schedule.lastMoveToSchedule;
