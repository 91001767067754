import { UserDefinedFieldSchema } from 'models/UserDefinedField';
import z from 'zod';
import { LocationSchema } from './Location';

export const LocationDetailResultSchema = z.object({
    data: LocationSchema,
    schemaExtensions: z.object({
        udfs: z.array(UserDefinedFieldSchema).optional(),
    }),
});

export type LocationDetailResult = z.infer<typeof LocationDetailResultSchema>;
