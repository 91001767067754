import React from 'react';
import './MySkeleton.scss';
import { Skeleton } from '@mui/material';
import coalesceClassNames from 'utils/coalesceClassNames';

export default function MySkeleton({
    className,
    variant = 'text',
    lines = 1,
    width,
    height,
    style,
}: {
    className?: string;
    variant?: 'text' | 'rectangular' | 'circular';
    lines?: number;
    width?: number;
    height?: number;
    style?: React.CSSProperties;
}) {
    // Render a mui skeleton for each line
    const elems = [];
    for (let i = 0; i < lines; i++) {
        elems.push(
            <Skeleton
                key={i}
                className="MySkeleton__Line"
                variant={variant}
                animation="wave"
                width={width}
                height={height}
                style={style}
            />,
        );
    }
    return <div className={coalesceClassNames('MySkeleton', className)}>{elems}</div>;
}
