import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import workstationsApi from 'features/workstations/workstations.api';
import { type RootState } from 'store/store';
import { StepTransitionParams } from './models/StepTransitionParams';
import { Workstation } from './models/Workstation';

const initialState = {
    currentWorkstation: undefined,
    pendingStepTransition: null,
} as {
    currentWorkstation?: Workstation;
    pendingStepTransition: null | StepTransitionParams;
};

const slice = createSlice({
    name: 'workstations',
    initialState,
    reducers: {
        setCurrentWorkstation(state, action: PayloadAction<Workstation | undefined>) {
            state.currentWorkstation = action.payload;
            if (action.payload) {
                localStorage.setItem(`currentWorkstationId`, action.payload.id);
            } else {
                localStorage.removeItem(`currentWorkstationId`);
            }
        },
    },
    extraReducers: builder => {
        builder.addMatcher(
            workstationsApi.endpoints.getWorkstations.matchFulfilled,
            (state, action) => {
                // load the last used station id from localstorage
                const currentWorkstationId = localStorage.getItem(`currentWorkstationId`);
                const workstation = action.payload.find(w => w.id === currentWorkstationId);
                if (workstation) {
                    state.currentWorkstation = workstation;
                }
            },
        );
        builder.addMatcher(
            workstationsApi.endpoints.workstationStepTransition.matchPending,
            (state, action) => {
                const params = action.meta.arg.originalArgs;
                state.pendingStepTransition = {
                    to: params.to,
                    reason: params.reason,
                };
            },
        );
        builder.addMatcher(
            workstationsApi.endpoints.workstationStepTransition.matchFulfilled,
            state => {
                state.pendingStepTransition = null;
            },
        );
        builder.addMatcher(
            workstationsApi.endpoints.workstationStepTransition.matchRejected,
            state => {
                state.pendingStepTransition = null;
            },
        );
    },
});

export const { setCurrentWorkstation } = slice.actions;

export default slice.reducer;

export const selectCurrentWorkstation = (state: RootState) => state.workstations.currentWorkstation;
export const selectPendingStepTransition = (state: RootState) =>
    state.workstations.pendingStepTransition;
