import { rest } from 'msw';

import Env from 'config/Env';
import { fakeSalesOrderAccountingConnections } from './faker/AccountingFaker';

const BASE_URL = `${Env.API_BASE_URL}`;

export const accountingHandlers = [
    // Get sales orders accounting entries
    rest.get(`${BASE_URL}/accounting/connections/salesorder/:salesOrderId`, (req, res, ctx) => {
        const result = fakeSalesOrderAccountingConnections;
        return res(ctx.delay(500), ctx.status(200), ctx.json(result));
    }),
];
