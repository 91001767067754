import z from 'zod';
import { SalesItemSchema } from './SalesItem';
import { SalesOrderSchema } from './SalesOrder';

export const SalesOrderDetailSchema = SalesOrderSchema.and(
    z.object({
        context: z.object({
            orderLines: z.array(SalesItemSchema),
            shippingMethod: z
                .object({
                    name: z.string(),
                })
                .optional(),
            shippingAddress: z
                .object({
                    fullAddress: z.string(),
                })
                .optional(),
        }),
    }),
);

export type SalesOrderDetail = z.infer<typeof SalesOrderDetailSchema>;
