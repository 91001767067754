import PackageCreateModal from 'features/dispatch/components/PackageCreateModal/PackageCreateModal';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { usePageTitle } from 'utils/locationHelpers';

export default function PackageCreatePage() {
    usePageTitle('New');
    const navigate = useNavigate();

    return <PackageCreateModal close={() => navigate(`/packages`)} />;
}
