import ShortUniqueId from 'short-unique-id';

/** returns true if a value is undefined or null */
export function isNone(val: unknown): val is undefined | null {
    return val === undefined || val === null;
}

/** returns true if param is null/undefined or an empty array/string */
export function isEmpty(val: unknown): val is undefined | null | '' | [] {
    return (
        val === undefined ||
        val === null ||
        (typeof val === 'string' && val.length === 0) ||
        (Array.isArray(val) && val.length === 0)
    );
}

export const wait = (time: number) => {
    return new Promise<void>(resolve => {
        setTimeout(() => {
            return resolve();
        }, time);
    });
};

const shortUid = new ShortUniqueId();
export const generateShortUuid = (): string => {
    return shortUid();
};

export const formatCurrency = (val?: number | null, trimEmptyCents = false) => {
    if (isEmpty(val)) {
        return val;
    }

    const numVal = val as number;
    const strVal = numVal.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
    });
    return trimEmptyCents ? strVal.replace('.00', '') : strVal;
};

export const tryParseFloat = (val?: string, defaultValue?: number) => {
    if (isEmpty(val)) {
        return defaultValue;
    }

    const result = parseFloat(val as string);
    return Number.isNaN(result) ? defaultValue : result;
};

export const tryParseJson = (val?: string) => {
    if (isEmpty(val)) {
        return null;
    }
    try {
        return JSON.parse(val);
    } catch {
        return null;
    }
};

/** Find the value of a property by it's path
 * this supports objects and arrays
 * eg deepPath = 'some.path[1].value'
 */
export function getDeepValue(obj: object, deepPath: string) {
    let result: any = obj;
    const tokens = deepPath.split('.');
    for (let i = 0; i < tokens.length; i++) {
        const path = tokens[i];
        if (path.includes('[')) {
            // array ref e.g. 'somepath[0]'
            const pathParts = path.split(/[[\]]/);
            const pathMain = pathParts[0];
            const pathArrayIndex = parseInt(pathParts[1], 10);
            result = result[pathMain][pathArrayIndex];
        } else {
            result = result[path];
        }
        if (isNone(result)) {
            break;
        }
    }
    return result;
}

/** Set the value of a property by it's path
 * this supports objects and arrays
 * eg deepPath = 'some.path[1].value'
 */
export function setDeepValue(obj: any, deepPath: string, val: any) {
    let result = obj;
    const tokens = deepPath.split('.');
    for (let i = 0; i < tokens.length - 1; i++) {
        const path = tokens[i];

        let nextResult;
        if (path.includes('[')) {
            // array ref e.g. 'somepath[0]'
            const pathParts = path.split(/[[\]]/);
            const pathMain = pathParts[0];
            const pathArrayIndex = parseInt(pathParts[1], 10);

            let arrayRef = result[pathMain];
            if (isNone(arrayRef)) {
                arrayRef = [];
                result[pathMain] = arrayRef;
            }
            nextResult = arrayRef[pathArrayIndex];
            if (isNone(nextResult)) {
                nextResult = {};
                arrayRef[pathArrayIndex] = nextResult;
            }
        } else {
            nextResult = result[path];
            if (isNone(nextResult)) {
                nextResult = {};
                result[path] = nextResult;
            }
        }
        result = nextResult;
    }

    // we're at the end
    // assign the value and return
    const finalPath = tokens[tokens.length - 1];
    result[finalPath] = val;
    return result[finalPath];
}

export function isObject(item: any) {
    return item && typeof item === 'object' && !Array.isArray(item);
}

/**
 * Deep merge two objects. See https://stackoverflow.com/questions/27936772/how-to-deep-merge-instead-of-shallow-merge
 */
export function mergeDeep(target: any, ...sources: any[]): any {
    if (!sources.length) return target;
    const source = sources.shift();

    if (isObject(target) && isObject(source)) {
        Object.keys(source).forEach(key => {
            if (isObject(source[key])) {
                if (!target[key]) {
                    Object.assign(target, { [key]: {} });
                }
                mergeDeep(target[key], source[key]);
            } else {
                Object.assign(target, { [key]: source[key] });
            }
        });
    }

    return mergeDeep(target, ...sources);
}

/** Parse an unknown string as an enum type
 * if the value is not found in the enum it returns undefined
 */
export function parseEnum<V>(enumType: Record<PropertyKey, V>, val: string): V | undefined {
    const enumVal = Object.values(enumType).find(n => String(n) === val);
    return enumVal;
}
