import { dateTimeInstant } from 'utils/dateSchemas';
import z from 'zod';
import WorkItemStepStatus from '../../workOrders/enums/WorkItemStepStatus';
import { WorkstationSchema } from './Workstation';

export const WorkOrderItemStepHistorySchema = z.object({
    id: z.string(),
    principalId: z.string(),
    state: z.nativeEnum(WorkItemStepStatus),
    timestamp: dateTimeInstant(),
    workflowStepId: z.string(),
    workstationId: z.string().nullable(),
    context: z.object({
        principal: z.object({
            deletedAt: dateTimeInstant().nullable(),
            firstName: z.string(),
            id: z.number(),
            lastName: z.string(),
            manufacturerId: z.number(),
            rememberToken: z.string(),
        }),
        workstation: WorkstationSchema.optional(),
    }),
});

export type WorkOrderItemStepHistory = z.infer<typeof WorkOrderItemStepHistorySchema>;
