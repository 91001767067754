import StepCanTransition from 'features/workOrders/enums/StepCanTransition';
import { WorkstationSchema } from 'features/workstations/models/Workstation';
import z from 'zod';
import { WorkOrderItemStepEntitySchema } from './WorkOrderItemStepEntity';
import { WorkOrderItemWithOptionsSchema } from './WorkOrderItemWithOptions';
import { WorkflowStepEntitySchema } from './WorkflowStepEntity';

export const WorkOrderItemStepSummarySchema = WorkOrderItemStepEntitySchema.extend({
    context: z.object({
        workOrderItem: WorkOrderItemWithOptionsSchema,
        workflowStep: WorkflowStepEntitySchema,
        workstationLatest: WorkstationSchema.optional(),
        workOrderItemStepsBlocking: z.array(
            z.object({
                workOrderItemStep: WorkOrderItemStepEntitySchema,
                workflowStep: WorkflowStepEntitySchema,
            }),
        ),
        canTransition: z.nativeEnum(StepCanTransition),
    }),
});

export type WorkOrderItemStepSummary = z.infer<typeof WorkOrderItemStepSummarySchema>;
