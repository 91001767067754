import { dateTimeLocal } from 'utils/dateSchemas';
import { mergeDeep } from 'utils/helpers';
import z from 'zod';
import ShipmentStatus from '../enums/ShipmentStatus';

export const ShipmentEntitySchema = z.object({
    id: z.string(),
    tuid: z.string(),
    date: dateTimeLocal({ allowBlank: true }),
    name: z.string(),
    notes: z.string(),
    shipmentDays: z.number(),
    status: z.nativeEnum(ShipmentStatus),
});

export type ShipmentEntity = z.infer<typeof ShipmentEntitySchema>;

export class ShipmentEntityFactory {
    static create(data?: Partial<ShipmentEntity>) {
        const defaults: ShipmentEntity = {
            id: '',
            tuid: '',
            name: '',
            date: '',
            notes: '',
            status: ShipmentStatus.Open,
            shipmentDays: 1,
        };

        const mergedData = mergeDeep(defaults, data);

        return ShipmentEntitySchema.parse(mergedData);
    }
}
