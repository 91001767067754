import createDisplayEnum, { EnumDisplayLabels } from 'utils/createDisplayEnum';

enum WorkflowStepType {
    Default = 'DEFAULT',
    Packing = 'PACKING',
}
export default WorkflowStepType;

const labels: EnumDisplayLabels<typeof WorkflowStepType> = {
    Default: 'Default',
    Packing: 'Packing',
};

export const WorkflowStepTypeDisplay = createDisplayEnum(WorkflowStepType, labels);
