import { FormControl, InputLabel, MenuItem } from '@mui/material';
import Select, { SelectChangeEvent, SelectProps } from '@mui/material/Select';
import React, { ReactNode } from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import FieldValidator from '../FieldValidator/FieldValidator';
import './MyMultiSelectInput.scss';

export type MyMultiSelectInputOption = {
    label: string;
    value: string;
};

export type MyMultiSelectInputProps = {
    options?: MyMultiSelectInputOption[];
    allowBlank?: boolean;
    value?: string[];
    handleInput?: (val: string[]) => void;
    validationKey?: string;
    validationRequired?: string | boolean;
    validationCustom?: string | false;
} & SelectProps;

export default function MyMultiSelectInput({
    value,
    handleInput,
    validationKey,
    validationRequired,
    validationCustom,
    options = [],
    allowBlank = false,
    size = 'small',
    ...props
}: MyMultiSelectInputProps) {
    const onChange = (e: SelectChangeEvent<unknown>, child: ReactNode) => {
        const val = e.target.value as string[];
        if (handleInput) {
            handleInput(val);
        } else if (props.onChange) {
            props.onChange(e, child);
        }
    };

    return (
        <FieldValidator
            validationKey={validationKey}
            validationRequired={validationRequired}
            validationCustom={validationCustom}
            value={value}
        >
            {({ revealError, error }) => (
                <FormControl
                    className={coalesceClassNames(
                        'MyMultiSelectInput',
                        props.className,
                        error && 'MyMultiSelectInput--error',
                        props.label
                            ? 'MyMultiSelectInput--with-label'
                            : 'MyMultiSelectInput--no-label',
                    )}
                    size={size}
                >
                    <InputLabel id="demo-simple-select-label">{props.label}</InputLabel>
                    <Select
                        {...props}
                        native={false}
                        value={value}
                        size={size}
                        className={coalesceClassNames(
                            'MyMultiSelectInput__Select',
                            props.className && `${props.className}__Select`,
                        )}
                        onChange={onChange}
                        onBlur={revealError}
                        onClose={revealError}
                        MenuProps={{
                            className: 'MyMultiSelectInput__Menu',
                        }}
                    >
                        {allowBlank && (
                            <MenuItem
                                key={''}
                                value={''}
                            ></MenuItem>
                        )}
                        {/* Options can either be a list of strings or objects with { label, value } */}
                        {options.map((option, index) => {
                            const label = typeof option === 'object' ? option.label : option;
                            const val = typeof option === 'object' ? option.value : option;
                            return (
                                <MenuItem
                                    key={index}
                                    value={val}
                                >
                                    {label}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            )}
        </FieldValidator>
    );
}
