import { InputLabel, OutlinedInput } from '@mui/material';
import Switch from '@mui/material/Switch';
import React, { useEffect, useState } from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import {
    CriteriaCompProps,
    DataTableCriteriaFieldBase,
    DataTableCriteriaPlugin,
} from '../DataTableCriteriaTypes';
import './ToggleCriteriaField.scss';

export type ToggleCriteriaFieldConfig = DataTableCriteriaFieldBase & {
    type: 'toggle';
    trueValue?: string;
    falseValue?: string;
};

function ToggleCriteriaField({ field, onChange }: CriteriaCompProps<ToggleCriteriaFieldConfig>) {
    const trueValue = field.config.trueValue ?? 'true';
    const falseValue = field.config.falseValue ?? 'false';
    const [value, setValue] = useState(field.config.defaultValue ?? falseValue);

    const handleCheckedChanged = (checked: boolean) => {
        const val = checked ? trueValue : falseValue;
        setValue(val);
        field.value = val;
        onChange?.(field);
    };

    useEffect(() => {
        setValue(field.value);
    }, [field.value]);

    return (
        <div className={coalesceClassNames('DataTableCriteria__Field', 'ToggleCriteriaField')}>
            <InputLabel
                className="ToggleCriteriaField__Label"
                variant="outlined"
                shrink={true}
            >
                {field.config.label}
            </InputLabel>
            <OutlinedInput
                notched={true}
                label={field.config.label}
                className="ToggleCriteriaField__OutlinedInput"
                type="hidden"
            />
            <div className="ToggleCriteriaField__SwitchWrapper">
                <Switch
                    className="ToggleCriteriaField__Switch"
                    checked={value === trueValue}
                    onChange={e => handleCheckedChanged(e.target.checked)}
                />
            </div>
        </div>
    );
}

const plugin: DataTableCriteriaPlugin<ToggleCriteriaFieldConfig> = {
    Component: ToggleCriteriaField,
};
export default plugin;
