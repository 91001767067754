import z from 'zod';
import { WorkflowItemSplitConfigSchema } from './WorkflowItemSplitConfig';

export const WorkflowSchema =
    // include any keys we dont know about
    // this record is managed by the php backend and when we save it we dont want to leave anything out
    z
        .object({
            id: z.string(),
            name: z.string(),
            itemSplitMappingConfig: z.array(WorkflowItemSplitConfigSchema).nullable(),
        })
        .catchall(z.unknown());

export type Workflow = z.infer<typeof WorkflowSchema>;
