import StocktakeDetailModal from 'features/stocktake/components/StocktakeDetailModal/StocktakeDetailModal';
import stocktakeApi from 'features/stocktake/stocktake.api';
import React from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { usePageTitle } from 'utils/locationHelpers';

export default function StocktakeDetailPage() {
    const { stocktakeId } = useParams() as { stocktakeId: string };
    const navigate = useNavigate();

    const query = stocktakeApi.useStocktakeDetailQuery(stocktakeId);
    usePageTitle(query.data?.data.number);
    const countSheetQuery = stocktakeApi.useCountSheetStatusesQuery(stocktakeId);

    return (
        <>
            <StocktakeDetailModal
                model={query.data?.data}
                udfs={query.data?.schemaExtensions.udfs}
                countSheetSummaries={countSheetQuery.data?.data}
                isLoading={query.isLoading}
                isError={query.isError}
                close={() => navigate(`/stocktake`)}
            />

            {query.data?.data && (
                <Outlet
                    context={{
                        stocktake: query.data.data,
                    }}
                />
            )}
        </>
    );
}
