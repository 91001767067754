import MySelectInput, { MySelectInputOption } from 'components/MySelectInput/MySelectInput';
import PropertyDisplay from 'components/PropertyDisplay/PropertyDisplay';
import PropertyEdit from 'components/PropertyEdit/PropertyEdit';
import React, { useRef } from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import { generateShortUuid } from 'utils/helpers';
import './PropertyEditSelect.scss';

export type PropertyEditSelectProps = {
    className?: string;
    value?: string;
    label: string;
    hint?: string;
    onChange?: (val: string, label?: string) => void;
    readonly?: boolean;
    disabled?: boolean;
    options?: MySelectInputOption[];
    allowBlank?: boolean;
    autoFocus?: boolean;
    validationRequired?: string | boolean;
    validationCustom?: string | false;
    fullWidth?: boolean;
};

export default function PropertyEditSelect({
    className,
    value,
    label,
    hint,
    onChange,
    readonly = false,
    disabled = false,
    options,
    allowBlank = false,
    autoFocus,
    validationRequired,
    validationCustom,
    fullWidth,
}: PropertyEditSelectProps) {
    const handleInput = (val: any, lbl?: string) => {
        onChange?.(val, lbl);
    };

    const inputId = useRef(generateShortUuid()).current;

    const validationRequiredWithLabel =
        validationRequired === true ? `${label} is required` : validationRequired;

    if (readonly) {
        return (
            <PropertyDisplay
                className={className}
                label={label}
                hint={hint}
                value={value}
                options={options}
            />
        );
    }
    return (
        <PropertyEdit
            className={coalesceClassNames(
                'PropertyEditSelect',
                fullWidth && 'PropertyEditSelect--full-width',
                className,
            )}
            label={label}
            hint={hint}
            inputId={inputId}
        >
            <MySelectInput
                className="PropertyEditSelect__MySelectInput"
                value={value ?? undefined}
                options={options}
                disabled={disabled}
                allowBlank={allowBlank}
                handleInput={handleInput}
                autoFocus={autoFocus}
                validationRequired={validationRequiredWithLabel}
                validationCustom={validationCustom}
                fullWidth={fullWidth}
            />
        </PropertyEdit>
    );
}
