import MyButton from 'components/MyButton/MyButton';
import React, { useCallback, useMemo } from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import './DataTableCriteria.scss';

import Icons from 'Icons';
import { DynamicQueryParams } from 'models/DynamicQueryParams';
import { useSearchParams } from 'react-router-dom';
import { StrictUnion } from 'utils/typeHelpers';
import { DataTableCriteriaField, DataTableCriteriaPlugin } from './DataTableCriteriaTypes';
import AutocompleteCritieraField, {
    AutocompleteCriteriaFieldConfig,
} from './Fields/AutocompleteCriteriaField';
import DateCritieraField, { DateCriteriaFieldConfig } from './Fields/DateCriteriaField';
import SearchCritieraField, { SearchCriteriaFieldConfig } from './Fields/SearchCriteriaField';
import SelectCritieraField, { SelectCriteriaFieldConfig } from './Fields/SelectCriteriaField';
import TextCriteriaField, { TextCriteriaFieldConfig } from './Fields/TextCriteriaField';
import ToggleCriteriaField, { ToggleCriteriaFieldConfig } from './Fields/ToggleCriteriaField';

const CriteriaPluginMap: { [key: string]: DataTableCriteriaPlugin<any> } = {
    text: TextCriteriaField,
    search: SearchCritieraField,
    autocomplete: AutocompleteCritieraField,
    select: SelectCritieraField,
    date: DateCritieraField,
    toggle: ToggleCriteriaField,
};

export type DataTableCriteriaFieldConfig = StrictUnion<
    | TextCriteriaFieldConfig
    | SearchCriteriaFieldConfig
    | SelectCriteriaFieldConfig
    | AutocompleteCriteriaFieldConfig
    | DateCriteriaFieldConfig
    | ToggleCriteriaFieldConfig
>;

export function CriteriaBuilder<T extends DynamicQueryParams>() {
    const _criteria: DataTableCriteriaFieldConfig[] = [];

    type MyType = DataTableCriteriaFieldConfig & { field: keyof T['criteria'] };
    return {
        criteria(c: false | MyType) {
            if (c) {
                _criteria.push(c);
            }
            return this;
        },
        build() {
            return _criteria;
        },
    };
}

export default function DataTableCriteria({
    className,
    fields: fieldDefs,
    onChange,
    onRefresh,
    isRefreshing,
    children,
}: {
    className?: string;
    fields: (DataTableCriteriaFieldConfig | false)[];
    onChange?: (params: any) => void;
    onRefresh?: () => void;
    isRefreshing?: boolean;
    children?: React.ReactNode;
}) {
    const [urlParams, setUrlParams] = useSearchParams({});
    const updateUrlParam = useCallback(
        (field: DataTableCriteriaFieldConfig, val?: string) => {
            if (field.urlParam) {
                if (!val || val === field.defaultValue) {
                    urlParams.delete(field.urlParam);
                } else {
                    urlParams.set(field.urlParam, val);
                }
            }
        },
        [urlParams],
    );

    const fields = useMemo(() => {
        return (fieldDefs.filter(Boolean) as DataTableCriteriaFieldConfig[]).map(f => {
            const urlParamValue = f.urlParam ? urlParams.get(f.urlParam) : null;
            const result: DataTableCriteriaField<typeof f> = {
                config: { ...f },
                isVisible: !!f.isSticky,
                value: f.defaultValue || '',
            };

            // create a copy of options array for select filters
            if (result.config.type === 'select' || result.config.type === 'autocomplete') {
                result.config.options = result.config.options ? [...result.config.options] : [];
                // apply urlParamValue only if a valid option exists
                // this avoids console errors about out-of-range values
                if (urlParamValue && result.config.options.some(o => o.value === urlParamValue)) {
                    result.value = urlParamValue;
                }
            } else {
                // apply urlParamValue
                result.value = urlParamValue || result.value;
            }

            return result;
        });
    }, [fieldDefs, urlParams]);

    const applyFilters = useCallback(() => {
        const params = {} as any;
        fields.forEach(f => {
            params[f.config.field] = f.value;
        });

        onChange?.(params);
    }, [fields, onChange]);

    const handleParamChanged = useCallback(
        (field: DataTableCriteriaField<DataTableCriteriaFieldConfig>) => {
            // update url params
            if (field.config.urlParam) {
                updateUrlParam(field.config, field.value);
                setUrlParams(urlParams, { replace: true });
            }

            applyFilters();
        },
        [applyFilters, setUrlParams, updateUrlParam, urlParams],
    );

    const canResetFilters = fields.some(f => f.value !== f.config.defaultValue);

    const resetFilters = useCallback(() => {
        fields.forEach(f => {
            f.value = f.config.defaultValue || '';
            updateUrlParam(f.config, f.value);
        });
        setUrlParams(urlParams, { replace: true });
        applyFilters();
    }, [fields, setUrlParams, urlParams, applyFilters, updateUrlParam]);

    return (
        <div className={coalesceClassNames('DataTableCriteria', className)}>
            {fields.map((f, i) => {
                const ParamComp = CriteriaPluginMap[f.config.type];

                return ParamComp ? (
                    <ParamComp.Component
                        key={i}
                        field={f}
                        onChange={handleParamChanged}
                    />
                ) : null;
            })}

            {children}

            {canResetFilters && (
                <MyButton
                    className="DataTableCriteria__ResetButton"
                    label="Reset filters"
                    buttonType="LinkButton"
                    onClick={resetFilters}
                />
            )}

            {onRefresh && (
                <MyButton
                    className={coalesceClassNames(
                        'DataTableCriteria__RefreshButton',
                        isRefreshing && 'refreshing',
                    )}
                    IconRight={Icons.Refresh}
                    buttonType="LinkButton"
                    onClick={onRefresh}
                    title="Refresh"
                />
            )}
        </div>
    );
}
