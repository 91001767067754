import React from 'react';
import { formatDateTimeRelative } from 'utils/dateHelpers';
import './HistoryEntryDisplay.scss';

export type ChangedField = {
    label: string;
    displayValue: React.ReactNode;
};

export default function HistoryEntryDisplay({
    changedFields,
    log,
    updatedBy,
    timestamp,
}: {
    log: string | null;
    changedFields?: ChangedField[];
    updatedBy: string;
    timestamp: string;
}) {
    return (
        <div className="HistoryEntryDisplay">
            <div className="HistoryEntryDisplay__Title">
                <span className="HistoryEntryDisplay__UpdatedBy">
                    {!log && 'Updated by '} <strong>{updatedBy}</strong>
                </span>
                <span className="HistoryEntryDisplay__Timestamp">
                    {formatDateTimeRelative(timestamp)}
                </span>
            </div>
            <div className="HistoryEntryDisplay__Changes">
                {log ??
                    changedFields?.map(field => (
                        <FieldDetail
                            key={field.label}
                            entry={field}
                        />
                    ))}
            </div>
        </div>
    );
}

function FieldDetail({ entry: { label, displayValue } }: { entry: ChangedField }) {
    return (
        <div className="HistoryEntryDisplay__FieldDetail">
            <span className="HistoryEntryDisplay__FieldDetail__Field">{label}</span>

            <div className="HistoryEntryDisplay__FieldDetail__Content">{displayValue}</div>
        </div>
    );
}
