import ErrorContent from 'components/ErrorContent/ErrorContent';
import MyLinearProgress from 'components/MyLinearProgress/MyLinearProgress';
import workstationsApi from 'features/workstations/workstations.api';
import React from 'react';
import { Outlet } from 'react-router-dom';

export default function WorkstationsPage() {
    // Because of the way the workstations redirects work, we don't call usePageTitle here as it may lead to the slugs being rendered in an inconsistent order
    const workstationsQuery = workstationsApi.useGetWorkstationsQuery();

    // Load workstations first
    if (workstationsQuery.isLoading) {
        return <MyLinearProgress />;
    }

    if (workstationsQuery.isError) {
        return <ErrorContent />;
    }

    return <Outlet />;
}
