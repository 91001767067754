import React, { useMemo } from 'react';
import SanitizeHTML from 'sanitize-html';

export function SafeHTML({ className = '', html }: { className?: string; html?: string }) {
    const escaped = useMemo(
        () =>
            !html
                ? ''
                : SanitizeHTML(html, {
                      allowedTags: [
                          'blockquote',
                          'p',
                          'a',
                          'ul',
                          'ol',
                          'li',
                          'b',
                          'i',
                          'strong',
                          'em',
                          'strike',
                          'del',
                          'br',
                          'div',
                          'sup',
                          'sub',
                      ],
                      allowedAttributes: {
                          a: ['href', 'name', 'target'],
                          img: ['src'],
                      },
                      // Lots of these won't come up by default because we don't allow them
                      selfClosing: [
                          'img',
                          'br',
                          'hr',
                          'area',
                          'base',
                          'basefont',
                          'input',
                          'link',
                          'meta',
                      ],
                      // URL schemes we permit
                      allowedSchemes: ['http', 'https', 'ftp', 'mailto'],
                      allowedSchemesByTag: {},
                  }),
        [html],
    );

    return (
        <div
            className={className}
            dangerouslySetInnerHTML={{
                __html: escaped,
            }}
        />
    );
}
