import { StepTransitionParams } from 'features/workstations/models/StepTransitionParams';
import { WorkstationStepDetail } from 'features/workstations/models/WorkstationStepDetail';
import React from 'react';
import './WorkstationStepPacking.scss';

/** Appears in the details modal and displays the main status bar and any canTransition warnings */
export default function WorkstationStepPacking({
    model,
    doStepTransition,
}: {
    model: WorkstationStepDetail;
    doStepTransition?: (params: StepTransitionParams) => Promise<any>;
}) {
    if (!model && !doStepTransition) {
        return null;
    }
    return (
        <div className="WorkstationStepPacking">
            <h2 className="WorkStationStepPacking__Heading">Packages containing related items</h2>
            <ul>
                <li>TODO</li>
                <li>TODO</li>
                <li>TODO</li>
            </ul>

            <h2 className="WorkStationStepPacking__Heading">Related items not packed yet</h2>
            <ul>
                <li>TODO</li>
                <li>TODO</li>
                <li>TODO</li>
            </ul>
        </div>
    );
}
