import z from 'zod';
import SalesOrderCoreStatus from '../enums/SalesOrderCoreStatus';

export const SalesOrderStatusOldSchema = z
    .object({
        id: z.number(),
        name: z.string(),
        background_color: z.string(),
        text_color: z.string(),
        is_active: z.number().transform(val => Boolean(val)),
        sort_order: z.number(),
        core_status: z.nativeEnum(SalesOrderCoreStatus),
    })
    .transform<SalesOrderStatus>(statusOld => ({
        id: statusOld.id,
        name: statusOld.name,
        backgroundColor: statusOld.background_color,
        textColor: statusOld.text_color,
        isActive: statusOld.is_active,
        sortOrder: statusOld.sort_order,
        coreStatus: statusOld.core_status,
    }));

export const SalesOrderStatusSchema = z.object({
    id: z.number(),
    name: z.string(),
    backgroundColor: z.string(),
    textColor: z.string(),
    isActive: z.boolean(),
    sortOrder: z.number(),
    coreStatus: z.nativeEnum(SalesOrderCoreStatus),
});

export type SalesOrderStatus = z.infer<typeof SalesOrderStatusSchema>;
