import { ShipmentWorkOrderItem } from 'features/dispatch/models/ShipmentWorkOrderItem';
import WorkItemStatus from 'features/workOrders/enums/WorkItemStatus';
import React, { useMemo } from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import './ShipmentWorkItemsProgress.scss';

export default function ShipmentWorkItemsProgress({ items }: { items: ShipmentWorkOrderItem[] }) {
    const completedItems = useMemo(
        () => items.filter(i => i.context.workOrderItemState === WorkItemStatus.Completed),
        [items],
    );

    const percent = useMemo(
        () => (items.length === 0 ? 0 : (completedItems.length / items.length) * 100),
        [items, completedItems],
    );
    return (
        <div className="ShipmentWorkItemsProgress">
            {items.length === 0 ? (
                <div className="ShipmentWorkItemsProgress__Empty">&ndash;</div>
            ) : (
                <>
                    <div className="ShipmentWorkItemsProgress__Bar">
                        <div
                            className={coalesceClassNames(
                                'progress',
                                percent === 100 && 'complete',
                            )}
                            style={{ width: `${percent}%` }}
                        />
                    </div>
                    <div className="ShipmentWorkItemsProgress__Counts">
                        {completedItems.length} / {items.length}
                    </div>
                </>
            )}
        </div>
    );
}
