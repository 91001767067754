import React from 'react';
import { Link } from 'react-router-dom';

export default function LinkOrDiv({
    className,
    to,
    target,
    onClick,
    children,
}: {
    className?: string;
    to?: string;
    target?: string;
    onClick?: () => void;
    children?: React.ReactFragment;
}) {
    const isAbsLink = to?.startsWith('http');

    if (to && !isAbsLink) {
        return (
            <Link
                className={className}
                to={to}
                target={target}
                onClick={onClick}
            >
                {children}
            </Link>
        );
    }

    if (isAbsLink || onClick) {
        return (
            <a
                className={className}
                onClick={onClick}
                target={target}
                href={onClick ? undefined : to}
            >
                {children}
            </a>
        );
    }

    return <div className={className}>{children}</div>;
}
