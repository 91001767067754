import MySelectInput from 'components/MySelectInput/MySelectInput';
import React from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import { getDeepValue, isEmpty } from 'utils/helpers';
import {
    DataTableFilterBase,
    DataTableFilterPlugin,
    DataTableFilterSelectOption,
    FilterCompProps,
} from '../DataTableFilterTypes';

export type SelectFilterConfig = DataTableFilterBase & {
    type: 'select';
    field: string;
    options?: DataTableFilterSelectOption[];
    allowBlank?: boolean;
};

function SelectFilter({ filter, onChange }: FilterCompProps<SelectFilterConfig>) {
    const handleInput = (val: string) => {
        filter.value = val;
        onChange?.(filter);
    };

    const field = filter.config.type === 'select' && filter.config.field;

    return (
        <div
            className={coalesceClassNames(
                'DataTableFilters__Field',
                'DataTableFilters__Field--SelectFilter',
                field && `DataTableFilters__Field--SelectFilter--${field}`,
            )}
        >
            <MySelectInput
                label={filter.config.label}
                value={filter.value}
                options={filter.config.options}
                handleInput={handleInput}
                allowBlank={filter.config.allowBlank ?? true}
            />
        </div>
    );
}

const plugin: DataTableFilterPlugin<SelectFilterConfig> = {
    Component: SelectFilter,
    applyFilter: (filter, value, item) => {
        let val = getDeepValue(item, filter.field);
        if (isEmpty(val)) {
            val = '';
        }
        return String(val) === value;
    },
};
export default plugin;
