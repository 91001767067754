import FieldValidator from 'components/FieldValidator/FieldValidator';
import MySelectInput from 'components/MySelectInput/MySelectInput';
import MyTextInput from 'components/MyTextInput/MyTextInput';
import PropertyDisplay from 'components/PropertyDisplay/PropertyDisplay';
import PropertyEdit from 'components/PropertyEdit/PropertyEdit';
import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { formatTime } from 'utils/dateHelpers';
import { generateShortUuid } from 'utils/helpers';
import './PropertyEditDateTime.scss';

export type PropertyEditDateTimeProps = {
    value?: string;
    label: string;
    hint?: string;
    onChange?: (val?: string) => void;
    readonly?: boolean;
    disabled?: boolean;
    defaultTime?: string;
    validationRequired?: string | boolean;
    validationCustom?: string | false;
};

const timeOptions = [] as { label: string; value: string }[];
for (let i = 0; i < 24; i++) {
    const dt = DateTime.fromFormat(`${i}`, 'H');
    timeOptions.push({
        label: formatTime(dt),
        value: dt.toFormat('HH:mm'),
    });
}

export default function PropertyEditDateTime({
    value,
    label,
    hint,
    onChange,
    readonly = false,
    disabled = false,
    defaultTime = '00:00',
    validationRequired,
    validationCustom,
}: PropertyEditDateTimeProps) {
    const inputId = useRef(generateShortUuid()).current;

    const [date, setDate] = useState('');
    const [time, setTime] = useState(defaultTime);

    useEffect(() => {
        if (value) {
            const dt = DateTime.fromISO(value);
            if (dt.isValid) {
                setDate(dt.toFormat('yyyy-MM-dd'));
                const t = dt.toFormat('HH:mm');
                if (timeOptions.some(opt => opt.value === t)) {
                    setTime(t);
                } else {
                    setTime(defaultTime);
                }
            }
        }
    }, [defaultTime, value]);

    const updateValue = useCallback(
        (dateStr, timeStr) => {
            setDate(dateStr);
            setTime(timeStr);

            const val = DateTime.fromISO(`${dateStr}T${timeStr}`);
            if (val.isValid) {
                onChange?.(val.toISO() as string);
            } else {
                onChange?.('');
            }
        },
        [onChange],
    );

    const validationRequiredWithLabel =
        validationRequired === true ? `${label} is required` : validationRequired;

    if (readonly) {
        return (
            <PropertyDisplay
                label={label}
                hint={hint}
                value={value}
            />
        );
    }
    return (
        <PropertyEdit
            className="PropertyEditDateTime"
            label={label}
            hint={hint}
            inputId={inputId}
        >
            <FieldValidator
                value={value}
                validationRequired={validationRequiredWithLabel}
                validationCustom={validationCustom}
            >
                {({ revealError }) => (
                    <div className="PropertyEditDateTime__InputWrapper">
                        <MyTextInput
                            className="PropertyEditDateTime__Input"
                            id={inputId}
                            value={date}
                            type="date"
                            disabled={disabled}
                            handleInput={val => updateValue(val, time)}
                            onBlur={revealError}
                        />
                        <MySelectInput
                            className="PropertyEditDateTime__Input"
                            id={inputId}
                            value={time}
                            options={timeOptions}
                            allowBlank={false}
                            disabled={disabled}
                            handleInput={val => updateValue(date, val)}
                            onBlur={revealError}
                        />
                    </div>
                )}
            </FieldValidator>
        </PropertyEdit>
    );
}
