import z from 'zod';
import WorkItemStepStatus from '../../workOrders/enums/WorkItemStepStatus';
import WorkItemStepTransitionReason from '../../workOrders/enums/WorkItemStepTransitionReason';

export const WorkOrderItemAdditionalActionSchema = z.object({
    label: z.string(),
    reason: z.nativeEnum(WorkItemStepTransitionReason).optional(),
    to: z.nativeEnum(WorkItemStepStatus),
    type: z.string(), // for now always 'TRANSITION' - can be ignored,
    validFrom: z.array(z.nativeEnum(WorkItemStepStatus)),
});

export type WorkOrderItemAdditionalAction = z.infer<typeof WorkOrderItemAdditionalActionSchema>;
