import createDisplayEnum, { EnumDisplayLabels } from 'utils/createDisplayEnum';

enum WorkItemStepTransitionReason {
    OOS = 'OOS',
    Pause = 'PAUSE',
    OnOrder = 'ON_ORDER',
}
export default WorkItemStepTransitionReason;

const labels: EnumDisplayLabels<typeof WorkItemStepTransitionReason> = {
    OOS: 'Out of stock',
    Pause: 'Paused',
    OnOrder: 'On order',
};

export const WorkItemStepTransitionReasonDisplay = createDisplayEnum(
    WorkItemStepTransitionReason,
    labels,
);
