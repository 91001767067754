import StocktakeReviewModal from 'features/stocktake/components/StocktakeReviewModal/StocktakeReviewModal';
import { Stocktake } from 'features/stocktake/models/Stocktake';
import stocktakeApi from 'features/stocktake/stocktake.api';
import React from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { usePageTitle } from 'utils/locationHelpers';

export default function StocktakeReviewPage() {
    const navigate = useNavigate();
    const { stocktake } = useOutletContext() as {
        stocktake: Stocktake;
    };
    usePageTitle(stocktake.number);

    const query = stocktakeApi.useStocktakeDiscrepanciesQuery(stocktake.id);
    const countSheetQuery = stocktakeApi.useCountSheetStatusesQuery(stocktake.id);
    return (
        <>
            <StocktakeReviewModal
                data={query.data || []}
                stocktake={stocktake}
                countSheetSummaries={countSheetQuery.data?.data}
                isLoading={query.isLoading || countSheetQuery.isLoading}
                isError={query.isError}
                close={() => navigate(`..`)}
            />
        </>
    );
}
