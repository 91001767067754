import MyTextInput from 'components/MyTextInput/MyTextInput';
import PropertyDisplay from 'components/PropertyDisplay/PropertyDisplay';
import PropertyEdit from 'components/PropertyEdit/PropertyEdit';
import React, { useRef } from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import { generateShortUuid } from 'utils/helpers';
import './PropertyEditDate.scss';

export type PropertyEditDateProps = {
    className?: string;
    value?: string;
    label: string;
    hint?: string;
    onChange?: (val?: string) => void;
    readonly?: boolean;
    disabled?: boolean;
    validationRequired?: string | boolean;
    validationCustom?: string | false;
    max?: string;
    min?: string;
};

export default function PropertyEditDate({
    className,
    value,
    label,
    hint,
    min,
    max,
    onChange,
    readonly = false,
    disabled = false,
    validationRequired,
    validationCustom,
}: PropertyEditDateProps) {
    const inputId = useRef(generateShortUuid()).current;

    const validationRequiredWithLabel =
        validationRequired === true ? `${label} is required` : validationRequired;

    if (readonly) {
        return (
            <PropertyDisplay
                className={className}
                label={label}
                hint={hint}
                value={value}
            />
        );
    }
    return (
        <PropertyEdit
            className={coalesceClassNames('PropertyEditDate', className)}
            label={label}
            hint={hint}
            inputId={inputId}
        >
            <MyTextInput
                className="PropertyEditDate__MyTextInput"
                id={inputId}
                value={value}
                type="date"
                disabled={disabled}
                handleInput={onChange}
                validationRequired={validationRequiredWithLabel}
                validationCustom={validationCustom}
                min={min}
                max={max}
            />
        </PropertyEdit>
    );
}
