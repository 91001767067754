import SalesOrderDetailModal from 'features/sales/components/SalesOrderDetailModal/SalesOrderDetailModal';
import React from 'react';
import { Outlet, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { usePageTitle } from 'utils/locationHelpers';
import salesApi from '../../features/sales/sales.api';

export default function SalesDetailPage() {
    const { orderId = '' } = useParams();

    // retain url query params when navigating
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const query = salesApi.useSalesOrderDetailQuery(orderId);

    usePageTitle(query.data?.manufacturerReference);

    return orderId ? (
        <>
            <SalesOrderDetailModal
                model={query.data}
                isLoading={query.isLoading}
                isError={query.isError}
                close={() => navigate(`/sales?${searchParams}`)}
            />
            <Outlet />
        </>
    ) : null;
}
