import Dialog from 'components/Dialogs/Dialog';
import ErrorContent from 'components/ErrorContent/ErrorContent';
import MyButton from 'components/MyButton/MyButton';
import MyLinearProgress from 'components/MyLinearProgress/MyLinearProgress';
import PageHeader from 'components/PageHeader/PageHeader';
import PropertyDisplay from 'components/PropertyDisplay/PropertyDisplay';
import PropertyEditNumber from 'components/PropertyEditNumber/PropertyEditNumber';
import PropertyEditSelect from 'components/PropertyEditSelect/PropertyEditSelect';
import PropertyEditText from 'components/PropertyEditText/PropertyEditText';
import InventoryMovementBatchType, {
    InventoryMovementBatchTypeDisplay,
} from 'features/inventory/enums/InventoryMovementBatchType';
import inventoryApi from 'features/inventory/inventory.api';
import FormValidation from 'providers/FormValidation';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { isEmpty } from 'utils/helpers';
import LocationSelectInput from '../LocationSelectInput/LocationSelectInput';
import './InventoryMovementCreateDialog.scss';

export default function InventoryMovementCreateDialog({
    inventoryId,
    defaultLocationId,
    close,
}: {
    inventoryId: string;
    defaultLocationId?: string;
    close?: () => void;
}) {
    const [movementType, setMovementType] = useState(InventoryMovementBatchType.Adjustment);
    const [locationId, setLocationId] = useState(defaultLocationId || '');
    const [notes, setNotes] = useState('');

    const query = inventoryApi.useInventoryDetailQuery(inventoryId);
    const model = query.data?.data;

    // Not all movement types are allowed
    // exclude 'StockIn' and 'StockOut'
    const movementTypeOptions = [
        InventoryMovementBatchType.Adjustment,
        InventoryMovementBatchType.Transfer,
        InventoryMovementBatchType.WriteOff,
    ].map(val => ({
        label: InventoryMovementBatchTypeDisplay.display(val) as string,
        value: val as string,
    }));

    return (
        <Dialog
            className="AddMovementDialog"
            close={close}
        >
            {query.isLoading ? (
                <MyLinearProgress />
            ) : query.isError || !model ? (
                <ErrorContent />
            ) : (
                <>
                    <PageHeader
                        title="New Movement"
                        titleContext={model.partNumber}
                        subtitle={model.description}
                    />

                    <div className="AddMovementDialog__Fields">
                        <PropertyEditSelect
                            label="Movement type"
                            value={movementType}
                            options={movementTypeOptions}
                            onChange={val => setMovementType(val as InventoryMovementBatchType)}
                            autoFocus={true}
                        />

                        {movementType === InventoryMovementBatchType.Adjustment ? (
                            <AdjustmentForm
                                inventoryId={inventoryId}
                                locationId={locationId}
                                setLocationId={setLocationId}
                                notes={notes}
                                setNotes={setNotes}
                                close={close}
                            />
                        ) : movementType === InventoryMovementBatchType.Transfer ? (
                            <TransferForm
                                inventoryId={inventoryId}
                                locationId={locationId}
                                setLocationId={setLocationId}
                                notes={notes}
                                setNotes={setNotes}
                                close={close}
                            />
                        ) : movementType === InventoryMovementBatchType.WriteOff ? (
                            <WriteOffForm
                                inventoryId={inventoryId}
                                locationId={locationId}
                                setLocationId={setLocationId}
                                notes={notes}
                                setNotes={setNotes}
                                close={close}
                            />
                        ) : null}
                    </div>
                </>
            )}
        </Dialog>
    );
}

function AdjustmentForm({
    inventoryId,
    locationId = '',
    setLocationId,
    notes = '',
    setNotes,
    close,
}: {
    inventoryId: string;
    locationId?: string;
    setLocationId?: (id: string) => void;
    notes?: string;
    setNotes?: (id: string) => void;
    close?: () => void;
}) {
    const [quantity, setQuantity] = useState<number>(1);

    const locationsQuery = inventoryApi.useLocationListQuery();
    const stockQueryHash = useStockQueryHash(inventoryId);

    /** find locations with canStore == true */
    const locationsWithCanStore = useMemo(
        () => locationsQuery.data?.data.filter(loc => loc.canStore),
        [locationsQuery.data?.data],
    );

    /** number on hand in the selected location */
    const locationQuantity = useMemo(
        () => stockQueryHash[locationId] ?? 0,
        [locationId, stockQueryHash],
    );

    /** The difference between old a new quantity */
    const difference = useMemo(() => quantity - locationQuantity, [locationQuantity, quantity]);

    useEffect(() => {
        setQuantity(locationQuantity);
    }, [locationQuantity]);

    const [createMutation, createMutationStatus] =
        inventoryApi.useInventoryMovementCreateMutation();
    const submit = useCallback(async () => {
        if ((difference ?? 0) !== 0) {
            await createMutation({
                inventoryMovements: [
                    {
                        locationId,
                        quantityAllocated: 0,
                        quantityMoved: difference ?? 0,
                        serialNumbers: [],
                        tenantInventoryId: inventoryId,
                    },
                ],
                movementType: InventoryMovementBatchType.Adjustment,
                notes,
                userDefinedFields: {}, // TODO?
            }).unwrap();

            // above will throw if save fails and toast message will show
            // otherwise close now
            close?.();
        }
    }, [difference, createMutation, locationId, inventoryId, notes, close]);

    return (
        <FormValidation submit={submit}>
            {({ handleSubmit }) => (
                <>
                    <PropertyDisplay
                        label="Location"
                        value={
                            <LocationSelectInput
                                value={locationId}
                                locations={locationsWithCanStore}
                                onChange={setLocationId}
                                disabled={createMutationStatus.isLoading}
                                validationRequired="Please select a location"
                            />
                        }
                    />

                    {locationId && (
                        <>
                            <PropertyDisplay
                                label="Current quantity"
                                value={<QuantityDisplay value={locationQuantity} />}
                            />

                            <PropertyEditNumber
                                className="AddMovementDialog__AdjustmentForm__NewQuantity"
                                label="New quantity"
                                value={quantity}
                                min={0}
                                withButtons={true}
                                onChange={val => setQuantity(val ?? locationQuantity)}
                                disabled={createMutationStatus.isLoading}
                                validationRequired="Please enter a new quantity"
                                validationCustom={
                                    quantity === locationQuantity &&
                                    "New quantity can't be the same as current quantity"
                                }
                            />

                            <PropertyDisplay
                                className="AddMovementDialog__DifferenceDisplay"
                                label="Adjustment"
                                value={
                                    <QuantityDisplay
                                        value={difference === 0 ? undefined : difference}
                                        positiveNegative={true}
                                    />
                                }
                            />

                            <PropertyEditText
                                label="Notes"
                                value={notes}
                                onChange={setNotes}
                                multiline={true}
                                disabled={createMutationStatus.isLoading}
                            />
                        </>
                    )}

                    <div className="AddMovementDialog__ButtonRow">
                        <MyButton
                            label="Cancel"
                            buttonType="Hollow"
                            onClick={close}
                            disabled={createMutationStatus.isLoading}
                        />
                        <MyButton
                            label="Save"
                            buttonType="Primary"
                            onClick={handleSubmit}
                            isLoading={createMutationStatus.isLoading}
                        />
                    </div>
                </>
            )}
        </FormValidation>
    );
}

function TransferForm({
    inventoryId,
    locationId = '',
    setLocationId,
    notes = '',
    setNotes,
    close,
}: {
    inventoryId: string;
    locationId?: string;
    setLocationId?: (id: string) => void;
    notes?: string;
    setNotes?: (id: string) => void;
    close?: () => void;
}) {
    const [quantity, setQuantity] = useState<number>(1);
    const [destLocationId, setDestLocationId] = useState('');

    const locationsQuery = inventoryApi.useLocationListQuery();
    const stockQueryHash = useStockQueryHash(inventoryId);

    /** find locations with stock > 0 */
    const locationsWithStock = useMemo(() => {
        return locationsQuery.data?.data.filter(loc => {
            const qty = stockQueryHash[loc.id] ?? 0;
            return qty > 0;
        });
    }, [locationsQuery.data?.data, stockQueryHash]);

    /** number on hand in the selected location */
    const sourceLocationQuantity = useMemo(
        () => stockQueryHash[locationId] ?? 0,
        [locationId, stockQueryHash],
    );

    const [createMutation, createMutationStatus] =
        inventoryApi.useInventoryMovementCreateMutation();
    const submit = useCallback(async () => {
        await createMutation({
            inventoryMovements: [
                {
                    locationId,
                    quantityAllocated: 0,
                    quantityMoved: -quantity, // pass as negative
                    serialNumbers: [],
                    tenantInventoryId: inventoryId,
                },
                {
                    locationId: destLocationId,
                    quantityAllocated: 0,
                    quantityMoved: quantity, // pass as positive
                    serialNumbers: [],
                    tenantInventoryId: inventoryId,
                },
            ],
            movementType: InventoryMovementBatchType.Transfer,
            notes,
            userDefinedFields: {}, // TODO?
        }).unwrap();

        // above will throw if save fails and toast message will show
        // otherwise close now
        close?.();
    }, [createMutation, locationId, quantity, inventoryId, destLocationId, notes, close]);

    return (
        <FormValidation submit={submit}>
            {({ handleSubmit }) => (
                <>
                    <PropertyDisplay
                        label="From location"
                        value={
                            <LocationSelectInput
                                value={locationId}
                                onChange={setLocationId}
                                locations={locationsWithStock}
                                disabled={createMutationStatus.isLoading}
                                validationRequired="Please select a location"
                            />
                        }
                    />

                    {locationId && (
                        <>
                            <PropertyDisplay
                                label="Available quantity"
                                value={<QuantityDisplay value={sourceLocationQuantity} />}
                            />

                            <PropertyDisplay
                                label="To location"
                                value={
                                    <LocationSelectInput
                                        value={destLocationId}
                                        onChange={setDestLocationId}
                                        disabled={createMutationStatus.isLoading}
                                        validationRequired="Please select a location"
                                        validationCustom={
                                            locationId === destLocationId &&
                                            'Locations in the from and to fields cannot be the same'
                                        }
                                    />
                                }
                            />

                            {destLocationId && (
                                <>
                                    <PropertyEditNumber
                                        className="AddMovementDialog__WriteOffForm__Quantity"
                                        label="Transfer quantity"
                                        value={quantity}
                                        min={1}
                                        max={sourceLocationQuantity}
                                        withButtons={true}
                                        onChange={val => setQuantity(val ?? 1)}
                                        disabled={createMutationStatus.isLoading}
                                        validationRequired="Please enter a write off quantity"
                                        validationCustom={
                                            quantity > sourceLocationQuantity &&
                                            "Transfer quantity can't be more than available quantity"
                                        }
                                    />

                                    <PropertyEditText
                                        label="Notes"
                                        value={notes}
                                        onChange={setNotes}
                                        multiline={true}
                                        disabled={createMutationStatus.isLoading}
                                    />
                                </>
                            )}
                        </>
                    )}

                    <div className="AddMovementDialog__ButtonRow">
                        <MyButton
                            label="Cancel"
                            buttonType="Hollow"
                            onClick={close}
                            disabled={createMutationStatus.isLoading}
                        />
                        <MyButton
                            label="Save"
                            buttonType="Primary"
                            onClick={handleSubmit}
                            isLoading={createMutationStatus.isLoading}
                        />
                    </div>
                </>
            )}
        </FormValidation>
    );
}

function WriteOffForm({
    inventoryId,
    locationId = '',
    setLocationId,
    notes = '',
    setNotes,
    close,
}: {
    inventoryId: string;
    locationId?: string;
    setLocationId?: (id: string) => void;
    notes?: string;
    setNotes?: (id: string) => void;
    close?: () => void;
}) {
    const [quantity, setQuantity] = useState<number>(1);

    const locationsQuery = inventoryApi.useLocationListQuery();
    const stockQueryHash = useStockQueryHash(inventoryId);

    /** find locations with stock > 0 */
    const locationsWithStock = useMemo(() => {
        return locationsQuery.data?.data.filter(loc => {
            const qty = stockQueryHash[loc.id] ?? 0;
            return qty > 0;
        });
    }, [locationsQuery.data?.data, stockQueryHash]);

    /** number on hand in the selected location */
    const locationQuantity = useMemo(
        () => stockQueryHash[locationId] ?? 0,
        [locationId, stockQueryHash],
    );

    /** Quantity remaining after the adjustment */
    const adjustedQuantity = useMemo(() => {
        if (isEmpty(locationQuantity) || isEmpty(quantity)) {
            return undefined;
        }
        return locationQuantity - (quantity as number);
    }, [locationQuantity, quantity]);

    const [createMutation, createMutationStatus] =
        inventoryApi.useInventoryMovementCreateMutation();
    const submit = useCallback(async () => {
        await createMutation({
            inventoryMovements: [
                {
                    locationId,
                    quantityAllocated: 0,
                    quantityMoved: -quantity, // pass as negative
                    serialNumbers: [],
                    tenantInventoryId: inventoryId,
                },
            ],
            movementType: InventoryMovementBatchType.WriteOff,
            notes,
            userDefinedFields: {}, // TODO?
        }).unwrap();

        // above will throw if save fails and toast message will show
        // otherwise close now
        close?.();
    }, [createMutation, locationId, quantity, inventoryId, notes, close]);

    return (
        <FormValidation submit={submit}>
            {({ handleSubmit }) => (
                <>
                    <PropertyDisplay
                        label="Location"
                        value={
                            <LocationSelectInput
                                value={locationId}
                                onChange={setLocationId}
                                locations={locationsWithStock}
                                disabled={createMutationStatus.isLoading}
                                validationRequired="Please select a location"
                            />
                        }
                    />

                    {locationId && (
                        <>
                            <PropertyDisplay
                                label="Current quantity"
                                value={<QuantityDisplay value={locationQuantity} />}
                            />

                            <PropertyEditNumber
                                className="AddMovementDialog__WriteOffForm__Quantity"
                                label="Write off quantity"
                                value={quantity}
                                min={1}
                                max={locationQuantity}
                                withButtons={true}
                                onChange={val => setQuantity(val ?? 1)}
                                disabled={createMutationStatus.isLoading}
                                validationRequired="Please enter a write off quantity"
                                validationCustom={
                                    quantity > locationQuantity &&
                                    "Write off quantity can't be more than current quantity"
                                }
                            />

                            <PropertyDisplay
                                label="Quantity remaining"
                                value={<QuantityDisplay value={adjustedQuantity} />}
                            />

                            <PropertyEditText
                                label="Notes"
                                value={notes}
                                onChange={setNotes}
                                multiline={true}
                                disabled={createMutationStatus.isLoading}
                            />
                        </>
                    )}

                    <div className="AddMovementDialog__ButtonRow">
                        <MyButton
                            label="Cancel"
                            buttonType="Hollow"
                            onClick={close}
                            disabled={createMutationStatus.isLoading}
                        />
                        <MyButton
                            label="Save"
                            buttonType="Primary"
                            onClick={handleSubmit}
                            isLoading={createMutationStatus.isLoading}
                        />
                    </div>
                </>
            )}
        </FormValidation>
    );
}

function QuantityDisplay({
    value,
    positiveNegative = false,
}: {
    value?: any;
    positiveNegative?: boolean;
}) {
    return (
        <div className="AddMovementDialog__QuantityDisplay">
            {positiveNegative && value && value > 0 ? (
                <span className="positive">+{value}</span>
            ) : positiveNegative && value && value < 0 ? (
                <span className="negative">{value}</span>
            ) : (
                value ?? '-'
            )}
        </div>
    );
}

function useStockQueryHash(inventoryId: string) {
    const stockQuery = inventoryApi.useInventoryLocationsQuery(inventoryId);

    // create a hash of stock levels by location id
    const hash = useMemo(() => {
        const warehouses = stockQuery.data?.warehouses ?? [];
        const result = {} as { [key: string]: number | undefined };
        warehouses.forEach(w => {
            w.locations.forEach(loc => {
                const id = loc.path[loc.path.length - 1]?.id;
                if (id) {
                    result[id] = loc.count;
                }
            });
            return result;
        });
        return result;
    }, [stockQuery.data?.warehouses]);

    return hash;
}
