import createDisplayEnum, { EnumDisplayLabels } from 'utils/createDisplayEnum';

enum LocationType {
    Warehouse = 'WAREHOUSE',
    Aisle = 'AISLE',
    Bay = 'BAY',
    Bucket = 'BUCKET',
    Level = 'LEVEL',
    Rack = 'RACK',
    Row = 'ROW',
}
export default LocationType;

const labels: EnumDisplayLabels<typeof LocationType> = {
    Warehouse: 'Warehouse',
    Aisle: 'Aisle',
    Bay: 'Bay',
    Bucket: 'Bucket',
    Level: 'Level',
    Rack: 'Rack',
    Row: 'Row',
};

export const LocationTypeDisplay = createDisplayEnum(LocationType, labels);
