import { ScheduleEntitySchema } from 'features/schedule/models/ScheduleEntity';
import { ScheduleMetaEntitySchema } from 'features/schedule/models/ScheduleMetaEntity';
import z from 'zod';
import { WorkOrderEntitySchema } from './WorkOrderEntity';
import { WorkOrderItemWithStateSchema } from './WorkOrderItemWithState';
import { WorkstationGroupSchema } from './WorkstationGroup';

export const WorkOrderDetailSchema = WorkOrderEntitySchema.extend({
    context: z.object({
        workOrderItems: z.array(WorkOrderItemWithStateSchema),
        schedule: ScheduleEntitySchema,
        scheduleMeta: ScheduleMetaEntitySchema,
        workstationGroupAssignment: WorkstationGroupSchema.optional(),
    }),
});

export type WorkOrderDetail = z.infer<typeof WorkOrderDetailSchema>;
