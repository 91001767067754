import { UdfValueHashSchema } from 'models/UserDefinedField';
import { dateTimeInstant } from 'utils/dateSchemas';
import z from 'zod';
import StocktakeStatus from '../enums/StocktakeStatus';
import StocktakeType from '../enums/StocktakeType';
import { StocktakeTargetSchema } from './StocktakeTarget';

export const StocktakeSchema = z.object({
    id: z.string(), // uuid
    notes: z.string(),
    number: z.string(),
    status: z.nativeEnum(StocktakeStatus),
    parentLocationId: z.string(),
    parentLocationName: z.string(),
    stocktakeType: z.nativeEnum(StocktakeType),
    userDefinedFields: UdfValueHashSchema,
    createdAt: dateTimeInstant(),
    isArchived: z.boolean(),
    targets: z.array(StocktakeTargetSchema).optional(),
});

export type Stocktake = z.infer<typeof StocktakeSchema>;
