import createDisplayEnum, { EnumDisplayLabels } from 'utils/createDisplayEnum';

enum WorkItemStatus {
    Unstarted = 'UNSTARTED',
    InProgress = 'INPROGRESS',
    OnHold = 'ONHOLD',
    Completed = 'COMPLETED',
}
export default WorkItemStatus;

const labels: EnumDisplayLabels<typeof WorkItemStatus> = {
    Unstarted: 'Not started',
    InProgress: 'In progress',
    OnHold: 'On hold',
    Completed: 'Completed',
};

export const WorkItemStatusDisplay = createDisplayEnum(WorkItemStatus, labels);
