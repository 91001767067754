import QuoteriteWelcomeLogoSvg from 'assets/quoterite-welcome-logo.svg';
import MyButton, { MyButtonLink } from 'components/MyButton/MyButton';
import MyTextInput from 'components/MyTextInput/MyTextInput';
import authApi from 'features/auth/auth.api';
import useUrlQueryState from 'hooks/useUrlQueryState';
import { useDialogManager } from 'providers/DialogManager';
import FormValidation from 'providers/FormValidation';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PasswordStrengthBar from 'react-password-strength-bar';
import { useNavigate } from 'react-router-dom';
import { usePageTitle } from 'utils/locationHelpers';
import './ResetPasswordPage.scss';

export default function ResetPasswordPage() {
    usePageTitle('Reset Password');
    useEffect(() => {
        document.querySelector('body')?.classList.add('body--ResetPasswordPage');
        return () => document.querySelector('body')?.classList.remove('body--ResetPasswordPage');
    }, []);

    const [token] = useUrlQueryState('token');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const navigate = useNavigate();
    const dialogManager = useDialogManager();
    const [mutation, mutationState] = authApi.useResetPasswordMutation();

    const submitForm = useCallback(async () => {
        try {
            await mutation({
                token,
                password,
                confirm_password: confirmPassword,
            }).unwrap();
            await dialogManager.alert({
                title: 'Success',
                message: 'Your password has been reset successfully.',
            });
            navigate('/login');
        } catch (e) {
            dialogManager.alert({
                title: 'Failed to reset password',
                message:
                    'We were unable to reset your password, the link may have expired. Please try again or request a new link via the forgot password form.',
            });
            throw e;
        }
    }, [confirmPassword, dialogManager, mutation, navigate, password, token]);

    useEffect(() => {
        if (!token) {
            navigate('/login');
        }
    }, [navigate, token]);

    const passwordValidation = useMemo(() => {
        if (password.length < 8) {
            return 'Password must be at least 8 characters long';
        }
        return '';
    }, [password]);

    return (
        <div className="ResetPasswordPage">
            <img
                className="ResetPasswordPage__Logo"
                src={QuoteriteWelcomeLogoSvg}
            />
            <h1 className="ResetPasswordPage__Title">Reset Password</h1>

            <div className="ResetPasswordPage__FormWrapper">
                <FormValidation submit={submitForm}>
                    {({ handleSubmit }) => (
                        <form
                            noValidate
                            className="ResetPasswordPage__Form"
                            onSubmit={handleSubmit}
                        >
                            <MyTextInput
                                type="password"
                                name="password"
                                label="New password"
                                value={password}
                                autoFocus
                                handleInput={setPassword}
                                disabled={mutationState.isLoading}
                                validationRequired={'Please enter a new password'}
                                validationCustom={passwordValidation}
                            />

                            {password && !passwordValidation && (
                                <PasswordStrengthBar password={password} />
                            )}

                            <MyTextInput
                                type="password"
                                name="confirm"
                                label="Confirm password"
                                value={confirmPassword}
                                handleInput={setConfirmPassword}
                                disabled={mutationState.isLoading}
                                validationRequired={'Please confirm your new password'}
                                validationCustom={
                                    password !== confirmPassword
                                        ? 'Passwords do not match'
                                        : undefined
                                }
                            />

                            <MyButton
                                buttonType="Secondary"
                                size="large"
                                type="submit"
                                isLoading={mutationState.isLoading}
                            >
                                Reset password
                            </MyButton>
                        </form>
                    )}
                </FormValidation>
            </div>
            <div className="ResetPasswordPage__BackToLogin">
                <MyButton
                    buttonType="LinkButton"
                    href="/login"
                    LinkComponent={MyButtonLink}
                >
                    Back to login
                </MyButton>
            </div>
        </div>
    );
}
