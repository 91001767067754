import createDisplayEnum, { EnumDisplayLabels } from 'utils/createDisplayEnum';

enum AccountingConnectionStatus {
    Pending = 'PENDING',
    Failed = 'FAILED',
    Fulfilled = 'FULFILLED',
    External = 'EXTERNAL',
}

export default AccountingConnectionStatus;

const labels: EnumDisplayLabels<typeof AccountingConnectionStatus> = {
    Pending: 'Pending',
    Failed: 'Failed',
    Fulfilled: 'Fulfilled',
    External: 'External',
};

export const AccountingConnectionStatusDisplay = createDisplayEnum(
    AccountingConnectionStatus,
    labels,
);
