import createDisplayEnum, { EnumDisplayLabels } from 'utils/createDisplayEnum';

enum WorkItemFieldUnits {
    None = 'NONE',
    Inches = 'IN',
    Millimetres = 'MM',
}
export default WorkItemFieldUnits;

const labels: EnumDisplayLabels<typeof WorkItemFieldUnits> = {
    None: 'None',
    Inches: 'Inches',
    Millimetres: 'Millimetres',
};

export const WorkItemFieldUnitsDisplay = createDisplayEnum(WorkItemFieldUnits, labels);
