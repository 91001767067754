import z from 'zod';
import { StocktakeSchema } from './Stocktake';
import { UserDefinedFieldSchema } from 'models/UserDefinedField';

export const StocktakeListResultSchema = z.object({
    data: z.array(StocktakeSchema),
    total: z.number(),
    schemaExtensions: z.object({
        udfs: z.array(UserDefinedFieldSchema),
    }),
});

export type StocktakeListResult = z.infer<typeof StocktakeListResultSchema>;
