import { dateTimeOldSystem } from 'utils/dateSchemas';
import { parseEnum } from 'utils/helpers';
import z from 'zod';
import DocumentFriendlyType, { getDocumentFriendlyType } from '../enums/DocumentFriendlyType';

export const DealerDocumentFileSchema = z.object({
    id: z.number(),
    filename: z.string(),
    extension: z.string(),
    mime_type: z.string(),
    size: z.string(),
    type: z.string().transform(
        // Handle bad data from backend - sometimes type can be file extension instead
        val => parseEnum(DocumentFriendlyType, val) ?? getDocumentFriendlyType(`file.${val}`),
    ),
    name: z.string(),
    document_category_id: z.number().nullable(),
    account_id: z.number().nullable(),
    created_by: z.number().nullable(),
    updated_by: z.number().nullable(),
    created_at: dateTimeOldSystem({ isUTC: true }),
    updated_at: dateTimeOldSystem({ isUTC: true }),
    direct_upload: z.number(),
    // main_admin_user: z.number().nullable(), ?
    employee_download_count: z.number(),
    client_download_count: z.number(),
    admin_company_id: z.number().nullable(),
    user_created_by: z.number().nullable(),
    user_updated_by: z.number().nullable(),
    manufacturer_employee_id: z.number().nullable(),
    brand_id: z.number().nullable(),
    link: z.string().nullable(),
    document_type: z.string(),
    url: z.string(),
    pivot: z
        .object({
            documentable_id: z.number(),
            document_id: z.number(),
            documentable_type: z.string(),
        })
        .optional(),
});

export type DealerDocumentFile = z.infer<typeof DealerDocumentFileSchema>;
