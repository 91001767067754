import z from 'zod';
import StocktakeImportErrorCode from '../enums/StocktakeImportErrorCode';

export const StocktakeImportErrorSchema = z.object({
    type: z.string(), // "BadRequest"
    description: z.string(), // "only a single file can be uploaded"
    code: z.nativeEnum(StocktakeImportErrorCode), // NotFound
    reason: z.string().optional(), // "QtyBlank" only when excel file has an error in it
});

export type StocktakeImportError = z.infer<typeof StocktakeImportErrorSchema>;
