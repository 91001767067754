import { UserDefinedFieldSchema } from 'models/UserDefinedField';
import z from 'zod';
import { LocationSchema } from './Location';

export const LocationListResultSchema = z.object({
    data: z.array(LocationSchema),
    schemaExtensions: z.object({
        udfs: z.array(UserDefinedFieldSchema).optional(),
    }),
});

export type LocationListResult = z.infer<typeof LocationListResultSchema>;
