import { UserDefinedFieldSchema } from 'models/UserDefinedField';
import z from 'zod';

export const InventorySchemaResultSchema = z.object({
    data: z.array(z.any()),
    schemaExtensions: z.object({
        udfs: z.array(UserDefinedFieldSchema),
    }),
});

export type InventorySchemaResult = z.infer<typeof InventorySchemaResultSchema>;
