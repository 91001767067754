import z from 'zod';

export const CountSheetInventorySchema = z.object({
    countedQuantity: z.number().optional(),
    description: z.string(),
    expectedQuantity: z.number(),
    partNumber: z.string(),
    tenantInventoryId: z.string(),
});

export type CountSheetInventory = z.infer<typeof CountSheetInventorySchema>;
