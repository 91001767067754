import Dialog from 'components/Dialogs/Dialog';
import MessagePanel from 'components/MessagePanel/MessagePanel';
import MyButton from 'components/MyButton/MyButton';
import { SalesItem } from 'features/sales/models/SalesItem';
import { SalesOrderDetail } from 'features/sales/models/SalesOrderDetail';
import salesApi from 'features/sales/sales.api';
import { useDialogManager } from 'providers/DialogManager';
import React, { useCallback, useMemo } from 'react';
import './SalesOrderSplitDialog.scss';

export default function SalesOrderSplitDialog({
    order,
    selectedItems,
    close,
}: {
    order: SalesOrderDetail;
    selectedItems: SalesItem[];
    close?: () => void;
}) {
    const [splitOrderMutation, splitOrderMutationState] = salesApi.useSalesOrderSplitMutation();
    const workItemsQuery = salesApi.useSalesOrderWorkItemsQuery(order.legacyId);
    const dialogManager = useDialogManager();
    const tooManyItemsSelected = selectedItems.length === order.context.orderLines.length;

    const commit = useCallback(async () => {
        const filteredIds = selectedItems.map(item => item.id);

        const result = await splitOrderMutation({
            orderId: order.legacyId,
            orderItems: filteredIds,
        }).unwrap();

        dialogManager.toast({
            title: `New order ${result.manufacturerReference} has been created.`,
            actionLinkTo: `/sales/${result.id}`,
            actionText: 'View order',
            icon: 'party',
            displayTime: 10000,
            dismissOnClick: false,
        });
        close?.();
    }, [close, dialogManager, order.legacyId, selectedItems, splitOrderMutation]);

    const workItemsExist = useMemo(() => {
        if (workItemsQuery.data) {
            const idsWithItems = Object.keys(workItemsQuery.data);
            const selectedIds = selectedItems.map(i => `${i.id}`);
            return selectedIds.some(id => idsWithItems.includes(id));
        }
        return false;
    }, [selectedItems, workItemsQuery.data]);

    return (
        <Dialog
            className="SalesOrderSplitDialog"
            close={close}
        >
            <h2>Split to new order</h2>

            {tooManyItemsSelected ? (
                <>
                    <MessagePanel
                        className="SalesOrderSplitDialog__AllItemsSelected"
                        messageType="warning"
                    >
                        You must leave at least one line item unticked
                    </MessagePanel>
                    <div className="SalesOrderSplitDialog__Buttons">
                        <MyButton
                            label="Ok"
                            buttonType="Hollow"
                            onClick={close}
                        />
                    </div>
                </>
            ) : workItemsExist ? (
                <>
                    <MessagePanel
                        className="SalesOrderSplitDialog__OrderHasWorkItems"
                        messageType="warning"
                    >
                        The selected sales items must not have associated work items
                    </MessagePanel>
                    <div className="SalesOrderSplitDialog__Buttons">
                        <MyButton
                            label="Ok"
                            buttonType="Hollow"
                            onClick={close}
                        />
                    </div>
                </>
            ) : (
                <>
                    <p className="SalesOrderSplitDialog__Message">
                        Are you sure you want to split {selectedItems.length}{' '}
                        {selectedItems.length === 1 ? 'item' : 'items'} to a new order ?
                    </p>
                    <div className="SalesOrderSplitDialog__Buttons">
                        <MyButton
                            label="Yes, split now"
                            buttonType="Primary"
                            onClick={commit}
                            isLoading={splitOrderMutationState.isLoading}
                        />
                        <MyButton
                            label="Cancel"
                            buttonType="Hollow"
                            onClick={close}
                            disabled={splitOrderMutationState.isLoading}
                        />
                    </div>
                </>
            )}
        </Dialog>
    );
}
