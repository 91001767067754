import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const slice = createSlice({
    name: 'customers',
    initialState,
    reducers: {},
    // extraReducers: builder => {

    // },
});

// export const { setCurrentWorkstation } = slice.actions;

export default slice.reducer;

// export const selectCurrentWorkstation = (state: RootState) => state.customers.currentWorkstation;
