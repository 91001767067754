import { addListener, configureStore, createListenerMiddleware } from '@reduxjs/toolkit';
import Env from 'config/Env';
import accountingReducer from 'features/accounting/accounting.slice';
import authReducer from 'features/auth/auth.slice';
import customersReducer from 'features/customers/customers.slice';
import dispatchReducer from 'features/dispatch/dispatch.slice';
import documentsReducer from 'features/documents/documents.slice';
import inventoryReducer from 'features/inventory/inventory.slice';
import scheduleReducer from 'features/schedule/schedule.slice';
import stocktakeReducer from 'features/stocktake/stocktake.slice';
import workOrdersReducer from 'features/workOrders/workOrders.slice';
import workstationsReducer from 'features/workstations/workstations.slice';
import { createBrowserHistory } from 'history';
import { createReduxHistoryContext } from 'redux-first-history';
import formsReducer from './forms.slice';
// import logger from 'redux-logger';
import { api } from 'services/api';
import apiErrorMiddleware from './apiErrorMiddleware';

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
    history: createBrowserHistory(),
    reduxTravelling: Env.isDev,
    savePreviousLocations: 1,
});

const makeStore = () => {
    const store = configureStore({
        reducer: {
            [api.reducerPath]: api.reducer,
            accounting: accountingReducer,
            auth: authReducer,
            customers: customersReducer,
            dispatch: dispatchReducer,
            documents: documentsReducer,
            forms: formsReducer,
            inventory: inventoryReducer,
            schedule: scheduleReducer,
            stocktake: stocktakeReducer,
            workOrders: workOrdersReducer,
            workstations: workstationsReducer,
            router: routerReducer,
        },
        devTools: Env.isDev,
        middleware: getDefaultMiddleware =>
            getDefaultMiddleware({
                serializableCheck: {
                    // Ignore warnings on these action types
                    ignoredActions: [addListener.type],
                },
            })
                .concat(createListenerMiddleware().middleware)
                .concat(routerMiddleware)
                // .concat(logger)
                .concat(api.middleware)
                .concat(apiErrorMiddleware),
    });

    return store;
};

export const store = makeStore();

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export const history = createReduxHistory(store);
