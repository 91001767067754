import InventoryReceiptDetailModal from 'features/inventory/components/InventoryReceiptDetailModal/InventoryReceiptDetailModal';
import inventoryApi from 'features/inventory/inventory.api';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { usePageTitle } from 'utils/locationHelpers';

export default function InventoryReceiptDetailPage() {
    const { receiptId = '' } = useParams();
    const navigate = useNavigate();

    const query = inventoryApi.useInventoryReceiptDetailQuery(receiptId);
    usePageTitle(query.data?.tuid);

    return (
        <InventoryReceiptDetailModal
            model={query.data}
            isLoading={query.isLoading}
            isError={query.isError}
            close={() => navigate('/receiving')}
        />
    );
}
