import z from 'zod';
import { InventoryLocationPathSchema } from './InventoryLocationsResult';
import { InventoryTotalsSchema } from './InventoryTotals';

export const InventoryLocationJoinDetailSchema = InventoryTotalsSchema.extend({
    context: z.object({
        locationPath: z.array(InventoryLocationPathSchema).optional(),
    }),
    // containers: z.array(InventoryContainerSchema).optional(),
});
export type InventoryLocationJoinDetail = z.infer<typeof InventoryLocationJoinDetailSchema>;
