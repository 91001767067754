import Icons from 'Icons';
import MyButton from 'components/MyButton/MyButton';
import DocumentDetailModal from 'features/documents/components/DocumentDetailModal/DocumentDetailModal';
import DocumentUploadModal from 'features/documents/components/DocumentUploadModal/DocumentUploadModal';
import DocumentsTable from 'features/documents/components/DocumentsTable/DocumentsTable';
import { DocumentUploadResultDocument } from 'features/documents/models/DocumentUploadResult';
import { SalesOrder } from 'features/sales/models/SalesOrder';
import salesApi from 'features/sales/sales.api';
import React, { useCallback, useMemo, useState } from 'react';
import './SalesOrderDocumentsTab.scss';

export default function SalesOrderDocumentsTab({ order }: { order: SalesOrder }) {
    const docsQuery = salesApi.useSalesOrderDocumentsQuery(order.legacyId);
    const dealerDocsQuery = salesApi.useSalesOrderDealerDocumentsQuery(order.legacyId);

    const [showUpload, setShowUpload] = useState(false);
    const [attachMutation] = salesApi.useSalesOrderDocumentAttachMutation();
    const [archiveMutation] = salesApi.useSalesOrderDocumentArchiveMutation();

    /** After the document has been uploaded, attach it to the sales order */
    const attachUploadedDocument = useCallback(
        (document: DocumentUploadResultDocument) => {
            return attachMutation({
                document,
                manufacturerOrderId: order.legacyId,
            }).unwrap();
        },
        [attachMutation, order.legacyId],
    );

    const handleArchive = useCallback(
        async (documentId: number) => {
            return archiveMutation({
                manufacturerOrderId: order.legacyId,
                documentId,
            }).unwrap();
        },
        [archiveMutation, order.legacyId],
    );

    const [detailDocId, setDetailDocId] = useState<number>();
    const detailModel = useMemo(
        () => detailDocId && docsQuery.data?.data.find(d => d.id === detailDocId),
        [detailDocId, docsQuery.data?.data],
    );
    return (
        <div className="SalesOrderDocumentsTab">
            {/* Dealer docs */}
            <h2 className="SalesOrderDocumentsTab__Heading">Dealer Documents</h2>
            <div className="SalesOrderDocumentsTab__TableContainer">
                <DocumentsTable
                    data={dealerDocsQuery.data?.data}
                    isLoading={dealerDocsQuery.isLoading}
                    isError={dealerDocsQuery.isError}
                    onRefresh={dealerDocsQuery.refetch}
                    isRefreshing={dealerDocsQuery.isFetching}
                    rowLinkTo={doc => doc.url}
                    rowLinkToTarget="_blank"
                    showBrand={false}
                    showFilterBar={false}
                />
            </div>

            {/* Manufacturer docs */}
            <h2 className="SalesOrderDocumentsTab__Heading">
                Manufacturer Documents
                <MyButton
                    buttonType="Primary"
                    size="medium"
                    label="Upload documents"
                    IconLeft={Icons.Upload}
                    onClick={() => setShowUpload(true)}
                />
            </h2>
            <div className="SalesOrderDocumentsTab__TableContainer">
                <DocumentsTable
                    data={docsQuery.data?.data}
                    isLoading={docsQuery.isLoading}
                    isError={docsQuery.isError}
                    onRefresh={docsQuery.refetch}
                    isRefreshing={docsQuery.isFetching}
                    onRowClick={doc => setDetailDocId(doc.id)}
                    showBrand={false}
                    showFilterBar={false}
                />
            </div>

            {showUpload && (
                <DocumentUploadModal
                    showBrand={false}
                    directUpload={true}
                    afterUpload={attachUploadedDocument}
                    close={() => setShowUpload(false)}
                />
            )}

            {detailModel && (
                <DocumentDetailModal
                    model={detailModel}
                    showBrand={false}
                    onArchive={handleArchive}
                    close={() => setDetailDocId(undefined)}
                />
            )}
        </div>
    );
}
