import createDisplayEnum, { EnumDisplayLabels } from 'utils/createDisplayEnum';

enum AccountingConnectionType {
    Invoice = 'INVOICE',
    Contact = 'CONTACT',
}

export default AccountingConnectionType;

const labels: EnumDisplayLabels<typeof AccountingConnectionType> = {
    Invoice: 'Invoice',
    Contact: 'Contact',
};

export const AccountingConnectionTypeDisplay = createDisplayEnum(AccountingConnectionType, labels);
