import { rest } from 'msw';

import Env from 'config/Env';
import { fakePackages, fakeShipments } from './faker/ShipmentsFaker';
import { PackageEntity } from './models/PackageEntity';
import { ShipmentEntity } from './models/ShipmentEntity';

const BASE_URL = `${Env.API_BASE_URL}`;

export const dispatchHandlers = [
    // Get all shipment summaries
    rest.post(`${BASE_URL}/shipment`, (req, res, ctx) => {
        return res(
            ctx.delay(500),
            ctx.status(200),
            ctx.json({
                data: fakeShipments,
                total: fakeShipments.length,
            }),
        );
    }),

    // Get shipment detail
    rest.get(`${BASE_URL}/shipment/:id`, (req, res, ctx) => {
        const model = fakeShipments.find(s => s.id === req.params.id);
        return res(ctx.delay(500), ctx.status(200), ctx.json(model));
    }),

    // Update shipment
    rest.post(`${BASE_URL}/shipment/:id/update`, (req, res, ctx) => {
        const model = fakeShipments.find(s => s.id === req.params.id);
        if (model) {
            const body = req.body as ShipmentEntity;
            model.date = body.date;
            model.name = body.name;
            model.notes = body.notes;
            model.shipmentDays = body.shipmentDays;
            model.status = body.status;
        }

        return res(ctx.delay(500), ctx.status(200), ctx.json(true));
    }),

    // Get all packages
    rest.post(`${BASE_URL}/package`, (req, res, ctx) => {
        return res(
            ctx.delay(500),
            ctx.status(200),
            ctx.json({
                data: fakePackages,
                total: fakePackages.length,
            }),
        );
    }),

    // Get package detail
    rest.get(`${BASE_URL}/package/:id`, (req, res, ctx) => {
        const model = fakePackages.find(p => p.id === req.params.id);
        return res(ctx.delay(500), ctx.status(200), ctx.json(model));
    }),

    // Update package
    rest.post(`${BASE_URL}/package/:id/update`, (req, res, ctx) => {
        const model = fakePackages.find(p => p.id === req.params.id);
        if (model) {
            const body = req.body as PackageEntity;
            model.shipmentId = body.shipmentId;
            model.locationId = body.locationId;
            model.description = body.description;
            model.notes = body.notes;
            model.weight = body.weight;
            model.dimensions = body.dimensions;
        }

        return res(ctx.delay(500), ctx.status(200), ctx.json(true));
    }),
];
