import createDisplayEnum, { EnumDisplayLabels } from 'utils/createDisplayEnum';

enum UserDefinedFieldValueType {
    Boolean = 'BOOLEAN',
    Color = 'COLOUR',
    Date = 'DATE',
    Decimal = 'DECIMAL',
    File = 'FILE',
    Id = 'ID',
    Int = 'INT',
    Option = 'OPTION',
    String = 'STRING',
}
export default UserDefinedFieldValueType;

const labels: EnumDisplayLabels<typeof UserDefinedFieldValueType> = {
    Boolean: 'Boolean',
    Color: 'Colour',
    Date: 'Date',
    Decimal: 'Decimal',
    File: 'File',
    Id: 'Id',
    Int: 'Integer',
    Option: 'Option',
    String: 'String',
};

export const UserDefinedFieldValueTypeDisplay = createDisplayEnum(
    UserDefinedFieldValueType,
    labels,
);
