import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import { DateTime } from 'luxon';
import React, { ComponentType } from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import { isBetweenDates } from 'utils/dateHelpers';

interface MyCalendarDayProps extends PickersDayProps<DateTime> {
    selectedDay?: DateTime;
    selectedMonth?: DateTime;
    selectionMode: 'day' | 'week';
}

const MyCalendarDay = ((props: MyCalendarDayProps) => {
    const { day, className, selectionMode, selectedDay, selectedMonth, ...other } = props;

    const isDaySelected = selectedDay && day.hasSame(selectedDay, 'day');
    const isWeekMode = selectionMode === 'week';
    const weekFirst = isWeekMode ? selectedDay?.startOf('week') : undefined;
    const weekLast = isWeekMode ? selectedDay?.endOf('week') : undefined;

    const isWeekSelected = weekFirst ? isBetweenDates(day, weekFirst, weekLast) : false;
    const isFirst = weekFirst ? day.hasSame(weekFirst, 'day') : undefined;
    const isLast = weekLast ? day.hasSame(weekLast, 'day') : undefined;

    return (
        <div
            className={coalesceClassNames(
                'MyCalendarDay',
                className,
                isDaySelected && 'selected-day',
                isWeekSelected && 'selected-week',
                isFirst && 'first',
                isLast && 'last',
            )}
        >
            <PickersDay
                {...other}
                className="MyCalendarDay__Button"
                day={day}
            />
        </div>
    );
}) as ComponentType<PickersDayProps<DateTime>>;

export default MyCalendarDay;
