import createDisplayEnum, { EnumDisplayLabels } from 'utils/createDisplayEnum';

enum WorkflowBreakdownNonMappedKeys {
    Omit = 'OMIT',
    Append = 'APPEND',
}
export default WorkflowBreakdownNonMappedKeys;

const labels: EnumDisplayLabels<typeof WorkflowBreakdownNonMappedKeys> = {
    Omit: 'Omit',
    Append: 'Append',
};

export const WorkflowBreakdownNonMappedKeysDisplay = createDisplayEnum(
    WorkflowBreakdownNonMappedKeys,
    labels,
);
