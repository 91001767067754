import createDisplayEnum, { EnumDisplayLabels } from 'utils/createDisplayEnum';

enum YesNoEnum {
    Yes = 'true',
    No = 'false',
}

export default YesNoEnum;

const labels: EnumDisplayLabels<typeof YesNoEnum> = {
    Yes: 'Yes',
    No: 'No',
};

export const YesNoEnumDisplay = createDisplayEnum(YesNoEnum, labels);
