import { faker } from '@faker-js/faker';
import { WorkOrderEntity } from 'features/workOrders/models/WorkOrderEntity';
import { DateTime } from 'luxon';
import { Schedule } from '../models/Schedule';
import { ScheduledWorkOrder } from '../models/ScheduledWorkOrder';

faker.seed(42);
type WorkItemCountHash = { [key: string]: number };
const workOrderItemCounts: WorkItemCountHash = {};

function generateFakeScheduledWorkOrder(
    index: number,
    scheduledDate: DateTime,
): ScheduledWorkOrder {
    // create a fake work order
    // Can't call generateFakeWorkOrderDetail() because it will cause a circular dependency
    const wo: WorkOrderEntity = {
        id: faker.string.uuid(),
        tuid: `WRK${faker.number.int({ min: 1, max: 99999 }).toString().padStart(5, '0')}`,
        notes: '',
    };

    const daysRequired = faker.number.int({ min: 1, max: 7 });
    const requestedDate = scheduledDate.plus({
        days: faker.number.int({ min: -14, max: 14 }),
    });

    const estimatedCompletionDate = scheduledDate.plus({ days: daysRequired });

    const itemCount = faker.number.int({ min: 1, max: 3 });
    workOrderItemCounts[wo.id] = itemCount;

    return {
        ...wo,
        sortOrder: index + 1,
        daysRequired,
        requestedDate: requestedDate.toISODate() as string,
        estimatedCompletionDate: estimatedCompletionDate.toISO() as string,
        latestScheduleDate: requestedDate.plus({ days: daysRequired }).toISO() as string,
        slackDays: Math.ceil(requestedDate.diff(estimatedCompletionDate).as('days')),
        identifier: faker.string.alphanumeric(6),
        itemCount,
        workstationGroupAssignment: {
            id: faker.string.uuid(),
            name: faker.word.words(2),
            warehouseLocationId: faker.string.uuid(),
        },
    };
}

export function generateFakeSchedule(date: DateTime): Schedule {
    const scheduleWorkOrders: ScheduledWorkOrder[] = [];
    const count = faker.helpers.rangeToNumber({ min: 1, max: 10 });
    for (let i = 0; i < count; i++) {
        scheduleWorkOrders.push(generateFakeScheduledWorkOrder(i, date));
    }

    return {
        id: faker.string.uuid(),
        date: date.toISODate() as string,
        context: {
            scheduleWorkOrders,
            workOrderItemsCount: scheduleWorkOrders.reduce((hash, wo) => {
                hash[wo.id] = workOrderItemCounts[wo.id];
                return hash;
            }, {} as WorkItemCountHash),
        },
    };
}

const dtFirst = DateTime.now().startOf('day').minus({ days: 30 });
const dates = [] as DateTime[];
for (let i = 0; i < 60; i++) {
    const dt = dtFirst.plus({ days: i });
    // dont include weekends
    if (dt.weekday < 6) {
        dates.push(dt);
    }
}

export const fakeSchedules = dates.map(d => generateFakeSchedule(d));
