import Icons from 'Icons';
import { InventoryLocationPath } from 'features/inventory/models/InventoryLocationsResult';
import { Location } from 'features/inventory/models/Location';
import React from 'react';
import { Link } from 'react-router-dom';
import './LocationBreadcrumbs.scss';

export default function LocationBreadcrumbs({
    path,
    model,
}: {
    path: InventoryLocationPath[];
    /**
     * The model of the current location, to be shown at the end of the breadcrumbs.
     * As it is the current item, no link is generated
     */
    model?: Location;
}) {
    return (
        <div className="LocationBreadcrumbs">
            <Crumb
                id={''}
                name="Home"
            />
            {path?.map((l, i) => (
                <Crumb
                    key={l.id}
                    id={l.id}
                    name={l.name}
                    isLast={i === path.length - 1 && !model}
                />
            ))}
            <span className="current">{model?.name}</span>
        </div>
    );
}

function Crumb({ name, id, isLast = false }: { id: string; name: string; isLast?: boolean }) {
    return (
        <>
            <Link
                className="Link"
                to={`/locations/${id}`}
            >
                {name}
            </Link>
            {!isLast && <Icons.ChevronRight />}
        </>
    );
}
