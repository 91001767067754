import createDisplayEnum, { EnumDisplayLabels } from 'utils/createDisplayEnum';

enum CustomerHoldStatus {
    None = 'none',
    StopOrder = 'stop-order',
    StopMake = 'stop-make',
}
export default CustomerHoldStatus;

const labels: EnumDisplayLabels<typeof CustomerHoldStatus> = {
    None: 'None',
    StopOrder: 'Stop order',
    StopMake: 'Stop make',
};

export const CustomerHoldStatusDisplay = createDisplayEnum(CustomerHoldStatus, labels);
