import { ScheduledWorkOrderSchema } from 'features/schedule/models/ScheduledWorkOrder';
import z from 'zod';
import { ScheduleEntitySchema } from './ScheduleEntity';

export const ScheduleSchema = ScheduleEntitySchema.extend({
    /** context / work orders is only populated when loaded via /withworkorders route */
    context: z
        .object({
            scheduleWorkOrders: z.array(ScheduledWorkOrderSchema),
            workOrderItemsCount: z.record(z.string(), z.number()).default({}), // hash of workOrderId to count
        })
        .optional(),
}).transform(model => {
    // lookup itemCount to make this simpler later
    model.context?.scheduleWorkOrders.forEach(wo => {
        wo.itemCount = model.context?.workOrderItemsCount[wo.id];
    });
    return model;
});

export type Schedule = z.infer<typeof ScheduleSchema>;
