import { Menu } from '@mui/material';
import Icons from 'Icons';
import MyButton from 'components/MyButton/MyButton';
import MyCalendar from 'components/MyCalendar/MyCalendar';
import { DateTime } from 'luxon';
import React, { useCallback, useState } from 'react';
import coalesceClassNames, { extendClassName } from 'utils/coalesceClassNames';
import { formatDateRange } from 'utils/dateHelpers';
import './MyCalendarPicker.scss';

/** wrapper for mui calendar picker with a select-style input to activate it
 * see docs at https://mui.com/x/api/date-pickers/calendar-picker/
 */
export default function MyCalendarPicker({
    value,
    onChange,
    minDate,
    maxDate,
    className,
    selectionMode = 'day', // day | week
    shouldDisableDate,
}: {
    value?: DateTime;
    onChange?: (value: DateTime) => void;
    minDate?: DateTime;
    maxDate?: DateTime;
    className?: string;
    selectionMode?: 'day' | 'week';
    shouldDisableDate?: (day: DateTime) => boolean;
}) {
    const [isCalendarOpen, setIsCalendarOpen] = useState(false);
    const anchorRef = React.useRef<HTMLDivElement>(null);

    const weekStartDate = selectionMode === 'week' ? value?.startOf('week') : undefined;
    const weekEndDate = selectionMode === 'week' ? value?.endOf('week') : undefined;

    const goToPrev = useCallback(() => {
        const interval = selectionMode === 'week' ? { weeks: 1 } : { days: 1 };
        const val = value ?? DateTime.now().startOf('day');
        onChange?.(val.minus(interval));
    }, [onChange, selectionMode, value]);

    const goToNext = useCallback(() => {
        const interval = selectionMode === 'week' ? { weeks: 1 } : { days: 1 };
        const val = value ?? DateTime.now().startOf('day');
        onChange?.(val.plus(interval));
    }, [onChange, selectionMode, value]);

    return (
        <>
            <div
                ref={anchorRef}
                className={coalesceClassNames(
                    'MyCalendarPicker',
                    `MyCalendarPicker--selection-${selectionMode}`,
                    className,
                )}
            >
                <MyButton
                    className="MyCalendarPicker__PrevButton"
                    buttonType="None"
                    IconLeft={Icons.ChevronLeft}
                    onClick={goToPrev}
                />
                <MyButton
                    className="MyCalendarPicker__Button"
                    buttonType="None"
                    onClick={() => setIsCalendarOpen(!isCalendarOpen)}
                    fullWidth
                >
                    {!value ? (
                        <>Select a date</>
                    ) : weekStartDate && weekEndDate ? (
                        formatDateRange(weekStartDate, weekEndDate, { includeYear: true })
                    ) : (
                        // {/* Week ending {weekEndDate.weekdayShort} {weekEndDate.toFormat('dd MMM')} */}
                        value.toFormat('EEE dd MMM yyyy')
                    )}
                </MyButton>
                <MyButton
                    className="MyCalendarPicker__NextButton"
                    buttonType="None"
                    IconLeft={Icons.ChevronRight}
                    onClick={goToNext}
                />
            </div>
            <Menu
                open={isCalendarOpen}
                onClose={() => setIsCalendarOpen(false)}
                anchorEl={anchorRef.current}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MyCalendar
                    className={coalesceClassNames(
                        'MyCalendarPicker__Calendar',
                        className && extendClassName(className, '__Calendar'),
                    )}
                    value={value}
                    onChange={onChange}
                    selectionMode={selectionMode}
                    shouldDisableDate={shouldDisableDate}
                    minDate={minDate}
                    maxDate={maxDate}
                />
            </Menu>
        </>
    );
}
