import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    // currentWorkstation: undefined,
} as {
    // currentWorkstation?: Workstation;
};

const slice = createSlice({
    name: 'workOrders',
    initialState,
    reducers: {},
    // extraReducers: builder => {

    // },
});

// export const { setCurrentWorkstation } = slice.actions;

export default slice.reducer;

// export const selectCurrentWorkstation = (state: RootState) => state.workstations.currentWorkstation;
