import { CircularProgress } from '@mui/material';
import Icons from 'Icons';
import useDebouncedCallback from 'hooks/useDebouncedCallback';
import React, { useEffect } from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import './SaveStatusIndicator.scss';

type SaveStatus = 'saving' | 'success' | 'error' | undefined;

export default function SaveStatusIndicator({
    className,
    isSaving,
    isSuccess,
    isError,
}: {
    className?: string;
    isSaving: boolean;
    isSuccess: boolean;
    isError: boolean;
}) {
    const [saveStatus, setSaveStatus] = React.useState<SaveStatus>();

    /** Debounced update allows the loading spinner to display for a little longer than it actually takes to run any save
     * This gives a nicer experience for the user
     */
    const updateSaveStatus = useDebouncedCallback(
        500,
        (status: SaveStatus) => {
            setSaveStatus(status);
        },
        [],
    );

    useEffect(() => {
        if (isSaving) {
            // dont debounce here
            setSaveStatus('saving');
        } else if (isError) {
            updateSaveStatus('error');
        } else if (isSuccess) {
            updateSaveStatus('success');
        } else {
            updateSaveStatus(undefined);
        }
    }, [isSaving, isSuccess, isError, updateSaveStatus]);

    return (
        <div className={coalesceClassNames('SaveStatusIndicator', className)}>
            {saveStatus === 'saving' ? (
                <CircularProgress
                    className="SaveStatusIndicator__Progress"
                    size={20}
                />
            ) : saveStatus === 'error' ? (
                <div className="SaveStatusIndicator__Error">
                    <Icons.Error />
                </div>
            ) : saveStatus === 'success' ? (
                <div className="SaveStatusIndicator__Success">
                    <Icons.Check />
                </div>
            ) : null}
        </div>
    );
}
