import Icons from 'Icons';
import ErrorContent from 'components/ErrorContent/ErrorContent';
import MyButton from 'components/MyButton/MyButton';
import MyLinearProgress from 'components/MyLinearProgress/MyLinearProgress';
import PageHeader from 'components/PageHeader/PageHeader';
import WorkflowItemSplitEditModal from 'features/workOrders/components/WorkflowItemSplitEditModal/WorkflowItemSplitEditModal';
import WorkflowItemSplitTable from 'features/workOrders/components/WorkflowItemSplitTable/WorkflowItemSplitTable';
import { Workflow } from 'features/workOrders/models/Workflow';
import {
    WorkflowItemSplitConfig,
    WorkflowItemSplitConfigFactory,
} from 'features/workOrders/models/WorkflowItemSplitConfig';
import workOrdersApi from 'features/workOrders/workOrders.api';
import { useDialogManager } from 'providers/DialogManager';
import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { generateShortUuid } from 'utils/helpers';

export default function WorkflowBreakdownPage() {
    const { workflowId = '' } = useParams() as { workflowId?: string };
    return (
        <>
            {workflowId && (
                <WorkflowBreakdownPage_Pure
                    key={workflowId}
                    workflowId={workflowId}
                />
            )}
        </>
    );
}

function WorkflowBreakdownPage_Pure({ workflowId }: { workflowId: string }) {
    const query = workOrdersApi.useWorkflowQuery(workflowId);
    const [detailConfig, setDetailConfig] = useState<WorkflowItemSplitConfig>();

    const addConfig = useCallback(() => {
        setDetailConfig(WorkflowItemSplitConfigFactory.create({}));
    }, []);

    // const dispatch = useAppDispatch();
    const dialogManager = useDialogManager();
    const [saveMutation] = workOrdersApi.useWorkflowUpdateMutation();

    const saveConfig = useCallback(
        async (config: WorkflowItemSplitConfig) => {
            if (!query.data) {
                throw new Error('Workflow not loaded');
            }

            const currentConfigs: WorkflowItemSplitConfig[] =
                query.data.itemSplitMappingConfig ?? [];
            const updatedConfigs: WorkflowItemSplitConfig[] = [...currentConfigs];

            // append or update the config
            const index = currentConfigs.findIndex(c => c.id === config.id);
            if (index === -1) {
                updatedConfigs.push(config);
            } else {
                updatedConfigs[index] = config;
            }

            // copy the workflow and update the itemSplitMappingConfig
            const updatedWorkflow: Workflow = {
                ...query.data,
                itemSplitMappingConfig: updatedConfigs,
            };

            // save to backend
            await dialogManager.showLoadingWhile(saveMutation(updatedWorkflow).unwrap());
        },
        [dialogManager, query.data, saveMutation],
    );

    const cloneConfig = useCallback(
        async (config: WorkflowItemSplitConfig) => {
            if (!query.data) {
                throw new Error('Workflow not loaded');
            }

            const currentConfigs: WorkflowItemSplitConfig[] =
                query.data?.itemSplitMappingConfig ?? [];

            // clone the config - generate a new id so it's not a duplicate
            const clonedConfig = { ...config, id: generateShortUuid() };
            const updatedConfigs = [...currentConfigs, clonedConfig];

            // copy the workflow and update the itemSplitMappingConfig
            const updatedWorkflow: Workflow = {
                ...query.data,
                itemSplitMappingConfig: updatedConfigs,
            };

            // save to backend
            await dialogManager.showLoadingWhile(saveMutation(updatedWorkflow).unwrap());
        },
        [dialogManager, query.data, saveMutation],
    );

    const deleteConfig = useCallback(
        async (config: WorkflowItemSplitConfig) => {
            if (!query.data) {
                throw new Error('Workflow not loaded');
            }

            const confirm = await dialogManager.confirm({
                message: `Are you sure you want to delete this item split config?`,
                acceptLabel: 'Yes, delete now',
                acceptButtonType: 'Danger',
            });

            if (confirm) {
                const currentConfigs: WorkflowItemSplitConfig[] =
                    query.data?.itemSplitMappingConfig ?? [];

                const updatedConfigs = currentConfigs.filter(c => c.id !== config.id);

                // copy the workflow and update the itemSplitMappingConfig
                const updatedWorkflow: Workflow = {
                    ...query.data,
                    itemSplitMappingConfig: updatedConfigs,
                };

                // save to backend
                await dialogManager.showLoadingWhile(saveMutation(updatedWorkflow).unwrap());
            }
        },
        [dialogManager, query.data, saveMutation],
    );

    return (
        <div>
            {query.isLoading ? (
                <MyLinearProgress />
            ) : query.isError ? (
                query.isError && <ErrorContent />
            ) : (
                <>
                    <PageHeader
                        title="Workflow Item Split Breakdown"
                        titleContext={query.data?.name}
                    >
                        <MyButton
                            label="Add split config"
                            IconLeft={Icons.Plus}
                            buttonType="Accent"
                            onClick={addConfig}
                        />
                    </PageHeader>

                    <WorkflowItemSplitTable
                        data={query.data?.itemSplitMappingConfig ?? []}
                        highlightedRowId={detailConfig?.id}
                        onRowClick={setDetailConfig}
                        onClone={cloneConfig}
                        onDelete={deleteConfig}
                    />

                    {detailConfig && (
                        <WorkflowItemSplitEditModal
                            model={detailConfig}
                            onSave={saveConfig}
                            close={() => setDetailConfig(undefined)}
                        />
                    )}
                </>
            )}
        </div>
    );
}
