import StocktakeCreateModal from 'features/stocktake/components/StocktakeCreateModal/StocktakeCreateModal';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { usePageTitle } from 'utils/locationHelpers';

export default function StocktakeCreatePage() {
    usePageTitle('New');
    const navigate = useNavigate();

    return <StocktakeCreateModal close={() => navigate(`/stocktake`)} />;
}
