import { rest } from 'msw';

import Env from 'config/Env';
import StepCanTransition from '../workOrders/enums/StepCanTransition';
import WorkItemStepStatus from '../workOrders/enums/WorkItemStepStatus';
import {
    fakeWorkstationStepDetails,
    fakeWorkstationSteps,
    fakeWorkstations,
    generateWorkItemStepHistory,
} from './faker/WorkstationsFaker';
import { WorkstationStepDetail } from './models/WorkstationStepDetail';

const BASE_URL = `${Env.API_BASE_URL}`;

export const workstationsHandlers = [
    rest.post(`${BASE_URL}/workstations`, (req, res, ctx) => {
        return res(
            ctx.delay(500),
            ctx.status(200),
            ctx.json({
                data: fakeWorkstations,
            }),
        );
    }),

    rest.get(`${BASE_URL}/workstations/:stationId/workordersteps/ready`, (req, res, ctx) => {
        const steps = fakeWorkstationSteps.filter(
            s =>
                s.context.canTransition === StepCanTransition.True &&
                [
                    WorkItemStepStatus.Unstarted,
                    WorkItemStepStatus.InProgress,
                    WorkItemStepStatus.OnHold,
                ].includes(s.status),
        );
        return res(ctx.delay(500), ctx.status(200), ctx.json(steps));
    }),

    rest.get(`${BASE_URL}/workstations/:stationId/workordersteps/notready`, (req, res, ctx) => {
        const steps = fakeWorkstationSteps.filter(
            s =>
                s.context.canTransition === StepCanTransition.FalseBlockingItems ||
                s.context.canTransition === StepCanTransition.FalseBlockingSteps,
        );
        return res(ctx.delay(500), ctx.status(200), ctx.json(steps));
    }),

    rest.get(`${BASE_URL}/workstations/:stationId/workordersteps/completed`, (req, res, ctx) => {
        const steps = fakeWorkstationSteps.filter(s =>
            [WorkItemStepStatus.Completed, WorkItemStepStatus.Skipped].includes(
                s.status as WorkItemStepStatus,
            ),
        );
        return res(ctx.delay(500), ctx.status(200), ctx.json(steps));
    }),

    rest.get(`${BASE_URL}/workstations/:stationId/workordersteps/:id`, (req, res, ctx) => {
        let detail = fakeWorkstationStepDetails.find(d => d.id === req.params.id);

        if (detail && detail.context.canTransition === StepCanTransition.True) {
            // determine canTransition based on other step statuses
            const steps = fakeWorkstationSteps;
            const hasOtherStepsInProgress = steps.some(
                s =>
                    s.status === WorkItemStepStatus.InProgress &&
                    s.context.workstationLatest?.id === req.params.stationId &&
                    s.id !== req.params.id,
            );

            if (hasOtherStepsInProgress) {
                // create a copy and update canTransition
                detail = JSON.parse(JSON.stringify(detail)) as WorkstationStepDetail;
                detail.context.canTransition = StepCanTransition.FalseAnotherInProgress;
            }
        }

        return res(ctx.delay(500), ctx.status(detail ? 200 : 400), ctx.json(detail));
    }),

    rest.post(`${BASE_URL}/workstations/:stationId/workordersteps/query`, (req, res, ctx) => {
        const steps = fakeWorkstationSteps.filter(
            d => d.context.workOrderItem.tuid === (req.body as any).workOrderItemTuid,
        );
        return res(ctx.delay(500), ctx.status(200), ctx.json(steps));
    }),

    rest.put(
        `${BASE_URL}/workstations/:stationId/workordersteps/:id/transition`,
        (req, res, ctx) => {
            const body = req.body as {
                from: WorkItemStepStatus;
                to: WorkItemStepStatus;
            };

            const summary = fakeWorkstationSteps.find(d => d.id === req.params.id);
            if (summary) {
                summary.status = body.to;
                summary.context.workstationLatest = {
                    id: req.params.stationId as string,
                    name: 'Workstation',
                    allowMultipleInProgress: false,
                };
            }

            const detail = fakeWorkstationStepDetails.find(d => d.id === req.params.id);
            if (detail) {
                detail.status = body.to;
            }

            return res(
                ctx.delay(500),
                ctx.status(detail ? 200 : 400),
                ctx.json({
                    message: detail ? 'OK' : 'ERROR',
                }),
            );
        },
    ),

    rest.get(`${BASE_URL}/workorderitemstep/:stepId/history`, (req, res, ctx) => {
        const result = [generateWorkItemStepHistory()];
        return res(ctx.delay(500), ctx.status(200), ctx.json(result));
    }),
];
