import { WorkOrderItemAdditionalActionSchema } from 'features/workstations/models/WorkOrderItemAdditionalAction';
import z from 'zod';

export const WorkflowStepInstanceEntitySchema = z.object({
    instanceId: z.string(),
    workflowStepId: z.string(),
    showDefaultActions: z.boolean().default(false),
    additionalActions: z.array(WorkOrderItemAdditionalActionSchema).optional(),
});

export type WorkflowStepInstanceEntity = z.infer<typeof WorkflowStepInstanceEntitySchema>;
