import z from 'zod';
import { StocktakeSchema } from './Stocktake';
import { UserDefinedFieldSchema } from 'models/UserDefinedField';

export const StocktakeDetailResultSchema = z.object({
    data: StocktakeSchema,
    schemaExtensions: z.object({
        udfs: z.array(UserDefinedFieldSchema),
    }),
});

export type StocktakeDetailResult = z.infer<typeof StocktakeDetailResultSchema>;
