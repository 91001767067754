import Dialog from 'components/Dialogs/Dialog';
import MyButton from 'components/MyButton/MyButton';
import MyCheckboxTree, { MyCheckboxTreeNode } from 'components/MyCheckboxTree/MyCheckboxTree';
import PageHeader from 'components/PageHeader/PageHeader';
import PropertyContainer from 'components/PropertyContainer/PropertyContainer';
import PropertyEditNumber from 'components/PropertyEditNumber/PropertyEditNumber';
import { SalesItemLabelConfig } from 'features/sales/models/SalesItemLabelConfig';
import Icons from 'Icons';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import typescriptNaturalSort from 'typescript-natural-sort';
import './LabelPrintDialog.scss';

export type LabelPrintDialogResult = {
    confirmed: boolean;
    copies: number | null;
    labelIds: number[];
};

enum WIZARD_STEP {
    SELECT_LABELS,
    SPECIFY_COPIES,
}

export default function LabelPrintDialog({
    config,
    close,
}: {
    config: SalesItemLabelConfig[];
    close?: (result: LabelPrintDialogResult) => void;
}) {
    const [step, setStep] = useState(WIZARD_STEP.SELECT_LABELS);
    const [copies, _setCopies] = useState<number | undefined>();
    const [copiesDisabled, setCopiesDisabled] = useState(true);

    const setCopies = useCallback(
        // Wrapper for _setCopies that disallows updating the value when the config disallows editing values
        (quantity: number | undefined) => {
            if (!copiesDisabled) {
                _setCopies(quantity);
            }
        },
        [copiesDisabled],
    );

    // selectedIds is all IDs including the parent level page sizes
    const [selectedIds, setSelectedIds] = useState<string[]>([]);
    // selectedLabelIds is only the ids of the selected labels
    const selectedLabelIds = useMemo(
        () =>
            selectedIds
                .filter(id => parseInt(id, 10).toString() === id)
                .map(id => parseInt(id, 10)) ?? [],
        [selectedIds],
    );

    const selectedConfigs = useMemo(
        () =>
            config
                .filter(c => selectedLabelIds.includes(c.id))
                .sort((a, b) => typescriptNaturalSort(a.name, b.name)),
        [config, selectedLabelIds],
    );

    // When the user selects new labels, update the values for the next page of the wizard
    useEffect(() => {
        // If any config is not overridable, disallow override for all configs
        setCopiesDisabled(selectedConfigs.some(c => !c.copies_user_overridable));
        // Use the highest copies as the quantity of labels to print
        _setCopies(selectedConfigs.map(c => c.copies).reduce((max, val) => Math.max(max, val), 1));
    }, [config, selectedConfigs, selectedIds, selectedLabelIds]);

    const labelNodes = useMemo(() => {
        const nodes: MyCheckboxTreeNode[] = [];

        config.forEach(c => {
            const roundedWidth = Math.floor(c.width_in_units * 100) / 100;
            const roundedHeight = Math.floor(c.height_in_units * 100) / 100;
            const widthHeightUnitLabel = `${roundedWidth} x ${roundedHeight}${c.units}`;
            const childNode = {
                id: c.id.toString(),
                label: c.name,
            };
            let parentNode = nodes.find(n => n.label === widthHeightUnitLabel);
            if (!parentNode) {
                parentNode = {
                    label: widthHeightUnitLabel,
                    id: widthHeightUnitLabel,
                    nodes: [],
                };
                nodes.push(parentNode);
            }
            parentNode.nodes?.push(childNode);
        });
        // Sort child and then parent nodes by label text.
        nodes.forEach(n => n.nodes?.sort((a, b) => typescriptNaturalSort(a.label, b.label)));
        nodes.sort((a, b) => typescriptNaturalSort(a.label, b.label));

        // Select all nodes by default
        setSelectedIds([...nodes.map(n => n.id), ...config.map(c => c.id.toString())]);

        return nodes;
    }, [config]);

    const onCancel = () => {
        close?.({
            confirmed: false,
            copies: null,
            labelIds: [],
        });
    };

    const onSubmit = () => {
        close?.({
            confirmed: true,
            copies: copies ?? null,
            labelIds: selectedLabelIds,
        });
    };

    return (
        <Dialog
            className="LabelPrintDialog"
            close={onCancel}
        >
            <PageHeader
                className="LabelPrintDialog__PageHeader"
                title="Print labels"
                subtitle={
                    step === WIZARD_STEP.SPECIFY_COPIES
                        ? 'Please specify number of copies'
                        : 'Select any labels you want to print'
                }
            />

            {step === WIZARD_STEP.SELECT_LABELS && (
                <>
                    <div className="LabelPrintDialog__InputWrapper">
                        <MyCheckboxTree
                            nodes={labelNodes}
                            selectedIds={selectedIds}
                            initialExpandAll={true}
                            onChange={ids => {
                                setSelectedIds(ids);
                            }}
                        />
                    </div>

                    <div className="LabelPrintDialog__Buttons">
                        <MyButton
                            label="Cancel"
                            buttonType="Hollow"
                            onClick={onCancel}
                        />
                        <MyButton
                            className="LabelPrintDialog__Buttons__Save"
                            label="Next"
                            buttonType="Primary"
                            onClick={() => setStep(WIZARD_STEP.SPECIFY_COPIES)}
                            disabled={!selectedLabelIds.length}
                        />
                    </div>
                </>
            )}
            {step === WIZARD_STEP.SPECIFY_COPIES && (
                <>
                    <PropertyContainer className="LabelPrintDialog__InputWrapper">
                        <PropertyEditNumber
                            label={
                                copiesDisabled ? 'Copies specified in config' : 'Number of copies'
                            }
                            value={copies}
                            allowBlank={true}
                            withButtons={!copiesDisabled}
                            min={1}
                            onChange={setCopies}
                            disabled={copiesDisabled}
                        />
                    </PropertyContainer>
                    <div className="LabelPrintDialog__Buttons specify-copies">
                        <MyButton
                            className="LabelPrintDialog__Buttons__Back"
                            label="Back"
                            IconLeft={Icons.ChevronLeft}
                            buttonType="Nude"
                            onClick={() => setStep(WIZARD_STEP.SELECT_LABELS)}
                        />
                        <MyButton
                            className="LabelPrintDialog__Buttons__Save"
                            label="Print"
                            buttonType="Accent"
                            onClick={onSubmit}
                        />
                    </div>
                </>
            )}
        </Dialog>
    );
}
