import useUrlQueryState from 'hooks/useUrlQueryState';
import { useCallback, useMemo } from 'react';

/** Access to Workstation Step Filters which are controled by url query params */
export default function useStepFilters() {
    const [filterQuery, setFilterQuery] = useUrlQueryState('filter');

    /** Reconstruct the filter obj from the url params */
    const filterObj: StepFilterStructure = useMemo(() => {
        let obj = {} as StepFilterStructure;
        if (filterQuery) {
            try {
                obj = JSON.parse(filterQuery);
            } catch {
                // invalid json clear param and use default val
                setFilterQuery('', { replace: true });
            }
        }
        return obj;
    }, [filterQuery, setFilterQuery]);

    /** Compute an array of the active filters based on the current filterObj */
    const activeFilters = useMemo(() => {
        const result = [] as ActiveStepFilter[];

        if (filterObj.tuid) {
            result.push({
                key: 'tuid',
                displayName: 'Item',
                value: filterObj.tuid,
            });
        }
        if (filterObj.ref) {
            result.push({
                key: 'ref',
                displayName: 'Ref',
                value: filterObj.ref,
            });
        }
        if (filterObj.step) {
            result.push({
                key: 'step',
                displayName: 'Step',
                value: filterObj.step,
            });
        }
        if (filterObj.summaryFields) {
            filterObj.summaryFields.forEach(field => {
                result.push({
                    key: `summaryField__${field.key}`,
                    displayName: field.displayName ?? field.key,
                    value: `${field.value ?? ''}`,
                });
            });
        }

        return result;
    }, [filterObj]);

    /** Apply a filter to filterObj and update the url query  */
    const applyFilter = (key: keyof StepFilterStructure, value: any) => {
        const newObj = JSON.parse(JSON.stringify(filterObj)) as StepFilterStructure;

        if (key === 'summaryFields') {
            if (!newObj.summaryFields) {
                newObj.summaryFields = [value];
            } else if (!newObj.summaryFields.some(l => l.key === value.key)) {
                newObj.summaryFields.push(value);
            }
        } else {
            newObj[key] = value;
        }

        setFilterQuery(JSON.stringify(newObj), { replace: true });
    };

    /** Remove filters from filterObj and update the url query */
    const removeFilter = useCallback(
        (f: ActiveStepFilter) => {
            // create a copy of filterobj
            const newFilterObj: StepFilterStructure = JSON.parse(JSON.stringify(filterObj));

            // remove the filter
            if (f.key.startsWith('summaryField__')) {
                if (newFilterObj.summaryFields) {
                    // remove the matching label
                    newFilterObj.summaryFields = newFilterObj.summaryFields.filter(
                        l => `summaryField__${l.key}` !== f.key,
                    );
                    if (!newFilterObj.summaryFields.length) {
                        delete newFilterObj.summaryFields;
                    }
                }
            } else {
                const k = f.key as keyof StepFilterStructure;
                delete newFilterObj[k];
            }

            if (!Object.keys(newFilterObj).length) {
                setFilterQuery('', { replace: true });
            } else {
                setFilterQuery(JSON.stringify(newFilterObj), { replace: true });
            }
        },
        [filterObj, setFilterQuery],
    );

    return {
        filterObj,
        activeFilters,
        applyFilter,
        removeFilter,
    };
}

type ActiveStepFilter = {
    key: string;
    displayName: string;
    value: string;
};

type StepFilterStructure = {
    tuid?: string;
    step?: string;
    ref?: string;
    summaryFields?: {
        key: string;
        displayName: string | undefined;
        value: string | boolean | number | null;
    }[];
};
