import createDisplayEnum, { EnumDisplayLabels } from 'utils/createDisplayEnum';

enum InventoryMovementBatchType {
    StockIn = 'STOCKIN',
    StockOut = 'STOCKOUT',
    Transfer = 'TRANSFER',
    Adjustment = 'ADJUSTMENT',
    WriteOff = 'WRITEOFF',
}
export default InventoryMovementBatchType;

const labels: EnumDisplayLabels<typeof InventoryMovementBatchType> = {
    StockIn: 'Stock in',
    StockOut: 'Stock out',
    Transfer: 'Transfer',
    Adjustment: 'Adjustment',
    WriteOff: 'Write off',
};

export const InventoryMovementBatchTypeDisplay = createDisplayEnum(
    InventoryMovementBatchType,
    labels,
);
