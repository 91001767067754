import z from 'zod';

export const SalesOrderLineStatusSchema = z.object({
    id: z.number(),
    name: z.string(),
    backgroundColor: z.string(),
    textColor: z.string(),
    isActive: z.boolean(),
    sortOrder: z.number(),
});

export type SalesOrderLineStatus = z.infer<typeof SalesOrderLineStatusSchema>;

/** Deprecated - old schema for status entries uses snake-case
 * Transform these when we see them so they match the new schema
 */
export const SalesOrderLineStatusOldSchema = z
    .object({
        id: z.number(),
        name: z.string(),
        background_color: z.string(),
        text_color: z.string(),
        is_active: z.number().transform(val => Boolean(val)),
        sort_order: z.number(),
    })
    .transform<SalesOrderLineStatus>(statusOld => ({
        id: statusOld.id,
        name: statusOld.name,
        backgroundColor: statusOld.background_color,
        textColor: statusOld.text_color,
        isActive: statusOld.is_active,
        sortOrder: statusOld.sort_order,
    }));
