import { faker } from '@faker-js/faker';
import LocationType from 'features/inventory/enums/LocationType';
import { fakeLocations } from 'features/inventory/faker/InventoryFaker';
import { DateTime } from 'luxon';
import StocktakeCountStatus from '../enums/StocktakeCountStatus';
import StocktakeStatus from '../enums/StocktakeStatus';
import StocktakeTargetType from '../enums/StocktakeTargetType';
import StocktakeType from '../enums/StocktakeType';
import { CountSheet } from '../models/CountSheet';
import { CountSheetInventory } from '../models/CountSheetInventory';
import { CountSheetSummary } from '../models/CountSheetSummary';
import { Stocktake } from '../models/Stocktake';

faker.seed(42);
function generateFakeStocktake(): Stocktake {
    const fakeLocation = faker.helpers.arrayElement(
        fakeLocations.filter(l => l.locationType === LocationType.Warehouse),
    );
    return {
        id: faker.string.uuid(),
        notes: faker.word.words({ count: { min: 0, max: 20 } }),
        number: `ST${faker.helpers
            .rangeToNumber({ min: 1, max: 9999 })
            .toString()
            .padStart(10, '0')}`,
        parentLocationId: fakeLocation.parentLocationId ?? fakeLocation.id,
        parentLocationName: fakeLocation.parentLocationName ?? fakeLocation.name,
        stocktakeType: faker.helpers.enumValue(StocktakeType),
        status: faker.helpers.enumValue(StocktakeStatus),
        userDefinedFields: {},
        createdAt: DateTime.now()
            .minus({ seconds: faker.helpers.rangeToNumber({ min: 60, max: 604800 }) })
            .toISO() as string,
        isArchived: faker.datatype.boolean(),
        targets: [
            {
                targetId: fakeLocation.id,
                targetType: StocktakeTargetType.Location,
            },
            {
                targetId: fakeLocation.id,
                targetType: StocktakeTargetType.Location,
            },
        ],
    };
}

function generateFakeCountSheetSummary(stocktakeId: string): CountSheetSummary {
    return {
        id: faker.string.uuid(),
        stocktakeId,
        key: `STC${faker.helpers
            .rangeToNumber({ min: 1, max: 9999 })
            .toString()
            .padStart(10, '0')}`,
        locationId: faker.string.uuid(),
        locationName: `Bay ${faker.number.int({ min: 1, max: 999 })}`,
        countStatus: faker.helpers.enumValue(StocktakeCountStatus),
    };
}

function generateFakeCountSheet(summary: CountSheetSummary): CountSheet {
    return {
        id: summary.id,
        stocktakeId: summary.stocktakeId,
        key: summary.key,
        inventory: faker.helpers.multiple(generateFakeInventory, {
            count: faker.number.int({ min: 0, max: 30 }),
        }),
    };
}

function generateFakeInventory(): CountSheetInventory {
    return {
        countedQuantity: undefined,
        description: faker.commerce.productDescription(),
        expectedQuantity: faker.number.int({ min: 1, max: 99 }),
        partNumber: faker.string.alphanumeric(12),
        tenantInventoryId: faker.string.uuid(),
    };
}

export const fakeStocktakes = faker.helpers.multiple(generateFakeStocktake, { count: 10 });
export const fakeCountSheetSummaries = fakeStocktakes
    .map(s =>
        faker.helpers.multiple(() => generateFakeCountSheetSummary(s.id), {
            count: faker.number.int({ min: 1, max: 10 }),
        }),
    )
    .flat();
export const fakeCountSheets = fakeCountSheetSummaries.map(sum => generateFakeCountSheet(sum));
