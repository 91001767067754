import StepCanTransition from 'features/workOrders/enums/StepCanTransition';
import { WorkOrderItemEntitySchema } from 'features/workOrders/models/WorkOrderItemEntity';
import { WorkOrderItemStepEntitySchema } from 'features/workOrders/models/WorkOrderItemStepEntity';
import { WorkflowStepEntitySchema } from 'features/workOrders/models/WorkflowStepEntity';
import { WorkstationSchema } from 'features/workstations/models/Workstation';
import z from 'zod';

export const SalesOrderWorkItemStepSchema = WorkOrderItemStepEntitySchema.extend({
    context: z.object({
        canTransition: z.nativeEnum(StepCanTransition),
        workflowStep: WorkflowStepEntitySchema,
        workOrderItem: WorkOrderItemEntitySchema,
        // this is the station which last updated the status or null if Unstarted
        workstationLatest: WorkstationSchema.optional().nullable(),
    }),
});

export type SalesOrderWorkItemStep = z.infer<typeof SalesOrderWorkItemStepSchema>;
