import z from 'zod';
import { InventorySchema } from './Inventory';
import { UserDefinedFieldSchema } from 'models/UserDefinedField';

export const InventoryDetailResultSchema = z.object({
    data: InventorySchema,
    schemaExtensions: z.object({
        attributes: z.array(z.any()),
        udfs: z.array(UserDefinedFieldSchema),
    }),
});

export type InventoryDetailResult = z.infer<typeof InventoryDetailResultSchema>;
