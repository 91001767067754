import createDisplayEnum, { EnumDisplayLabels } from 'utils/createDisplayEnum';
import StepCanTransition from './StepCanTransition';
import WorkItemStepStatus from './WorkItemStepStatus';

/** Front-end only display state for WorkOrderItemSteps
 * Computed from WorkItemStepStatus and canTransition prop */
enum WorkItemStepUiState {
    ComingUp = 'COMINGUP',
    Ready = 'READY',
    InProgress = 'INPROGRESS',
    OnHold = 'ONHOLD',
    Skipped = 'SKIPPED',
    Completed = 'COMPLETED',
}
export default WorkItemStepUiState;

const labels: EnumDisplayLabels<typeof WorkItemStepUiState> = {
    ComingUp: 'Coming up',
    Ready: 'Ready to start',
    InProgress: 'In progress',
    OnHold: 'On hold',
    Skipped: 'Skipped',
    Completed: 'Completed',
};

export const WorkItemStepUiStateDisplay = createDisplayEnum(WorkItemStepUiState, labels);

export function computeWorkItemStepUiState(
    state: WorkItemStepStatus,
    canTransition: StepCanTransition,
) {
    switch (state) {
        case WorkItemStepStatus.Unstarted:
            return canTransition === StepCanTransition.FalseBlockingItems ||
                canTransition === StepCanTransition.FalseBlockingSteps
                ? WorkItemStepUiState.ComingUp
                : WorkItemStepUiState.Ready;
        case WorkItemStepStatus.InProgress:
            return WorkItemStepUiState.InProgress;
        case WorkItemStepStatus.OnHold:
            return WorkItemStepUiState.OnHold;
        case WorkItemStepStatus.Skipped:
        case WorkItemStepStatus.SkippedLocked:
            return WorkItemStepUiState.Skipped;
        case WorkItemStepStatus.Completed:
        case WorkItemStepStatus.CompletedLocked:
            return WorkItemStepUiState.Completed;
        default:
            throw new Error(`Unknown WorkItemStepStatus '${state}'`);
    }
}
