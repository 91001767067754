import { Tooltip } from '@mui/material';
import Icons from 'Icons';
import ErrorContent from 'components/ErrorContent/ErrorContent';
import MyButton from 'components/MyButton/MyButton';
import MyCalendarPicker from 'components/MyCalendarPicker/MyCalendarPicker';
import MyLinearProgress from 'components/MyLinearProgress/MyLinearProgress';
import PageHeader from 'components/PageHeader/PageHeader';
import DndContainer from 'components/ReactSmoothDnd/DndContainer';
import { Schedule } from 'features/schedule/models/Schedule';
import { ScheduledWorkOrder } from 'features/schedule/models/ScheduledWorkOrder';
import scheduleApi from 'features/schedule/schedule.api';
import { setLastMoveToSchedule } from 'features/schedule/schedule.slice';
import useUrlQueryState from 'hooks/useUrlQueryState';
import { DateTime } from 'luxon';
import React, { useEffect, useMemo, useState } from 'react';
import { DropResult } from 'smooth-dnd';
import { useAppDispatch } from 'store/hooks';
import coalesceClassNames from 'utils/coalesceClassNames';
import { dateIsPast } from 'utils/dateHelpers';
import { isNone } from 'utils/helpers';
import ScheduleWorkOrderRow from '../ScheduleWorkOrderRow/ScheduleWorkOrderRow';
import './ScheduleMain.scss';

const DATE_DEFAULT = DateTime.now().startOf('week');

export default function ScheduleMain() {
    const [dateParam, setDateParam] = useUrlQueryState('date', DATE_DEFAULT.toISODate() as string);
    const selectedDate = useMemo(() => DateTime.fromISO(dateParam), [dateParam]);

    const dateFrom = useMemo(() => selectedDate.startOf('week'), [selectedDate]);
    const dateTo = useMemo(() => dateFrom.plus({ days: 6 }), [dateFrom]);

    const dateFromStr = useMemo(() => dateFrom.toFormat('yyyy-MM-dd'), [dateFrom]);
    const dateToStr = useMemo(() => dateTo.toFormat('yyyy-MM-dd'), [dateTo]);

    const isDateThisWeek = useMemo(
        () => selectedDate.hasSame(DateTime.now(), 'week'),
        [selectedDate],
    );

    const [isMovingDates, setIsMovingDates] = useState(true);

    const dispatch = useAppDispatch();
    const [assignMutation] = scheduleApi.useScheduleAssignWorkOrdersMutation();

    const scheduleQuery = scheduleApi.useScheduleListWithWorkOrdersQuery(
        {
            dateFrom: dateFromStr,
            dateTo: dateToStr,
        },
        {
            refetchOnMountOrArgChange: true,
            refetchOnFocus: true,
        },
    );

    useEffect(() => {
        if (!scheduleQuery.isFetching) {
            setIsMovingDates(false);
        }
    }, [scheduleQuery.isFetching]);

    /** Move work orders to the given schedule
     * @param schedule the schedule to move items to
     * @param workOrders an array of WorkOrders to be moved
     * @param index optional param - the index to put the new items at. If not provided then the items will be scheduled last. */
    const handleAssignToSchedule = (
        schedule: Schedule,
        workOrders: ScheduledWorkOrder[],
        sortOrder?: number,
    ) => {
        // for concurrency - find the id of the item with the provided `sortOrder`
        const sortOrderWorkOrderId = isNone(sortOrder)
            ? undefined
            : schedule.context?.scheduleWorkOrders.find(w => w.sortOrder === (sortOrder as number))
                  ?.id;

        if (!isNone(sortOrder) && !sortOrderWorkOrderId) {
            throw new Error(`handleAssignToSchedule: Invalid sortOrder '${sortOrder}'`);
        }

        // optimistic update of data
        // this would normally be done inside the api slice
        // but because we need access to dateFrom and dateTo its easier here
        dispatch(
            scheduleApi.util.updateQueryData(
                'scheduleListWithWorkOrders',
                {
                    dateFrom: dateFromStr,
                    dateTo: dateToStr,
                },
                draft => {
                    // targetList will be undefined if it is on a different page to sourceList
                    const targetList = draft.find(s => s.id === schedule.id)?.context
                        ?.scheduleWorkOrders;

                    const finalIndex = !targetList
                        ? 0
                        : isNone(sortOrder)
                        ? targetList.length
                        : targetList.findIndex(w => w.sortOrder === sortOrder);

                    // loop through work orders
                    workOrders.forEach(workOrder => {
                        const sourceSchedule = draft.find(
                            s => !!s.context?.scheduleWorkOrders.find(w => w.id === workOrder.id),
                        );
                        if (sourceSchedule) {
                            // remove from source list
                            const sourceList = sourceSchedule?.context?.scheduleWorkOrders ?? [];
                            const sourceIndex = sourceList.findIndex(w => w.id === workOrder.id);
                            if (sourceIndex > -1) {
                                sourceList.splice(sourceIndex, 1);
                            }
                        }
                    });

                    // append to targetList - this only happens if target is on the same page as source
                    if (targetList) {
                        targetList.splice(finalIndex, 0, ...workOrders);
                    }
                },
            ),
        );

        assignMutation({
            scheduleId: schedule.id,
            scheduleDate: schedule.date,
            workOrders: workOrders.map(w => w.id),
            sortOrder,
            sortOrderWorkOrderId,
            position: isNone(sortOrder) ? undefined : 'ABOVE', // TODO BELOW?
        });

        dispatch(setLastMoveToSchedule(schedule));
    };

    const goToDate = (date: DateTime) => {
        setIsMovingDates(true);
        setDateParam(date.toISODate() as string);
    };

    const isLoading = isMovingDates && scheduleQuery.isFetching;

    return (
        <div className="ScheduleMain">
            <PageHeader
                className="ScheduleMain__PageHeader"
                title="Schedule"
                subtitle="Work orders scheduled by date"
            >
                <MyButton
                    buttonType="LinkButton"
                    label={isDateThisWeek ? 'This week' : 'Go to this week'}
                    disabled={isDateThisWeek}
                    IconLeft={isDateThisWeek ? Icons.Check : undefined}
                    onClick={() => goToDate(DateTime.now().startOf('week'))}
                />

                <MyCalendarPicker
                    className="ScheduleMain__CalendarPicker"
                    selectionMode="week"
                    value={selectedDate}
                    onChange={goToDate}
                />
            </PageHeader>

            <div className="ScheduleMain__DatesList">
                {scheduleQuery.currentData ? (
                    scheduleQuery.currentData.map((s, i) => (
                        <DateBlock
                            key={`${s.id}__${i}`}
                            schedule={s}
                            isLoading={isLoading}
                            onAssign={handleAssignToSchedule}
                        />
                    ))
                ) : isLoading ? (
                    <div className="ScheduleMain__DatesList__LoadingIndicator">
                        <MyLinearProgress delay={100} />
                    </div>
                ) : scheduleQuery.isError ? (
                    <ErrorContent className="ScheduleMain__DatesList__Error" />
                ) : null}
            </div>
        </div>
    );
}

function DateBlock({
    schedule,
    isLoading = false,
    onAssign,
}: {
    schedule: Schedule;
    isLoading?: boolean;
    onAssign: (schedule: Schedule, workOrders: ScheduledWorkOrder[], sortOrder?: number) => void;
}) {
    const [dateParam] = useUrlQueryState('date');
    const [highlightId] = useUrlQueryState('highlight');

    const schedDate = useMemo(() => DateTime.fromISO(schedule.date).toISODate(), [schedule.date]);
    const isDatePast = dateIsPast(DateTime.fromISO(schedule.date).endOf('day'));
    const isExpandedDefault = !isDatePast || (highlightId && dateParam === schedDate);
    const [isCollapsed, setIsCollapsed] = useState(!isExpandedDefault);

    const dt = useMemo(() => DateTime.fromISO(schedule.date), [schedule.date]);
    const empty = !schedule.context?.scheduleWorkOrders.length;
    const isToday = dt.hasSame(DateTime.now(), 'day');

    const handleDrop = (result: DropResult) => {
        if (!isNone(result.addedIndex)) {
            const i = result.addedIndex as number;
            const sortOrder = schedule.context?.scheduleWorkOrders[i]?.sortOrder ?? undefined;
            onAssign(schedule, [result.payload], sortOrder);
        }
    };

    /** Add up items for all work orders in schedule */
    const itemCount = useMemo(() => {
        const orders = schedule.context?.scheduleWorkOrders ?? [];
        const total = orders.reduce((sum, wo) => sum + (wo.itemCount ?? 0), 0);
        return total;
    }, [schedule.context?.scheduleWorkOrders]);

    return (
        <div
            className={coalesceClassNames(
                'ScheduleMain__DateBlock',
                isLoading && 'ScheduleMain__DateBlock--Loading',
                isCollapsed && 'ScheduleMain__DateBlock--Collapsed',
            )}
        >
            <div className="ScheduleMain__DateBlock__Header">
                {isToday && (
                    <span className="ScheduleMain__DateBlock__Header__TodayBadge">Today</span>
                )}
                <h2 className="ScheduleMain__DateBlock__Header__Title">
                    <span className="weekday">{dt.weekdayLong}</span>{' '}
                    <strong>{dt.toFormat('dd MMM')}</strong> {dt.toFormat('yyyy')}
                </h2>
                <div className="ScheduleMain__DateBlock__Header__Counts">
                    <strong>{schedule.context?.scheduleWorkOrders.length}</strong>{' '}
                    {schedule.context?.scheduleWorkOrders.length === 1 ? 'order' : 'orders'} (
                    {itemCount} {itemCount === 1 ? 'item' : 'items'})
                </div>
                <Tooltip
                    title="Expand/collapse"
                    arrow
                >
                    <span>
                        <MyButton
                            className="ScheduleMain__DateBlock__Header__ExpandButton"
                            IconRight={isCollapsed ? Icons.CaretDown : Icons.CaretUp}
                            buttonType="Nude"
                            onClick={() => setIsCollapsed(!isCollapsed)}
                        />
                    </span>
                </Tooltip>
            </div>
            <div
                className={coalesceClassNames(
                    'ScheduleMain__DateBlock__RowContainer',
                    empty && 'ScheduleMain__DateBlock__RowContainer--Empty',
                )}
            >
                {empty && (
                    <div className="ScheduleMain__DateBlock__RowContainer__EmptyMessage">
                        Nothing scheduled
                    </div>
                )}
                <DndContainer
                    groupName="ScheduleWorkItems"
                    dragHandleSelector={isLoading ? 'none' : '.ScheduleWorkOrderRow__DragHandle'}
                    getChildPayload={i => schedule.context?.scheduleWorkOrders[i]}
                    onDrop={handleDrop}
                >
                    {!empty &&
                        schedule.context?.scheduleWorkOrders.map(wo => (
                            <ScheduleWorkOrderRow
                                key={wo.id}
                                workOrder={wo}
                                schedule={schedule}
                                isLoading={isLoading}
                                onAssign={onAssign}
                            />
                        ))}
                </DndContainer>
            </div>
        </div>
    );
}
