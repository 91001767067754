import { ScheduleEntitySchema } from 'features/schedule/models/ScheduleEntity';
import { ScheduleMetaEntitySchema } from 'features/schedule/models/ScheduleMetaEntity';
import { WorkOrderEntitySchema } from 'features/workOrders/models/WorkOrderEntity';
import { WorkOrderItemStepEntitySchema } from 'features/workOrders/models/WorkOrderItemStepEntity';
import { WorkflowStepEntitySchema } from 'features/workOrders/models/WorkflowStepEntity';
import { WorkstationWorkItemSchema } from 'features/workOrders/models/WorkstationWorkItem';
import z from 'zod';
import StepCanTransition from '../../workOrders/enums/StepCanTransition';
import { WorkstationSchema } from './Workstation';

export const WorkstationStepSummarySchema = WorkOrderItemStepEntitySchema.extend({
    context: z.object({
        canTransition: z.nativeEnum(StepCanTransition),
        salesOrder: z.object({
            id: z.string(),
            tuid: z.string(),
        }),
        schedule: ScheduleEntitySchema,
        scheduleMeta: ScheduleMetaEntitySchema,
        workflowStep: WorkflowStepEntitySchema,
        workOrder: WorkOrderEntitySchema,
        workOrderItem: WorkstationWorkItemSchema,
        // this is the station which last updated the status or null if Unstarted
        workstationLatest: WorkstationSchema.optional().nullable(),
    }),
});

export type WorkStationStepSummary = z.infer<typeof WorkstationStepSummarySchema>;
