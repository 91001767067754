import MyAutocompleteInput, {
    MyAutocompleteInputOption,
} from 'components/MyAutocompleteInput/MyAutocompleteInput';
import PropertyDisplay from 'components/PropertyDisplay/PropertyDisplay';
import PropertyEdit from 'components/PropertyEdit/PropertyEdit';
import React, { useRef } from 'react';
import { generateShortUuid } from 'utils/helpers';
import './PropertyEditAutocomplete.scss';

export type PropertyEditAutocompleteProps = {
    value: any;
    label: string;
    hint?: string;
    onChange?: (val: any, label?: string) => void;
    onChangeMultiple?: (val: MyAutocompleteInputOption[]) => void;
    readonly?: boolean;
    disabled?: boolean;
    options?: { label: string; value: string }[];
    allowMultiple?: boolean;
    validationRequired?: string | boolean;
    validationCustom?: string | false;
};

export default function PropertyEditAutocomplete({
    value,
    label,
    hint,
    onChange,
    onChangeMultiple,
    readonly = false,
    disabled = false,
    options,
    allowMultiple,
    validationRequired,
    validationCustom,
}: PropertyEditAutocompleteProps) {
    const inputId = useRef(generateShortUuid()).current;

    const validationRequiredWithLabel =
        validationRequired === true ? `${label} is required` : validationRequired;

    if (readonly) {
        return (
            <PropertyDisplay
                label={label}
                hint={hint}
                value={value}
                options={options}
            />
        );
    }

    return (
        <PropertyEdit
            className="PropertyEditAutocomplete"
            label={label}
            hint={hint}
            inputId={inputId}
        >
            <MyAutocompleteInput
                className="PropertyEditAutocomplete__Input"
                id={inputId}
                value={value}
                options={options}
                disabled={disabled}
                multiple={allowMultiple}
                onChange={onChange}
                onChangeMultiple={onChangeMultiple}
                validationRequired={validationRequiredWithLabel}
                validationCustom={validationCustom}
            />
        </PropertyEdit>
    );
}
