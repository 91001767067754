import z from 'zod';

export const SalesOrderWorksheetSchema = z.object({
    name: z.string(),
    path: z.string().optional(),
    url: z.string().optional(),
    errorMessage: z.string().optional(),
});

export type SalesOrderWorksheet = z.infer<typeof SalesOrderWorksheetSchema>;
