import { dateTimeOldSystem } from 'utils/dateSchemas';
import z from 'zod';

export const DocumentUploadResultDocumentSchema = z.object({
    id: z.number(),
    brand_id: z.preprocess(val => {
        // api returns brand_id as a string but it should be a number
        const num = val ? parseInt(String(val), 10) : null;
        return Number.isNaN(num) ? null : num;
    }, z.number().nullable()),
    created_at: dateTimeOldSystem({ isUTC: true }),
    direct_upload: z.string(),
    extension: z.string(),
    filename: z.string(),
    mime_type: z.string(),
    name: z.string(),
    size: z.number(),
    type: z.string(),
    updated_at: dateTimeOldSystem({ isUTC: true }),
    url: z.string(),
});

export const DocumentUploadResultSchema = z.object({
    data: z.object({
        uuid: z.string(),
        document: DocumentUploadResultDocumentSchema,
    }),
});

export type DocumentUploadResultDocument = z.infer<typeof DocumentUploadResultDocumentSchema>;
export type DocumentUploadResult = z.infer<typeof DocumentUploadResultSchema>;
