import WorkstationMain from 'features/workstations/components/WorkstationMain/WorkstationMain';
import workstationsApi from 'features/workstations/workstations.api';
import {
    selectCurrentWorkstation,
    setCurrentWorkstation,
} from 'features/workstations/workstations.slice';
import React, { useEffect } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export default function CurrentWorkstationPage() {
    // Instead of calling usePageTitle here, it is called within WorkstationMain
    const { stationId } = useParams();
    const workstationsQuery = workstationsApi.useGetWorkstationsQuery();

    const currentWorkstation = useAppSelector(selectCurrentWorkstation);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    // Set currentWorkstation when landing on this page
    // as long as station id is valid
    useEffect(() => {
        if (stationId !== currentWorkstation?.id) {
            if (workstationsQuery.data) {
                const station = workstationsQuery.data?.find(w => w.id === stationId);
                if (station) {
                    dispatch(setCurrentWorkstation(station));
                } else {
                    navigate('/workstations');
                }
            }
        }
    }, [dispatch, stationId, currentWorkstation, workstationsQuery.data, navigate]);

    return (
        <>
            {currentWorkstation?.id === stationId && <WorkstationMain />}
            <Outlet />
        </>
    );
}
