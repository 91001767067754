import { useMemo } from 'react';
import { InventoryLocationPath } from '../models/InventoryLocationsResult';
import { Location } from '../models/Location';

export function useInventoryLocationPath(locationId: string, locations: Location[]) {
    const model = useMemo(
        () => (locationId ? locations.find(l => l.id === locationId) : undefined),
        [locations, locationId],
    );

    const path: InventoryLocationPath[] = useMemo(() => {
        const result: Location[] = [];

        let currentNode = model;
        if (!currentNode?.parentLocationId) {
            // this is a parent node, dont show a path
            return result;
        }
        if (locationId) {
            while (currentNode) {
                if (currentNode.id !== model?.id) {
                    result.unshift(currentNode);
                }

                // eslint-disable-next-line @typescript-eslint/no-loop-func
                currentNode = locations.find(l => l.id === currentNode?.parentLocationId);
            }
        }

        return result;
    }, [model, locationId, locations]);
    return { model, path };
}
