import DataTable, { ColumnBuilder } from 'components/DataTable/DataTable';
import DataTableFilters, {
    DataTableFilterConfig,
} from 'components/DataTableFilters/DataTableFilters';
import inventoryApi from 'features/inventory/inventory.api';
import { LocationInventory } from 'features/inventory/models/LocationInventory';
import React, { useState } from 'react';
import './LocationInventoryTable.scss';

const INV_COLUMN_DEFS = ColumnBuilder<LocationInventory>()
    .column({
        label: 'Part No',
        key: 'partNumber',
        getValue: item => item.context.inventory.partNumber,
        isSortable: true,
    })
    .column({
        label: 'Description',
        key: 'description',
        getValue: item => item.context.inventory.description,
        isSortable: true,
    })
    .column({
        label: 'On Hand',
        key: 'quantityOnHand',
        getValue: item => item.quantityOnHand,
        isSortable: true,
        align: 'center',
    })
    .column({
        label: 'Allocated',
        key: 'quantityAllocated',
        getValue: item => item.quantityAllocated,
        isSortable: true,
        align: 'center',
    })
    .build();

const INV_FILTERS: DataTableFilterConfig[] = [
    {
        label: 'Search',
        type: 'search',
        defaultValue: '',
        fields: ['partNumber', 'description'],
    },
];

export default function LocationInventoryTable({ locationId }: { locationId?: string }) {
    const query = inventoryApi.useLocationInventoryQuery(locationId || '', {
        skip: !locationId,
    });

    const [filteredData, setFilteredData] = useState<LocationInventory[]>();

    return (
        <div className="LocationInventoryTable">
            {(query.data?.length ?? 0) > 0 && (
                <div className="LocationInventoryTable__FilterBar">
                    <DataTableFilters
                        data={query.data}
                        filters={INV_FILTERS}
                        onChange={setFilteredData}
                    />
                </div>
            )}
            <DataTable
                className="LocationInventoryTable__DataTable"
                isLoading={query.isLoading}
                isError={query.isError}
                data={filteredData}
                onRefresh={query.refetch}
                isRefreshing={query.isFetching}
                rowLinkTo={item => `/locations/${locationId}/inventory/${item.tenantInventoryId}`}
                zebra={true}
                useStickyHeader={true}
                useFrontEndSorting={true}
                columns={INV_COLUMN_DEFS}
                emptyState="No inventory found"
            />
        </div>
    );
}
