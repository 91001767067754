import DocumentDetailModal from 'features/documents/components/DocumentDetailModal/DocumentDetailModal';
import documentsApi from 'features/documents/documents.api';
import React from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { usePageTitle } from 'utils/locationHelpers';

export default function DocumentDetailPage() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const { documentId = '' } = useParams();
    const query = documentsApi.useDocumentListQuery();
    const [archiveMutation] = documentsApi.useDocumentArchiveMutation();

    const model = query.data?.find(d => `${d.id}` === documentId);
    usePageTitle(model?.name);

    return (
        <>
            <DocumentDetailModal
                model={model}
                isLoading={query.isFetching}
                isError={!!query.data && !model}
                showBrand={true}
                onArchive={archiveMutation}
                close={() => navigate(`..?${searchParams}`)}
            />
        </>
    );
}
