import { ShipmentStatusDisplay } from 'features/dispatch/enums/ShipmentStatus';
import { ShipmentEntity } from 'features/dispatch/models/ShipmentEntity';
import React from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import './ShipmentStatusBadge.scss';

export default function ShipmentStatusBadge({
    shipment,
    size = 'normal',
    className,
}: {
    shipment?: ShipmentEntity;
    size?: 'normal' | 'small' | 'tiny';
    className?: string;
}) {
    return (
        <div
            className={coalesceClassNames(
                'ShipmentStatusBadge',
                `ShipmentStatusBadge--${size}`,
                shipment?.status,
                className,
            )}
        >
            {shipment && ShipmentStatusDisplay.display(shipment.status)}
        </div>
    );
}
