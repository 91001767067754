import z from 'zod';

export const WorkOrderItemEntitySchema = z.object({
    id: z.string(),
    tuid: z.string(),
    workflowId: z.string(),
    workOrderId: z.string(),
    salesOrderLineId: z.string(),
    description: z.string(),
    salesOrderReference: z.string(),
    salesOrderId: z.string(),
    customerOrderReference: z.string(),
    customerOrderSidemark: z.string(),
    isCancelled: z.boolean().default(false),
    // Technically options and labels are included on the Entity
    // but they are omitted from most api results due to their size
    // For the FE models, they are included in the WorkOrderItemWithOptionsSchema
    // options: z.record(z.string(), z.any()),
    // labels: z.record(z.string(), z.any()),
});

export type WorkOrderItemEntity = z.infer<typeof WorkOrderItemEntitySchema>;
