import MyTextInput from 'components/MyTextInput/MyTextInput';
import React, { useEffect, useState } from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import { getDeepValue, isEmpty } from 'utils/helpers';
import {
    DataTableFilterBase,
    DataTableFilterPlugin,
    FilterCompProps,
} from '../DataTableFilterTypes';

export type TextFilterConfig = DataTableFilterBase & {
    type: 'text';
    field: string;
    exact?: boolean;
};

function TextFilter({ filter, onChange }: FilterCompProps<TextFilterConfig>) {
    const [value, setValue] = useState(filter.config.defaultValue || '');

    const handleInput = (val: string) => {
        setValue(val);
        filter.value = val;
        onChange?.(filter);
    };

    useEffect(() => {
        setValue(filter.value);
    }, [filter.value]);

    return (
        <div
            className={coalesceClassNames(
                'DataTableFilters__Field',
                'DataTableFilters__Field--TextFilter',
            )}
        >
            <MyTextInput
                label={filter.config.label}
                value={value}
                handleInput={handleInput}
                showClearButton={true}
            />
        </div>
    );
}

const plugin: DataTableFilterPlugin<TextFilterConfig> = {
    Component: TextFilter,
    applyFilter: (filter, value, item) => {
        const val = getDeepValue(item, filter.field);
        if (isEmpty(val)) {
            return false;
        }
        return filter.exact
            ? String(val).toLowerCase() === value.toLowerCase()
            : String(val).toLowerCase().indexOf(value.toLowerCase()) > -1;
    },
};
export default plugin;
