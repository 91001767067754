import Icons from 'Icons';
import MyCheckboxInput from 'components/MyCheckboxInput/MyCheckboxInput';
import PropertyDisplay from 'components/PropertyDisplay/PropertyDisplay';
import PropertyEdit from 'components/PropertyEdit/PropertyEdit';
import React, { useRef } from 'react';
import { generateShortUuid } from 'utils/helpers';
import './PropertyEditCheckbox.scss';

export type PropertyEditCheckboxProps = {
    checked?: boolean;
    label: string;
    hint?: string;
    onChange?: (val?: boolean) => void;
    readonly?: boolean;
    disabled?: boolean;
    validationRequired?: string | boolean;
    validationCustom?: string | false;
};

export default function PropertyEditCheckbox({
    checked,
    label,
    hint,
    onChange,
    readonly = false,
    disabled = false,
    validationRequired,
    validationCustom,
}: PropertyEditCheckboxProps) {
    const inputId = useRef(generateShortUuid()).current;

    const validationRequiredWithLabel =
        validationRequired === true ? `${label} is required` : validationRequired;

    if (readonly) {
        return (
            <PropertyDisplay
                label={label}
                hint={hint}
                value={
                    checked === true ? (
                        <Icons.Check className="PropertyEditCheckbox__DisplayCheck" />
                    ) : (
                        ''
                    )
                }
            />
        );
    }
    return (
        <PropertyEdit
            className="PropertyEditCheckbox"
            label={label}
            hint={hint}
            inputId={inputId}
        >
            <MyCheckboxInput
                className="PropertyEditCheckbox__Input"
                id={inputId}
                checked={checked}
                handleInput={onChange}
                disabled={disabled}
                validationRequired={validationRequiredWithLabel}
                validationCustom={validationCustom}
            />
        </PropertyEdit>
    );
}
