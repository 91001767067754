import Icons from 'Icons';
import MyButton, { MyButtonLink } from 'components/MyButton/MyButton';
import PageHeader from 'components/PageHeader/PageHeader';
import PackagesTable from 'features/dispatch/components/PackagesTable/PackagesTable';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { usePageTitle } from 'utils/locationHelpers';

export default function PackagesPage() {
    usePageTitle('Packages');
    return (
        <>
            <PageHeader title="Packages">
                <MyButton
                    label="New Package"
                    IconLeft={Icons.Plus}
                    buttonType="Accent"
                    href="create"
                    LinkComponent={MyButtonLink}
                />
            </PageHeader>
            <PackagesTable />
            <Outlet />
        </>
    );
}
