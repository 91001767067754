import createDisplayEnum, { EnumDisplayLabels } from 'utils/createDisplayEnum';

enum InventoryReceiptStatus {
    Draft = 'DRAFT',
    Processed = 'PROCESSED',
}
export default InventoryReceiptStatus;

const labels: EnumDisplayLabels<typeof InventoryReceiptStatus> = {
    Draft: 'Draft',
    Processed: 'Processed',
};

export const InventoryReceiptStatusDisplay = createDisplayEnum(InventoryReceiptStatus, labels);
