import z from 'zod';
import { UserDefinedFieldSchema } from 'models/UserDefinedField';
import { SupplierSchema } from './Supplier';

export const SupplierListResultSchema = z.object({
    data: z.array(SupplierSchema),
    schemaExtensions: z.object({
        udfs: z.array(UserDefinedFieldSchema).optional(),
    }),
});

export type SupplierListResult = z.infer<typeof SupplierListResultSchema>;
