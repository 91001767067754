import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const slice = createSlice({
    name: 'accounting',
    initialState,
    reducers: {},
    // extraReducers: builder => {

    // },
});

// export const {} = slice.actions;

export default slice.reducer;
