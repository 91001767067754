import MyTextInput from 'components/MyTextInput/MyTextInput';
import { CountSheetInventory } from 'features/stocktake/models/CountSheetInventory';
import React, { useEffect, useState } from 'react';
import { isEmpty } from 'utils/helpers';

export type StocktakeQuantityUpdateData = {
    tenantInventoryId: string;
    countedQuantity?: number;
};

export function StocktakeQuantityInput({
    item,
    allowBlank,
    disabled = false,
    onChange,
}: {
    item: CountSheetInventory;
    allowBlank: boolean;
    disabled?: boolean;
    onChange: (data: StocktakeQuantityUpdateData) => void;
}) {
    const defaultValue = allowBlank ? '' : '0';
    const [strVal, setStrVal] = useState<string>(item.countedQuantity?.toString() ?? defaultValue);

    /** Update str val if item changes (model update) */
    useEffect(() => {
        setStrVal(item.countedQuantity?.toString() ?? defaultValue);
    }, [defaultValue, item]);

    /** Dispatch an update to the editModel */
    const updateData = (qty?: number) => {
        onChange({
            tenantInventoryId: item.tenantInventoryId,
            countedQuantity: qty,
        });
    };

    /** When blurring the input, parse text input to int
     * and then call updateData or if input is invalid, then restore the previous value */
    const commitValue = () => {
        if (isEmpty(strVal) && allowBlank) {
            // if user entered empty string, save this as undefined
            updateData(undefined);
            return;
        }
        const num = parseInt(strVal, 10);
        if (Number.isNaN(num)) {
            // invalid value - restore previous val from model
            setStrVal(item.countedQuantity?.toString() ?? defaultValue);
        } else {
            // valid value, commit the change
            updateData(num);
            setStrVal(num.toString());
        }
    };

    return (
        <MyTextInput
            value={strVal}
            handleInput={setStrVal}
            onBlur={commitValue}
            type="number"
            min={0}
            disabled={disabled}
            fullWidth
        />
    );
}
