import createDisplayEnum, { EnumDisplayLabels } from 'utils/createDisplayEnum';

enum StocktakeStatus {
    Preparation = 'PREPARATION',
    InProgress = 'INPROGRESS',
    Complete = 'COMPLETE',
}
export default StocktakeStatus;

const labels: EnumDisplayLabels<typeof StocktakeStatus> = {
    Preparation: 'Preparation',
    InProgress: 'In Progress',
    Complete: 'Complete',
};

export const StocktakeStatusDisplay = createDisplayEnum(StocktakeStatus, labels);
