import UdfEdit from 'components/UdfEdit/UdfEdit';
import UserDefinedFieldEntityType from 'enums/UserDefinedFieldEntityType';
import { AttributeField } from 'models/AttributeField';
import { UserDefinedFieldFactory } from 'models/UserDefinedField';
import React, { useMemo } from 'react';

export default function AttributeEdit({
    attribute,
    value,
    readonly = false,
    disabled = false,
    onChange,
}: {
    attribute: AttributeField;
    value?: any;
    readonly?: boolean;
    disabled?: boolean;
    onChange: (val: any) => void;
}) {
    // Convert attribute to udf
    // they share almost all properties so UdfEdit can handle all the UI cases
    const udf = useMemo(
        () =>
            UserDefinedFieldFactory.create({
                ...attribute,
                entityType: UserDefinedFieldEntityType.Attribute,
            }),
        [attribute],
    );

    return (
        <UdfEdit
            udf={udf}
            value={value}
            onChange={onChange}
            disabled={disabled}
            hint="(group attribute)"
            // readonly={attribute.canBeOverridden ? readonly : true}
            readonly={readonly}
        />
    );
}
