import { WorkOrderItemDetailSchema } from 'features/workOrders/models/WorkOrderItemDetail';
import z from 'zod';
import { PackageEntitySchema } from './PackageEntity';
import { ShipmentEntitySchema } from './ShipmentEntity';

export const PackageSchema = PackageEntitySchema.extend({
    context: z.object({
        assignedWorkOrderItems: z.array(WorkOrderItemDetailSchema),
        shipment: ShipmentEntitySchema.optional(),
    }),
});

export type Package = z.infer<typeof PackageSchema>;
