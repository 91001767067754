import InventoryReceiptStatus, {
    InventoryReceiptStatusDisplay,
} from 'features/inventory/enums/InventoryReceiptStatus';
import React from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import './InventoryReceiptStatusBadge.scss';

export default function InventoryReceiptStatusBadge({
    status,
    isArchived,
    size = 'normal',
    className,
}: {
    status: InventoryReceiptStatus;
    isArchived?: boolean;
    size?: 'small' | 'normal';
    className?: string;
}) {
    return (
        <>
            <div
                className={coalesceClassNames(
                    'InventoryReceiptStatusBadge',
                    `InventoryReceiptStatusBadge--${size}`,
                    `InventoryReceiptStatusBadge--${status}`,
                    className,
                )}
            >
                {InventoryReceiptStatusDisplay.display(status)}
            </div>
            {isArchived && (
                <>
                    &nbsp;
                    <div
                        className={coalesceClassNames(
                            'InventoryReceiptStatusBadge',
                            `InventoryReceiptStatusBadge--${size}`,
                            `InventoryReceiptStatusBadge--Archived`,
                            className,
                        )}
                    >
                        Archived
                    </div>
                </>
            )}
        </>
    );
}
