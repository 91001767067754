import z from 'zod';
import LocationType from '../enums/LocationType';

export const InventoryLocationPathSchema = z.object({
    id: z.string(),
    name: z.string(),
    locationType: z.nativeEnum(LocationType),
});

export type InventoryLocationPath = z.infer<typeof InventoryLocationPathSchema>;

export const InventoryLocationSchema = z.object({
    count: z.number(),
    path: z.array(InventoryLocationPathSchema),
});

export type InventoryLocation = z.infer<typeof InventoryLocationSchema>;

export const InventoryLocationWarehouseSchema = z.object({
    id: z.string(),
    name: z.string(),
    locationType: z.nativeEnum(LocationType),
    total: z.number(),
    locations: z.array(InventoryLocationSchema),
});

export type InventoryLocationWarehouse = z.infer<typeof InventoryLocationWarehouseSchema>;

export const InventoryLocationsResultSchema = z.object({
    inventoryId: z.string(),
    total: z.number(),
    warehouses: z.array(InventoryLocationWarehouseSchema),
});

export type InventoryLocationsResult = z.infer<typeof InventoryLocationsResultSchema>;
