import DataTable, { ColumnBuilder } from 'components/DataTable/DataTable';
import DataTableFilters, {
    DataTableFilterConfig,
} from 'components/DataTableFilters/DataTableFilters';
import { useWarehouseOptions } from 'features/inventory/hooks/useWarehouseOptions';
import { selectPrimaryLocationId, setPrimaryLocationId } from 'features/inventory/inventory.slice';
import { StocktakeStatusDisplay } from 'features/stocktake/enums/StocktakeStatus';
import { StocktakeTypeDisplay } from 'features/stocktake/enums/StocktakeType';
import { Stocktake } from 'features/stocktake/models/Stocktake';
import workstationsApi from 'features/stocktake/stocktake.api';
import { DateTime } from 'luxon';
import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import { isEmpty } from 'utils/helpers';
import StocktakeStatusBadge from '../StocktakeStatusBadge/StocktakeStatusBadge';
import './StocktakeTable.scss';

const COLUMN_DEFS = ColumnBuilder<Stocktake>()
    .column({
        label: 'Stocktake',
        key: 'number',
        getValue: item => item.number,
        isSortable: true,
        defaultSort: 'DESC',
    })
    .column({
        label: 'Warehouse',
        key: 'parentLocationName',
        getValue: item => item.parentLocationName,
        isSortable: true,
    })
    .column({
        label: 'Type',
        key: 'stocktakeType',
        getValue: item => StocktakeTypeDisplay.display(item.stocktakeType),
        isSortable: true,
    })
    .column({
        label: 'Status',
        key: 'status',
        isSortable: true,
        getValue: item => item.status,
        renderValue: (val, item) => (
            <div className="StocktakeTable__DataTable__Cell--status">
                <StocktakeStatusBadge
                    status={val}
                    isArchived={item.isArchived}
                />
            </div>
        ),
    })
    .column({
        label: 'Created',
        key: 'createdAt',
        isSortable: true,
        getValue: item => item.createdAt,
        renderValue: val => DateTime.fromISO(val).toFormat('dd/MM/yyyy'),
    })
    .column({
        label: 'Notes',
        key: 'notes',
        getValue: item => item.notes,
        renderValue: val => <div className="StocktakeTable__NotesDisplay">{val}</div>,
    })
    .build();

export default function StocktakeTable() {
    const query = workstationsApi.useStocktakeListQuery({ includeArchived: false });

    const [filteredData, setFilteredData] = useState<Stocktake[]>();

    const dispatch = useDispatch();
    const defaultWarehouseId = useAppSelector(selectPrimaryLocationId);
    const warehouseFilterOptions = useWarehouseOptions();

    // highlight the currently open item id if any
    const { stocktakeId: activeItemId } = useParams();

    const tableFilters = useMemo(
        () =>
            [
                {
                    label: 'Search',
                    type: 'search',
                    defaultValue: '',
                    fields: ['number', 'parentLocationName'],
                },
                !isEmpty(warehouseFilterOptions) && {
                    label: 'Warehouse',
                    type: 'select',
                    options: warehouseFilterOptions,
                    defaultValue: defaultWarehouseId,
                    field: 'parentLocationId',
                    allowBlank: false,
                    onChange: (filter, value) => {
                        if (warehouseFilterOptions?.some(o => o.value === value)) {
                            dispatch(setPrimaryLocationId(value));
                        }
                    },
                },
                {
                    label: 'Type',
                    type: 'select',
                    options: StocktakeTypeDisplay.options,
                    defaultValue: '',
                    field: 'stocktakeType',
                },
                {
                    label: 'Status',
                    type: 'select',
                    options: StocktakeStatusDisplay.options,
                    defaultValue: '',
                    field: 'status',
                },
                // {
                //     label: 'Archived',
                //     type: 'toggle',
                //     field: 'includeArchived',
                //     onChange: (filter, value) => {
                //         setIncludeArchived(value === YesNoEnum.Yes);
                //     },
                //     applyFilter: () => true,
                // },
            ] as DataTableFilterConfig[],
        [defaultWarehouseId, dispatch, warehouseFilterOptions],
    );

    return (
        <div className="StocktakeTable">
            <div className="StocktakeTable__FilterBar">
                <DataTableFilters
                    data={query.data?.data}
                    filters={tableFilters}
                    onChange={setFilteredData}
                />
            </div>

            <DataTable
                className="StocktakeTable__DataTable"
                isLoading={query.isLoading}
                isError={query.isError}
                data={filteredData}
                onRefresh={query.refetch}
                rowLinkTo={item => item.id}
                rowIsHighlighted={item => item.id === activeItemId}
                zebra={true}
                useStickyHeader={true}
                useFrontEndSorting={true}
                isRefreshing={query.isFetching}
                columns={COLUMN_DEFS}
            />
        </div>
    );
}
