import createDisplayEnum, { EnumDisplayLabels } from 'utils/createDisplayEnum';

enum UserDefinedFieldEntityType {
    Attribute = 'ATTRIBUTE',
    Inventory = 'INVENTORY',
    SalesOrder = 'SALESORDER',
    PurchaseOrder = 'PURCHASEORDER',
    WorkOrder = 'WORKORDER',
    Location = 'LOCATION',
    InventoryMovement = 'INVENTORYMOVEMENT',
    InventoryGroup = 'INVENTORYGROUP',
    Stocktake = 'STOCKTAKE',
    Supplier = 'SUPPLIER',
    Workflow = 'WORKFLO',
}
export default UserDefinedFieldEntityType;

const labels: EnumDisplayLabels<typeof UserDefinedFieldEntityType> = {
    Attribute: 'Attribute',
    Inventory: 'Inventory',
    SalesOrder: 'Sales Order',
    PurchaseOrder: 'Purchase Order',
    WorkOrder: 'Work Order',
    Location: 'Location',
    InventoryMovement: 'Inventory Movement',
    InventoryGroup: 'Inventory Group',
    Stocktake: 'Stocktake',
    Supplier: 'Supplier',
    Workflow: 'Workflow',
};

export const UserDefinedFieldEntityTypeDisplay = createDisplayEnum(
    UserDefinedFieldEntityType,
    labels,
);
