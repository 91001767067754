import createDisplayEnum, { EnumDisplayLabels } from 'utils/createDisplayEnum';

enum StocktakeCountStatus {
    Initial = 'INITIAL',
    Started = 'STARTED',
    Complete = 'COMPLETE',
}
export default StocktakeCountStatus;

const labels: EnumDisplayLabels<typeof StocktakeCountStatus> = {
    Initial: 'Initial',
    Started: 'Started',
    Complete: 'Complete',
};

export const StocktakeCountStatusDisplay = createDisplayEnum(StocktakeCountStatus, labels);
