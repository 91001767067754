import createDisplayEnum, { EnumDisplayLabels } from 'utils/createDisplayEnum';

enum ShipmentStatus {
    Open = 'OPEN',
    Locked = 'LOCKED',
    Completed = 'COMPLETED',
}
export default ShipmentStatus;

const labels: EnumDisplayLabels<typeof ShipmentStatus> = {
    Open: 'Open',
    Locked: 'Locked',
    Completed: 'Completed',
};

export const ShipmentStatusDisplay = createDisplayEnum(ShipmentStatus, labels);
