import { useDialogManager } from 'providers/DialogManager';
import { useCallback, useEffect, useState } from 'react';
import { useFilePicker } from 'react-sage';

export default function useFileUploader(onUpload: (file: File) => Promise<any | void>) {
    const filePicker = useFilePicker();
    const dialogManager = useDialogManager();
    const [lastFileUpload, setLastFileUpload] = useState<File>();

    const handleUpload = useCallback(
        async (file: File) => {
            const promise = onUpload(file);
            await dialogManager.showLoadingWhile(promise);
            setLastFileUpload(file);
        },
        [dialogManager, onUpload],
    );

    useEffect(() => {
        const file = filePicker.files?.[0];
        if (file && file !== lastFileUpload) {
            handleUpload(file);
            filePicker.files = null;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filePicker.files]);

    return filePicker;
}
