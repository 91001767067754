import InventoryReceiptCreateModal from 'features/inventory/components/InventoryReceiptCreateModal/InventoryReceiptCreateModal';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { usePageTitle } from 'utils/locationHelpers';

export default function InventoryReceiptCreatePage() {
    usePageTitle('Create');
    const navigate = useNavigate();

    return <InventoryReceiptCreateModal close={() => navigate(`/receiving`)} />;
}
