import StocktakeCountSheetModal from 'features/stocktake/components/StocktakeCountSheetModal/StocktakeCountSheetModal';
import { Stocktake } from 'features/stocktake/models/Stocktake';
import workstationsApi from 'features/stocktake/stocktake.api';
import React, { useEffect, useMemo } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { usePageTitle } from 'utils/locationHelpers';

export default function StocktakeCountSheetPage() {
    const { countSheetKey = '' } = useParams();
    const navigate = useNavigate();

    const { stocktake } = useOutletContext() as {
        stocktake: Stocktake;
    };

    const query = workstationsApi.useCountSheetQuery(
        {
            stocktakeId: stocktake.id,
            countSheetKey,
        },
        {
            skip: !countSheetKey,
        },
    );

    usePageTitle(query.data?.key);

    /** Refresh data when loading - never rely on cache here */
    useEffect(() => {
        if (query.isSuccess) {
            // TODO - dont display the modal until data has refreshed
            // might need to use lazy query for this?
            query.refetch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const summaryQuery = workstationsApi.useCountSheetStatusesQuery(stocktake.id);
    const summary = useMemo(
        () => summaryQuery.data?.data.find(s => s.key === countSheetKey),
        [countSheetKey, summaryQuery.data?.data],
    );

    return (
        <>
            <StocktakeCountSheetModal
                stocktake={stocktake}
                model={query.data}
                summary={summary}
                isLoading={query.isLoading || summaryQuery.isLoading}
                isError={query.isError || summaryQuery.isError}
                close={() => navigate(`..`)}
                onRefresh={() => {
                    query.refetch();
                    summaryQuery.refetch();
                }}
                isRefreshing={query.isFetching || summaryQuery.isFetching}
            />
        </>
    );
}
