import Dialog from 'components/Dialogs/Dialog';
import ErrorContent from 'components/ErrorContent/ErrorContent';
import MessagePanel from 'components/MessagePanel/MessagePanel';
import MyButton from 'components/MyButton/MyButton';
import MyCalendar from 'components/MyCalendar/MyCalendar';
import MyLinearProgress from 'components/MyLinearProgress/MyLinearProgress';
import PropertyContainer from 'components/PropertyContainer/PropertyContainer';
import PropertyDisplay from 'components/PropertyDisplay/PropertyDisplay';
import { Schedule } from 'features/schedule/models/Schedule';
import { ScheduledWorkOrder } from 'features/schedule/models/ScheduledWorkOrder';
import scheduleApi from 'features/schedule/schedule.api';
import { DateTime } from 'luxon';
import React, { useMemo, useState } from 'react';
import { formatDateRelative } from 'utils/dateHelpers';
import './ScheduleMoveToDateDialog.scss';

export default function ScheduleMoveToDateDialog({
    workOrder,
    currentSchedule,
    close,
}: {
    workOrder: ScheduledWorkOrder;
    currentSchedule: Schedule;
    close?: (schedule?: Schedule) => void;
}) {
    const defaultDate = DateTime.fromISO(currentSchedule.date);
    const [selectedDate, setSelectedDate] = useState(defaultDate);
    const [selectedMonth, setSelectedMonth] = useState(defaultDate.startOf('month'));

    const scheduleQuery = scheduleApi.useScheduleListQuery({
        dateFrom: selectedMonth.startOf('month').toISODate() as string,
        dateTo: selectedMonth.endOf('month').toISODate() as string,
    });

    const onCancel = () => {
        close?.(undefined);
    };

    const onSave = () => {
        if (selectedDate.hasSame(defaultDate, 'day')) {
            // date did not change
            onCancel();
        } else {
            const sched = scheduleQuery.data?.find(s =>
                DateTime.fromISO(s.date).hasSame(selectedDate, 'day'),
            );
            if (sched) {
                close?.(sched);
            }
        }
    };

    const shouldDisableDate = (day: DateTime) => {
        if (!scheduleQuery.data) {
            return true;
        }
        const hasValidSchedule = scheduleQuery.data.find(s =>
            DateTime.fromISO(s.date).hasSame(day, 'day'),
        );
        return !hasValidSchedule;
    };

    const daysLate = useMemo(
        () => selectedDate.diff(DateTime.fromISO(workOrder.latestScheduleDate)).as('days'),
        [selectedDate, workOrder.latestScheduleDate],
    );

    return (
        <Dialog
            className="ScheduleMoveToDateDialog"
            close={onCancel}
        >
            <h1 className="ScheduleMoveToDateDialog__Title">Move to date</h1>
            <PropertyContainer layout="table">
                <PropertyDisplay
                    label="Work order"
                    value={workOrder.tuid}
                />
                <PropertyDisplay
                    label="Requested due date"
                    value={formatDateRelative(workOrder.requestedDate, { alwaysDate: true })}
                />
                <PropertyDisplay
                    label="Est. days required"
                    value={workOrder.daysRequired}
                />
                <PropertyDisplay
                    label="Latest schedule date"
                    value={formatDateRelative(workOrder.latestScheduleDate, { alwaysDate: true })}
                />
            </PropertyContainer>

            <div className="ScheduleMoveToDateDialog__CalendarWrapper">
                <MyCalendar
                    className="ScheduleMoveToDateDialog__Calendar"
                    value={selectedDate}
                    onChange={setSelectedDate}
                    onMonthChange={setSelectedMonth}
                    shouldDisableDate={shouldDisableDate}
                />
                {scheduleQuery.isFetching && (
                    <div className="ScheduleMoveToDateDialog__CalendarWrapper__Loading">
                        <MyLinearProgress delay={100} />
                    </div>
                )}
                {scheduleQuery.isError && (
                    <ErrorContent className="ScheduleMoveToDateDialog__Error" />
                )}
            </div>
            <div className="ScheduleMoveToDateDialog__SelectedDate">
                Selected date <strong>{formatDateRelative(selectedDate)}</strong>
            </div>
            {daysLate > 0 && (
                <MessagePanel messageType="warning">
                    Selected date is{' '}
                    <strong>
                        {daysLate} {daysLate === 1 ? 'day' : 'days'} after
                    </strong>{' '}
                    the latest schedule date
                </MessagePanel>
            )}
            <div className="ScheduleMoveToDateDialog__Buttons">
                <MyButton
                    className="ScheduleMoveToDateDialog__Buttons__Save"
                    label="Confirm"
                    onClick={onSave}
                />
                <MyButton
                    className="ScheduleMoveToDateDialog__Buttons__Cancel"
                    label="Cancel"
                    buttonType="None"
                    onClick={onCancel}
                />
            </div>
        </Dialog>
    );
}
