import { FormControl, InputLabel, OutlinedInput, Switch } from '@mui/material';
import React from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import { getDeepValue, isEmpty } from 'utils/helpers';
import {
    DataTableFilterBase,
    DataTableFilterPlugin,
    FilterCompProps,
} from '../DataTableFilterTypes';
import './ToggleFilter.scss';

export type ToggleFilterConfig = DataTableFilterBase & {
    type: 'toggle';
    field: string;
    allowBlank?: boolean;
    trueValue?: string;
    falseValue?: string;
};

function ToggleFilter({ filter, onChange }: FilterCompProps<ToggleFilterConfig>) {
    const trueValue = filter.config.trueValue ?? 'true';
    const falseValue = filter.config.falseValue ?? 'false';
    const handleCheckedChanged = (checked: boolean) => {
        const val = checked ? trueValue : falseValue;
        filter.value = val;
        onChange?.(filter);
    };

    const field = filter.config.type === 'toggle' && filter.config.field;

    return (
        <FormControl
            className={coalesceClassNames(
                'DataTableFilters__Field',
                'DataTableFilters__Field--ToggleFilter',
                'ToggleFilter',
                field && `DataTableFilters__Field--ToggleFilter--${field}`,
            )}
            size="small"
            variant="outlined"
        >
            <InputLabel
                variant="outlined"
                shrink={true}
            >
                {filter.config.label}
            </InputLabel>

            <OutlinedInput
                notched={true}
                label={filter.config.label}
                className="ToggleFilter__OutlinedInput"
                type="hidden"
            />
            <div className="ToggleFilter__SwitchWrapper">
                <Switch
                    className="ToggleFilter__Switch"
                    checked={filter.value === trueValue}
                    onChange={e => handleCheckedChanged(e.target.checked)}
                />
            </div>
        </FormControl>
    );
}

const plugin: DataTableFilterPlugin<ToggleFilterConfig> = {
    Component: ToggleFilter,
    applyFilter: (filter, value, item) => {
        let val = getDeepValue(item, filter.field);
        if (isEmpty(val)) {
            val = '';
        }
        return String(val) === value;
    },
};
export default plugin;
