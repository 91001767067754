import z from 'zod';

export const UserSchema = z.object({
    id: z.number(),
    firstName: z.string(),
    lastName: z.string(),
    email: z.string(),
});

export type User = z.infer<typeof UserSchema>;

export function getUserFullName(user?: User) {
    return `${user?.firstName} ${user?.lastName}`;
}

export function getUserInitials(user?: User) {
    const first = user?.firstName.charAt(0) || '';
    const last = user?.lastName.charAt(0) || '';
    return `${first}${last}`.toUpperCase();
}
