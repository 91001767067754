import z from 'zod';

export const StocktakeDiscrepancySchema = z.object({
    tenantInventoryId: z.string(),
    partNumber: z.string(),
    countSheetId: z.string(),
    countSheetKey: z.string(),
    description: z.string(),
    locationId: z.string(),
    locationName: z.string(),
    expectedQuantity: z.number(),
    countedQuantity: z.number(),
    difference: z.number(),
    movementsAllocated: z.number(),
    movementsOnHand: z.number(),
    adjustedDifference: z.number(),
});

export type StocktakeDiscrepancy = z.infer<typeof StocktakeDiscrepancySchema>;
