import Icons from 'Icons';
import MyMoneyInput from 'components/MyMoneyInput/MyMoneyInput';
import PropertyDisplay from 'components/PropertyDisplay/PropertyDisplay';
import PropertyEdit from 'components/PropertyEdit/PropertyEdit';
import React, { useRef } from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import { generateShortUuid } from 'utils/helpers';
import './PropertyEditMoney.scss';

export type PropertyEditMoneyProps = {
    className?: string;
    value?: number;
    label: string;
    hint?: string;
    onChange?: (val?: number) => void;
    readonly?: boolean;
    disabled?: boolean;
    validationRequired?: string | boolean;
    validationCustom?: string | false;
    min?: number;
    max?: number;
    allowBlank?: boolean;
};

export default function PropertyEditMoney({
    className,
    value,
    label,
    hint,
    onChange,
    readonly = false,
    disabled = false,
    validationRequired,
    validationCustom,
    min,
    max,
    allowBlank,
}: PropertyEditMoneyProps) {
    const inputId = useRef(generateShortUuid()).current;

    const validationRequiredWithLabel =
        validationRequired === true ? `${label} is required` : validationRequired;

    if (readonly) {
        return (
            <PropertyDisplay
                className={className}
                label={label}
                hint={hint}
                value={value}
            />
        );
    }
    return (
        <PropertyEdit
            className={coalesceClassNames('PropertyEditMoney', className)}
            label={label}
            hint={hint}
            inputId={inputId}
        >
            <div className="PropertyEditMoney__InputWrapper">
                <MyMoneyInput
                    className="PropertyEditMoney__Input"
                    id={inputId}
                    value={value}
                    allowBlank={allowBlank}
                    min={min}
                    max={max}
                    disabled={disabled}
                    handleInput={onChange}
                    LeftIcon={Icons.Dollar}
                    validationRequired={validationRequiredWithLabel}
                    validationCustom={validationCustom}
                />
            </div>
        </PropertyEdit>
    );
}
