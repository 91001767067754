import createDisplayEnum, { EnumDisplayLabels } from 'utils/createDisplayEnum';

enum WorkItemStepStatus {
    Unstarted = 'UNSTARTED',
    InProgress = 'INPROGRESS',
    OnHold = 'ONHOLD',
    Skipped = 'SKIPPED',
    SkippedLocked = 'SKIPPED_LOCKED',
    Completed = 'COMPLETED',
    CompletedLocked = 'COMPLETED_LOCKED',
}
export default WorkItemStepStatus;

const labels: EnumDisplayLabels<typeof WorkItemStepStatus> = {
    Unstarted: 'Not started',
    InProgress: 'In progress',
    OnHold: 'On hold',
    Skipped: 'Skipped',
    SkippedLocked: 'Skipped',
    Completed: 'Completed',
    CompletedLocked: 'Completed',
};

export const WorkItemStepStatusDisplay = createDisplayEnum(WorkItemStepStatus, labels);
