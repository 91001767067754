import { AttributeFieldSchema } from 'models/AttributeField';
import { UdfValueHashSchema } from 'models/UserDefinedField';
import { mergeDeep } from 'utils/helpers';
import z from 'zod';

export const InventoryGroupDetailSchema = z.object({
    id: z.string(),
    name: z.string(),
    userDefinedFields: UdfValueHashSchema,
    context: z.object({
        attributes: z.array(AttributeFieldSchema),
    }),
});

export type InventoryGroupDetail = z.infer<typeof InventoryGroupDetailSchema>;

export class InventoryGroupDetailFactory {
    static create(data: Partial<InventoryGroupDetail>) {
        const defaults: InventoryGroupDetail = {
            id: '',
            name: '',
            context: {
                attributes: [],
            },
            userDefinedFields: {},
        };

        const mergedData = mergeDeep(defaults, data);

        return InventoryGroupDetailSchema.parse(mergedData);
    }
}
