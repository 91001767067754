import AccountingConnectionStatus, {
    AccountingConnectionStatusDisplay,
} from 'features/accounting/enums/AccountingConnectionStatus';
import React from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import './AccountingConnectionStatusBadge.scss';

export default function AccountingConnectionStatusBadge({
    status,
    size = 'normal',
    className,
}: {
    status: AccountingConnectionStatus;
    size?: 'small' | 'normal';
    className?: string;
}) {
    return (
        <div
            className={coalesceClassNames(
                'AccountingConnectionStatusBadge',
                `AccountingConnectionStatusBadge--${size}`,
                `AccountingConnectionStatusBadge--${status}`,
                className,
            )}
        >
            {AccountingConnectionStatusDisplay.display(status)}
        </div>
    );
}
