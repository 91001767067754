import { useMemo } from 'react';
import { WorkstationStepsTableStep } from '../components/WorkstationStepsTable/WorkstationStepsTable';
import WorkstationListName from '../enums/WorkstationListName';
import workstationsApi from '../workstations.api';
import useStepFilters from './useStepFilters';

export type StepsTableQueryBase = {
    isSuccess: boolean;
    isLoading: boolean;
    isError: boolean;
    refetch: () => void;
    isFetching: boolean;
};

/** Load data from the relevant queue and
 * Transform data to match the input for WorkstationStepsTable
 * This basically means computing an isCollapsed flag for each row based on the step filter params
 */
export default function useStepsTableData({
    workstationId,
    listName,
}: {
    workstationId?: string;
    listName: WorkstationListName;
}) {
    const { filterObj } = useStepFilters();

    const readyQuery = workstationsApi.useGetWorkstationReadyStepsQuery(workstationId || '', {
        skip: !workstationId || listName !== WorkstationListName.Ready,
    });
    const comingUpQuery = workstationsApi.useGetWorkstationComingUpStepsQuery(workstationId || '', {
        skip: !workstationId || listName !== WorkstationListName.ComingUp,
    });
    const historyQuery = workstationsApi.useGetWorkstationCompletedStepsQuery(workstationId || '', {
        skip: !workstationId || listName !== WorkstationListName.History,
    });

    const query = useMemo(() => {
        if (listName === WorkstationListName.Ready) {
            return readyQuery;
        }
        if (listName === WorkstationListName.ComingUp) {
            return comingUpQuery;
        }
        if (listName === WorkstationListName.History) {
            return historyQuery;
        }
        return undefined;
    }, [comingUpQuery, historyQuery, listName, readyQuery]);

    const data = useMemo(() => query?.data, [query?.data]);

    const transformedData = useMemo((): WorkstationStepsTableStep[] => {
        return (data ?? []).map(item => {
            let isCollapsed = false;
            if (filterObj.tuid && item.context.workOrderItem.tuid !== filterObj.tuid) {
                isCollapsed = true;
            }
            if (filterObj.ref && item.context.workOrderItem.identifier !== filterObj.ref) {
                isCollapsed = true;
            }
            if (filterObj.step && item.context.workflowStep.name !== filterObj.step) {
                isCollapsed = true;
            }
            if (filterObj.summaryFields) {
                const fieldsMatch = filterObj.summaryFields.every(field =>
                    item.context.workOrderItem.summaryFields.some(
                        l => l.key === field.key && l.value === field.value,
                    ),
                );
                if (!fieldsMatch) {
                    isCollapsed = true;
                }
            }
            return {
                ...item,
                isCollapsed,
            };
        });
    }, [filterObj.ref, filterObj.step, filterObj.summaryFields, filterObj.tuid, data]);

    return [transformedData, query] as const;
}
